import React, { useState, Component } from "react";
import TimerIcon from "@material-ui/icons/Timer";
import Tooltip from "@material-ui/core/Tooltip";
import { Chip, makeStyles } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
export const Addfunctions = {
  Importance,
  Status,
};
const useStyles = makeStyles(() => ({
  buttonsuccess: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  buttonerror: {
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
}));

//////ความสำคัญ
export function Importance({ vara }) {
  const classes = useStyles();
  if (vara === "ปกติ") {
    return (
      <Tooltip title="ปกติ">
        <Chip
          icon={<SentimentSatisfiedAltIcon />}
          label="&nbsp;&nbsp;&nbsp;ปกติ&nbsp;&nbsp;&nbsp;&nbsp;"
          className={classes.buttoninfo}
          color="primary"
        />
      </Tooltip>
    );
  } else if (vara === "ด่วน") {
    return (
      <Tooltip title="ด่วน">
        <Chip
          icon={<SentimentSatisfiedIcon />}
          label="&nbsp;&nbsp;&nbsp;ด่วน&nbsp;&nbsp;&nbsp;&nbsp;"
          className={classes.buttonwiring}
          color="primary"
        />
      </Tooltip>
    );
  } else if (vara === "ด่วนมาก") {
    return (
      <Tooltip title="ด่วนมาก">
        <Chip
          icon={<SentimentVeryDissatisfiedIcon />}
          label="ด่วนมาก "
          className={classes.buttonerror}
          color="primary"
        />
      </Tooltip>
    );
  } else {
    return <></>;
  }
}

////สถานะ
export function Status({ para }) {
  const classes = useStyles();
  if (para === "ดำเนินการเรียบร้อย") {
    return (
      <Tooltip title="ดำเนินการเรียบร้อย">
        <Chip
          icon={<AlarmOnIcon />}
          label="ดำเนินการเรียบร้อย &nbsp;"
          className={classes.buttonsuccess}
          color="primary"
        />
      </Tooltip>
    );
  } else if (para === "อยู่ระหว่างดำเนินการ") {
    return (
      <Tooltip title="อยู่ระหว่างดำเนินการ">
        <Chip icon={<TimelapseIcon />} label="อยู่ระหว่างดำเนินการ" className={classes.buttonwiring} color="primary" />
      </Tooltip>
    );
  } else if (para === "รอดำเนินการ") {
    return (
      <Tooltip title="รอดำเนินการ">
        <Chip
          icon={<TimerIcon />}
          color="primary"
          label="&nbsp; &nbsp; &nbsp;รอดำเนินการ  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;"
          className={classes.buttoninfo}
        />
      </Tooltip>
    );
  } else if (para === "ปิดงาน") {
    return (
      <Tooltip title="ปิดงาน">
        <Chip
          label="&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; ปิดงาน &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;"
          icon={<CheckCircleOutlineIcon />}
          color="primary"
        />
      </Tooltip>
    );
  } else {
    return <></>;
  }
}
