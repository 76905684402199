import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {
  Divider,
  Box,
  CardHeader,
  Button,
  Card,
  Link,
  Container,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { status } from "../../Data/taskData.json";
import * as Yup from "yup";
import Page from "../../components/Page";
import { Formik, Form, ErrorMessage } from "formik";
import CKEditor from "ckeditor4-react";
import swal from "sweetalert";
import { LoadNewTaskService, UpdateDevTaskService,GetDetailTaskService } from "../../services/serviceTask";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  buttonsuccess: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
}));
const taskSchema = Yup.object().shape({
  taskRefers: Yup.string()
    .min(2, "กรุณากรอก คำแนะนำ")
    .required("กรุณากรอก คำแนะนำ"),
});

const InsertView = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const param = useLocation();
  const [setTask, SetTask] = useState({});
  const [loading, SetLoading] = useState(true);
  const [setDetail, Setdetail] = useState({});

  async function loadTask(param) {
    const resultTask = await LoadNewTaskService(param);
    SetTask(resultTask.data);
    SetLoading(false);
  }

  async function loadDetail(param) {
    const resultTask = await GetDetailTaskService(param);
    Setdetail(resultTask.data);
  }
  useEffect(() => {
    if (param.state !== null) {
      loadTask(param.state.id);
      loadDetail(param.state.id);
    }
  }, []);
  const SaveValue = async (values) => {
    // alert(values)
    if (param.state !== null) {
      let resultUpdate = await UpdateDevTaskService(param.state.id, values);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/task", { replace: true });
        } else {
          alert(resultUpdate.mgs);
        }
      }
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/Task">
              รายการหมอบหมายงาน
            </Link>
            <Link color="inherit">รายละเอียดการหมอบหมายงาน</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Formik
                onSubmit={(values) => {
                  SaveValue({
                    taskprojectsCode: parseInt(values.taskprojectsCode),
                    taskcustomerCode: values.taskcustomerCode,
                    tasktypeCode: parseInt(values.tasktypeCode),
                    taskstatusCode: parseInt(values.taskstatusCode),
                    taskImportanceCode: parseInt(values.taskImportanceCode),
                    taskempCode: values.taskempCode,
                    taskcreatedBy: parseInt(values.taskcreatedBy) != null ? parseInt(values.taskcreatedBy) : "",
                    notes: values.notes,
                    tasklist: values.tasklist,
                    taskIsuse: values.taskIsuse,
                    taskRefers: values.taskRefers,
                    taskDetail: values.taskDetail,
                    taskSolution: values.taskSolution,
                    taskstartDate: values.taskstartDate,
                    taskgetjobDate: values.taskgetjobDate,
                    taskresolveDate: values.taskresolveDate,
                  });
                }}
                enableReinitialize
                initialValues={{
                  taskprojectsCode: setDetail.projectsCode != null ? parseInt(setDetail.projectsCode) : "",
                  taskcustomerCode: setDetail.customerCode != null ? setDetail.customerCode : "",
                  taskstatusCode: setDetail.statusCode != null ? parseInt(setDetail.statusCode) : "",
                  taskcreatedBy: setDetail.taskcreatedBy != null ? setDetail.taskcreatedBy : "",
                  tasktypeCode: setDetail.tasktypeCode != null ? parseInt(setDetail.tasktypeCode) : "",
                  taskempCode: setDetail.empCode == null ? setDetail.empCode : setDetail.empCode,
                  taskIsuse: setDetail.taskIsuse != null ? setDetail.taskIsuse : "",
                  taskImportanceCode: setDetail.importanceCode != null ? parseInt(setDetail.importanceCode) : "",
                  taskSolution: setDetail.taskSolution,
                  notes: setDetail.notes,
                  taskDetail: setDetail.taskDetail,
                  tasklist: setDetail.tasklist,
                  taskresolveDate: setDetail.taskresolveDate,
                  taskstartDate: setDetail.taskstartDate,
                  taskRefers: setDetail.taskRefers != null ? setDetail.taskRefers : "",
                  taskgetjobDate: setDetail.taskgetjobDate, 
                }}
                validationSchema={taskSchema}
              >
                {({ setFieldValue, touched, errors, values, handleBlur, handleChange }) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <CardContent>
                          <Grid item md={12} xs={12}>
                            <Typography variant="h2" component="h2">
                              {"เรื่องแจ้ง :" + setTask.tasklist}
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12} p={3}>
                            <Typography variant="h4" component="h2">
                              {"วันที่สั่งงาน : " + setTask.taskcreatedDate}
                            </Typography>
                          </Grid>
                          <CardHeader />
                          <Card>
                            <PerfectScrollbar>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <Typography className="detail" variant="h5" component="h2">
                                        รายการ
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>รายละเอียดงาน</Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        วันที่แจ้งบัญหา :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.taskgetjobDate}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        วันที่เริ่มงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.taskstartDate + " " + "น."}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        วันที่สิ้นสุด :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.taskresolveDate + " " + "น."}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        ผู้มอบหมายงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.taskcreatedByName}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        ผู้รับงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.taskempNeme}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        โครงกาาร :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.projectsName}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        ลูกค้า :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.companyName}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        ระดับความสำคัญ :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.importanceName}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        ประเภทงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.tasktypeName}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        เรื่องแจ้ง :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.tasklist}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        รายละเอียดงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        dangerouslySetInnerHTML={{ __html: setTask.taskDetail }}
                                        style={{ width: "900px", wordWrap: "break-word" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        สถานะงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.statusName}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        วิธีแก้ไขปัญหา :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        dangerouslySetInnerHTML={{ __html: setTask.taskSolution }}
                                        style={{ width: "900px", wordWrap: "break-word" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        วันที่ส่งงาน :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{setTask.taskendDate}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5" component="h2">
                                        แนะนำ :
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        dangerouslySetInnerHTML={{ __html: setTask.taskRefers }}
                                        style={{ width: "1000px", wordWrap: "break-word" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography> หมายเหตุ :</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        dangerouslySetInnerHTML={{ __html: setTask.notes }}
                                        style={{ width: "1000px", wordWrap: "break-word" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </PerfectScrollbar>
                          </Card>
                          <CardHeader />

                          <CardHeader />
                          {setTask.statusName == "ดำเนินการเรียบร้อย" ? (
                            <Grid item md={12} xs={12}>
                              <Divider />
                              <CardHeader />
                              <Typography className={errors.taskRefers && touched.taskRefers ? "input-feedback" : ""}>
                                คำแนะนำ
                              </Typography>
                              {loading ? (
                                <></>
                              ) : (
                                <div className={errors.taskRefers && touched.taskRefers ? "border" : ""}>
                                  <CKEditor
                                    dangerouslySetInnerHTML={{ __html: "taskDetail &middot; Second" }}
                                    activeClass="editor"
                                    className={errors.taskRefers && touched.taskRefers ? "text-input error" : ""}
                                    variant="outlined"
                                    data={values.taskRefers}
                                    name="taskRefers"
                                    onChange={(e) => {
                                      setFieldValue("taskRefers", e.editor.getData());
                                    }}
                                    onBeforeLoad={(CKEDITOR) => {
                                      CKEDITOR.disableAutoInline = true;
                                      CKEDITOR.config.autoParagraph = false;
                                    }}
                                  />
                                </div>
                              )}
                              <ErrorMessage component="div" name="taskRefers" className="input-feedback " />
                              <CardHeader />
                              <Grid item md={12} xs={12}>
                                <TextField
                                  defaultValue={values.taskstatusCode}
                                  value={values.taskstatusCode}
                                  size="small"
                                  name="taskstatusCode"
                                  onChange={handleChange}
                                  variant="outlined"
                                  fullWidth
                                  select
                                  error={Boolean(touched.taskstatusCode && errors.taskstatusCode)}
                                  helperText={touched.taskstatusCode && errors.taskstatusCode}
                                  onBlur={handleBlur}
                                >
                                  {status.map((values) => (
                                    <MenuItem key={values.statusCode} value={values.statusCode}>
                                      {values.statusName}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </CardContent>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        {setTask.statusName != "ดำเนินการเรียบร้อย" ? (
                          <Box display="flex" justifyContent="flex-end">
                            <Button
                              color="primary"
                              variant="contained"
                              startIcon={<ArrowBackIcon />}
                              onClick={() => {
                                navigate("/app/Task", {
                                  replace: true,
                                });
                              }}
                              size="large"
                            >
                              ย้อนกลับ
                            </Button>
                          </Box>
                        ) : (
                          <Box display="flex" justifyContent="flex-end">
                            <Box mr={3}>
                              <Button
                                color="primary"
                                type="submit"
                                className={classes.buttonsuccess}
                                variant="contained"
                                startIcon={<SaveOutlinedIcon />}
                                size="large"
                              >
                                บันทึก
                              </Button>
                            </Box>
                            <Button
                              color="primary"
                              variant="contained"
                              startIcon={<ArrowBackIcon />}
                              onClick={() => {
                                navigate("/app/Task", {
                                  replace: true,
                                });
                              }}
                              size="large"
                            >
                              ย้อนกลับ
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default InsertView;
