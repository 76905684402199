import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  MenuItem,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import IconAdd from "../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { tyleleave, leaveStatus } from "../../Data/leaveData.json";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const handleOnChange = (event) => {
  console.log(event.target.value);
};

const Toolbar = ({
  className,
  searchData,
  flutterTypeData,
  flutterStatusData,
  clear,
  date,
  flutterDate,
  flutterSince,
  flutterTo,
  since,
  to,
  leaveStatus,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textPrimary">ประวัติการลา</Link>
        </Breadcrumbs>
      </Box>
      <Box mt={2}>
        <Card>
          <CardContent>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    onKeyUp={(e) => {
                      searchData(e.target.value);
                      console.log(e.target.value);
                    }}
                    id="search"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleOnChange}
                    placeholder="ค้นหา..."
                    variant="outlined"
                  ></TextField>
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    onChange={(e) => {
                      flutterTypeData(e.target.value);
                      console.log("testValue", e.target.value);
                    }}
                    size="small"
                    inputProps={{ defaultValue: 0 }}
                    label="ประเภทการลา"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    {tyleleave.map((item) => (
                      <MenuItem
                        key={item.leavetypeCode}
                        value={item.leavetypeCode}
                      >
                        {item.leavetypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    value={since}
                    id="startDate"
                    label="วันที่เริ่มการลา "
                    type="date"
                    fullWidth
                    name="startDate"
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      flutterSince(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    value={to}
                    id="endDate"
                    label="วันที่สิ้นสุดการลา "
                    type="date"
                    fullWidth
                    name="endDate"
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      flutterTo(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item md={1} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Tooltip title="ล้างข้อมูลการค้นหา">
                      <IconButton
                        values={0}
                        size="small"
                        onClick={(e) => {
                          document.getElementById("search").value = "";
                          clear();
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Box mt={0} display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<IconAdd />}
                      onClick={() => {
                        navigate("/app/FormLeave", { replace: true });
                      }}
                      size="large"
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={12}
                  onChange={(e) => {
                    flutterStatusData(e.target.value);
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">สถานะ</FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        checked={leaveStatus == "0"}
                        value="0"
                        control={<Radio color="primary" />}
                        label="ทั้งหมด"
                      />
                      <FormControlLabel
                       checked={leaveStatus == "1"}
                        value="1"
                        control={<Radio color="primary" />}
                        label="อนุมัติ"
                      />
                      <FormControlLabel
                        checked={leaveStatus == "3"}
                        value="3"
                        control={<Radio color="primary" />}
                        label="รออนุมัติ"
                      />
                      <FormControlLabel
                        checked={leaveStatus == "2"}
                        value="2"
                        control={<Radio color="primary" />}
                        label="ไม่อนุมัติ"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
