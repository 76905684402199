import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableContainer from '@material-ui/core/TableContainer';
import { Box, TextField, CardHeader, Typography, Grid, Card, MenuItem, Button, Table, TableBody, Avatar, TableCell, TableHead, TablePagination, TableRow, makeStyles } from "@material-ui/core";
import IconDel from "../../icons/Del";
import Detail from "../../icons/Detail";
import IconEdit from "../../icons/Edit";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import swal from 'sweetalert';
import { DeleteExpensesService } from "../../services/services.Expenses";
import { useNavigate } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { pageSize } from "../../Data/pageSize.json";



const useStyles = makeStyles(() => ({
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
}));

const REDCard = ({ className, expensess, setpagin, fetchData, changPageSize, changCurrentPage, currentPage, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedREDIds] = useState([]);

  const handleChangePage = (event, newPage) => {
    changCurrentPage(newPage);
};


  const Del = async (expesesCode) => {

    let result = await DeleteExpensesService(expesesCode);
    if (result.statusCode === 1) {
      fetchData();
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  }

  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
        <TableContainer>
          <Table>
            <TableHead className="thead-font">
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell>วันที่ชำระเงิน </TableCell>
                <TableCell>หมวดหมู่</TableCell>
                <TableCell>รายการ</TableCell>
                <TableCell>จำนวนเงิน(บาท)</TableCell>
                <TableCell>ภาษีมูลค่าเพิ่ม(บาท)</TableCell>
                <TableCell>ยอดรวมสุทธิ(บาท)</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expensess.map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell>{((setpagin.currentPage - 1) * setpagin.pageSize + (index + 1))}</TableCell>
                  <TableCell>{item.exTransactionDate}</TableCell>
                  <TableCell>{item.categoryName}</TableCell>
                  <TableCell>{item.exList}</TableCell>
                  <TableCell>{item.exValue}</TableCell>
                  <TableCell>{item.exVatTotal}</TableCell>
                  <TableCell>{item.exTotal}</TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <ButtonGroup variant="contained">
                        <Button 
                          className={classes.buttonwiring}
                          size="medium"
                          onClick={(e) => {
                            navigate("/app/Formred", { state: { id: item.expesesCode } }, { replace: true });
                          }}>
                          <IconEdit />

                        </Button>
                        <Button 
                         className={classes.buttoninfo}
                         size="medium"
                          onClick={(e) => {
                            navigate("/app/DetailRED", { state: { id: item.expesesCode } }, { replace: true });
                          }}>

                          <Detail />
                        </Button>
                        <Button 
                         color="secondary"
                         size="medium"
                          onClick={(e) => {
                            swal({
                              className: "bg-modal-red",
                              icon: "warning",
                              dangerMode: true,
                              title: item.exList,
                              text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                              buttons: {
                                cancel: "ยกเลิก",
                                confirm: {
                                  text: "ใช่",
                                  value: item.expesesCode,
                                },
                              },
                            }).then((value) => {
                              if (value !== null) {
                                Del(value);
                              }
                            });
                          }}>
                          <IconDel />
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <CardHeader />


      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {setpagin.rowCount == 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error"> "ไม่พบข้อมูล&nbsp;!" </Typography>
              </Box>) : (
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <Typography>&nbsp; ข้อมูล : &nbsp;{setpagin.rowCount}&nbsp; รายการ</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Box display="flex" justifyContent="flex-end">
                        <Typography>แถวต่อหน้า : &nbsp;</Typography>
                        <TextField
                          defaultValue={10}
                          select
                          size="small"
                          onChange={(e) => {
                            changPageSize(e.target.value);

                          }}
                        >
                          {pageSize.map((item) => (
                            <MenuItem key={item.code} value={item.code}>
                              {item.name}
                            </MenuItem>
                          ))}
                          <MenuItem value={-1}> ทั้งหมด</MenuItem>
                        </TextField>
                      </Box>

                      <Pagination
                        count={setpagin.pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

REDCard.propTypes = {
  className: PropTypes.string,
  revenueExpenditures: PropTypes.array.isRequired,
};

export default REDCard;
