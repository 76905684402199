import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  FormControl,
  MenuItem,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import IconAdd from "../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, searchData, MonthFutter, YearFutter, monthe, yeare,  clear, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();


  var monthlist = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(2), (e, index) => year - index);

  return (
    <div noValidate className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <p style={{ fontSize: 30 }}>ข้อมูลการจ่ายเงินเดือน</p>
        </Breadcrumbs>
      </Box>

      <form id="input">
        <Card>
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextField
                    id="search"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      searchData(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={"ค้นหา..."}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    value={monthe}
                    size="small"
                    label="-- เดือน --"
                    name="month"
                    onChange={(e) => {
                      MonthFutter(e.target.value.toString())
                    }}
                    variant="outlined"
                    fullWidth
                    select
                  >
                     <MenuItem value={"0"}>ทั้งหมด</MenuItem>
                    {monthlist.map((month, i) => (
                      <MenuItem value={i + 1}>{month}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    value={yeare}
                    size="small"
                    label="-- ปี --"
                    name="year"
                    onChange={(e) => {
                      YearFutter(e.target.value.toString())
                    }}
                    variant="outlined"
                    fullWidth
                    select
                  >
                     <MenuItem value={"0"}>ทั้งหมด</MenuItem>
                    {years.map((year, index) => (
                      <MenuItem key={`year${index}`} value={year}>
                        {year + 543}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={1} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Tooltip title="ล้างข้อมูลการค้นหา">
                      <IconButton
                        values={0}
                        size="small"
                        onClick={(e) => {
                          document.getElementById("search").value = "";
                          clear();
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<IconAdd />}
                      onClick={() => {
                        navigate("/app/formSalary", { replace: true });
                      }}
                      size="large"
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
