import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import IconSave from "../../../icons/Save";
import IconCancel from "../../../icons/Cancel";
import swal from "sweetalert";
import {
  SaveTaskService,
  UpdateTaskService,
  GetdataTaskService,
  LoadDataTaskService,
} from "../../../services/serviceTask";
import { status, taskType, importance } from "../../../Data/taskData.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dateFormat from "dateformat";
import {
  Box,
  Button,
  CardHeader,
  Card,
  MenuItem,
  Link,
  Container,
  CardContent,
  TextField,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Page from "../../../components/Page";
import CKEditor from "ckeditor4-react";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const taskSchema = Yup.object().shape({
  taskgetjobDate: Yup.date()
    .max(Yup.ref("taskstartDate"), "กรุณากรอกวันที่รับงานน้อยกว่าวันที่บันทึกข้อมูล")
    .required("กรุณากรอก วันที่แจ้งบัญหา"),
  taskstartDate: Yup.date()
    .min(Yup.ref("taskgetjobDate"), "กรุณากรอกวันที่รับงานมากกว่าวันที่บันทึกข้อมูล")
    .max(Yup.ref("taskresolveDate"), "กรุณากรอกวันที่แก้ไขงานเสร็จสิ้นน้อยกว่าวันที่บันทึกข้อมูล")
    .required("กรุณากรอก วันที่บันทึกข้อมูล"),
  taskresolveDate: Yup.date()
    .min(Yup.ref("taskstartDate"), "กรุณากรอกวันที่แก้ไขงานเสร็จสิ้นมากกว่าวันที่บันทึกข้อมูล")
    .required("กรุณากรอก วันที่แก้ไขงานเสร็จสิ้น"),
  taskprojectsCode: Yup.string().required("กรุณากรอก ชื่อโครงงาน"),
  taskcustomerCode: Yup.string().required("กรุณากรอก ชื่อลูกค้า"),
  tasktypeCode: Yup.string().required("กรุณาเลือก ประเภทงาน"),
  taskDetail: Yup.string().required("กรุณากรอก รายละเอียด"),
  taskempCode: Yup.string().required("กรุณาเลือก พนักงาน"),
  taskImportanceCode: Yup.string().required("กรุณาเลือก ความสำคัญ"),
  tasklist: Yup.string()
    .max(50, "กรุณากรอก ข้อมูลน้อยกว่า 50 ตัว")
    .required("กรุณากรอก เรื่องแจ้ง"),
});

const InsertView = ({}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [emp, Setemp] = useState([]);
  const [customer, Setcustomer] = useState([]);
  const [project, Setproject] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [setTask, SetTask] = useState({});
  const param = useLocation();

  //CKEditor.editorUrl = "https://cdn.ckeditor.com/4.14.1/full/ckeditor.js";
  useEffect(() => {
    fetchdata();
    if (param.state !== null) {
      loadTask(param.state.id);
    }
  }, []);

  async function loadTask(param) {
    const resultTask = await LoadDataTaskService(param);
    SetTask(resultTask.data);
    SetLoading(false);
  }

  async function fetchdata() {
    const result = await GetdataTaskService();
    Setemp(result.emp);
    Setcustomer(result.customer);
    Setproject(result.project);
    SetLoading(false);
  }

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateTaskService(param.state.id, values);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/Task", { replace: true });
        } else {
          alert(resultUpdate.mgs);
        }
      }
    } else {
      let result = await SaveTaskService(values);

      if (result !== null) {
        if (result.statusCode === 1) {
          swal({
            title: "สำเร็จ",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/Task", { replace: true });
        } else {
          alert(result.mgs);
        }
      }
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/Task">
              รายการหมอบหมาบงาน
            </Link>
            <Link color="inherit">เพิ่มการหมอบหมาบงาน</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardHeader />
            <CardContent>
              <Box display="flex" justifyContent="center">
                <CardHeader />
                <Typography color="textPrimary" variant="h2">
                  ข้อมูลการแจ้งปัญหา
                </Typography>
              </Box>
              <CardContent>
                <Formik
                  onSubmit={(values) => {
                    SaveValue({
                      taskprojectsCode: parseInt(values.taskprojectsCode),
                      taskcustomerCode: values.taskcustomerCode,
                      tasktypeCode: parseInt(values.tasktypeCode),
                      taskstatusCode: parseInt(values.taskstatusCode),
                      taskImportanceCode: parseInt(values.taskImportanceCode),
                      taskempCode: values.taskempCode,
                      taskcreatedBy: parseInt(values.taskcreatedBy) != null ? parseInt(values.taskcreatedBy) : "",
                      notes: values.notes,
                      tasklist: values.tasklist,
                      taskIsuse: values.taskIsuse,
                      taskRefers: values.taskRefers,
                      taskDetail: values.taskDetail,
                      taskSolution: values.taskSolution,
                      taskstartDate: values.taskstartDate,
                      taskgetjobDate: values.taskgetjobDate,
                      taskresolveDate: values.taskresolveDate,
                    });
                  }}
                  enableReinitialize
                  initialValues={{
                    taskprojectsCode: setTask.projectsCode != null ? parseInt(setTask.projectsCode) : "",
                    taskcustomerCode: setTask.customerCode != null ? setTask.customerCode : "",
                    tasktypeCode: setTask.tasktypeCode != null ? parseInt(setTask.tasktypeCode) : "",
                    taskstatusCode: setTask.statusCode != null ? parseInt(setTask.statusCode) : "",
                    taskImportanceCode: setTask.importanceCode != null ? parseInt(setTask.importanceCode) : "",
                    taskempCode: setTask.empCode != null ? setTask.empCode : "",
                    taskstartDate: setTask.taskstartDate,
                    taskresolveDate: setTask.taskresolveDate,
                    taskDetail: setTask.taskDetail,
                    tasklist: setTask.tasklist,
                    taskgetjobDate:
                      setTask.taskgetjobDate != null ? dateFormat(setTask.taskgetjobDate, "yyyy-mm-dd") : "",
                    taskcreatedBy: setTask.taskcreatedBy != null ? parseInt(setTask.taskcreatedBy) : "",
                  }}
                  validationSchema={taskSchema}
                >
                  {({ errors, touched, handleBlur, values, handleChange, setFieldValue }) => (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item md={2} xs={12}>
                          <TextField
                            defaultValue={values.taskgetjobDate}
                            value={values.taskgetjobDate}
                            fullWidth
                            id="date"
                            type="date"
                            variant="outlined"
                            name="taskgetjobDate"
                            size="small"
                            label="วันที่แจ้งปัญหา"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={Boolean(touched.taskgetjobDate && errors.taskgetjobDate)}
                            helperText={touched.taskgetjobDate && errors.taskgetjobDate}
                          />
                        </Grid>

                        <Grid item md={2} xs={12}>
                          <TextField
                            defaultValue={values.taskstartDate}
                            value={values.taskstartDate}
                            fullWidth
                            id="date"
                            type="datetime-local"
                            variant="outlined"
                            name="taskstartDate"
                            size="small"
                            label="วันที่เริ่มงาน"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={Boolean(touched.taskstartDate && errors.taskstartDate)}
                            helperText={touched.taskstartDate && errors.taskstartDate}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            defaultValue={values.taskresolveDate}
                            value={values.taskresolveDate}
                            fullWidth
                            id="date"
                            type="datetime-local"
                            variant="outlined"
                            name="taskresolveDate"
                            size="small"
                            label="วันที่สิ้นสุด"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={Boolean(touched.taskresolveDate && errors.taskresolveDate)}
                            helperText={touched.taskresolveDate && errors.taskresolveDate}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            defaultValue={values.taskprojectsCode}
                            value={values.taskprojectsCode}
                            size="small"
                            label="-- โครงการ --"
                            name="taskprojectsCode"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.taskprojectsCode && errors.taskprojectsCode)}
                            helperText={touched.taskprojectsCode && errors.taskprojectsCode}
                            onBlur={handleBlur}
                          >
                            {project.map((values) => (
                              <MenuItem key={values.projectsCode} value={values.projectsCode}>
                                {values.projectsName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            defaultValue={values.taskcustomerCode}
                            value={values.taskcustomerCode}
                            size="small"
                            error={Boolean(touched.taskcustomerCode && errors.taskcustomerCode)}
                            fullWidth
                            helperText={touched.taskcustomerCode && errors.taskcustomerCode}
                            label="-- ลูกค้า --"
                            select
                            name="taskcustomerCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                          >
                            {customer.map((values) => (
                              <MenuItem key={values.customerCode} value={values.customerCode}>
                                {values.companyName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            defaultValue={values.tasktypeCode}
                            value={values.tasktypeCode}
                            size="small"
                            label="-- ประเภทงาน --"
                            name="tasktypeCode"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.tasktypeCode && errors.tasktypeCode)}
                            helperText={touched.tasktypeCode && errors.tasktypeCode}
                            onBlur={handleBlur}
                          >
                            {taskType.map((values) => (
                              <MenuItem key={values.tasktypeCode} value={values.tasktypeCode}>
                                {values.tasktypeName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: values.tasklist != null ? true : false }}
                            defaultValue={values.tasklist}
                            value={values.tasklist}
                            size="small"
                            fullWidth
                            label="เรื่องที่แจ้ง"
                            error={Boolean(touched.tasklist && errors.tasklist)}
                            helperText={touched.tasklist && errors.tasklist}
                            name="tasklist"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            defaultValue={values.taskempCode}
                            value={values.taskempCode}
                            size="small"
                            label="-- พนักงาน --"
                            name="taskempCode"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            select
                            error={Boolean(touched.taskempCode && errors.taskempCode)}
                            helperText={touched.taskempCode && errors.taskempCode}
                            onBlur={handleBlur}
                          >
                            {emp.map((values) => (
                              <MenuItem key={values.empCode} value={values.empCode}>
                                {values.emps}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {/* <Grid item md={3} xs={12}>
                          <Autocomplete
                            size="small"
                            defaultValue={values.taskempCode}
                            value={values.taskempCode}
                            freeSolo
                            options={emp.map((item) => item.emps)}
                            renderInput={(params) => (
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                name="taskempCode"
                                id="taskempCode"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                {...params}
                                fullWidth
                                type="text"
                                label="-- พนักงาน --"
                                variant="outlined"
                                error={Boolean(touched.taskempCode && errors.taskempCode)}
                                helperText={touched.taskempCode && errors.taskempCode}
                              />
                            )}
                          />
                        </Grid> */}

                        <Grid item md={3} xs={12}>
                          <TextField
                            defaultValue={values.taskImportanceCode}
                            value={values.taskImportanceCode}
                            size="small"
                            label="-- ระดับความสำคัญ --"
                            name="taskImportanceCode"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            select
                            error={Boolean(touched.taskImportanceCode && errors.taskImportanceCode)}
                            helperText={touched.taskImportanceCode && errors.taskImportanceCode}
                            onBlur={handleBlur}
                          >
                            {importance.map((values) => (
                              <MenuItem key={values.importanceCode} value={values.importanceCode}>
                                {values.importanceName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography className={errors.taskDetail && touched.taskDetail ? "input-feedback" : ""}>
                            รายละเอียด
                          </Typography>

                          {loading ? (
                            <></>
                          ) : (
                            <div className={errors.taskDetail && touched.taskDetail ? "border" : ""}>
                              <CKEditor
                                className={errors.taskDetail && touched.taskDetail ? "text-input error" : ""}
                                name="taskDetail"
                                data={values.taskDetail}
                                onChange={(e) => {
                                  setFieldValue("taskDetail", e.editor.getData());
                                }}
                                config={
                                  {
                                    // Load the default contents.css file plus customizations for this sample.
                                    /* contentsCss: [
                                "http://cdn.ckeditor.com/4.12.1/full-all/contents.css",
                                "https://ckeditor.com/docs/vendors/4.12.1/ckeditor/assets/css/widgetstyles.css",
                              ],
                              // Setup content provider. See https://ckeditor.com/docs/ckeditor4/latest/features/media_embed
                              embed_provider: "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}",

                              // Configure the Enhanced Image plugin to use classes instead of styles and to disable the
                              // resizer (because image size is controlled by widget styles or the image takes maximum
                              // 100% of the editor width).
                              cloudServices_uploadUrl: "https://33333.cke-cs.com/easyimage/upload/",
                                */
                                  }
                                }
                                onBeforeLoad={(CKEDITOR) => {
                                  CKEDITOR.disableAutoInline = true;
                                  CKEDITOR.config.autoParagraph = false;
                                }}
                              />
                            </div>
                          )}
                          <ErrorMessage component="div" name="taskDetail" className="input-feedback " />
                        </Grid>
                      </Grid>
                      <Box display="flex" justifyContent="center" p={2}>
                        <Box mr={3}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<IconSave />}
                            size="large"
                          >
                            บันทึก
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<IconCancel />}
                            onClick={() => {
                              navigate("/app/Task", {
                                replace: true,
                              });
                            }}
                            size="large"
                          >
                            ยกเลิก
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default InsertView;
