import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, Card, CardContent, TextField, InputAdornment, SvgIcon, makeStyles, Grid } from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import IconAdd from "../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));
const handleOnChange = (event) => {
  console.log(event.target.value);
};

const Toolbar = ({ className,searchData,clear, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <p style={{ fontSize: 30 }} >ลูกค้า</p>
        </Breadcrumbs>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box>
              <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    onKeyUp={(e) => {
                      searchData(e.target.value);
                      console.log(e.target.value);
                    }}
                    id="search"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleOnChange}
                    placeholder="ค้นหา..."
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<IconAdd />}
                      onClick={() => {
                        navigate("/app/IndexCustomer", { replace: true });
                      }}
                      size="large"
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
