import React from "react";
import Page from "../../components/Page";
import theme from "../../theme";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { GetDetailQuatationService } from "../../services/services.Qoutation";
import { company } from "../../Data/companyData.json";
import THBText from "thai-baht-text";
import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },

  TyText: { color: "#000", fontSize: "8px" },
});

const PrintQuotation = (className) => {
  const classes = useStyles();
  const param = useLocation();
  const navigate = useNavigate();
  const [quoitem, SetQuoItem] = React.useState({ description: [{}] });

  async function loadQuoItem(param) {
    const resultQuoItem = await GetDetailQuatationService(param);
    SetQuoItem(resultQuoItem.quotation);
  }
  React.useEffect(() => {
    if (param.state !== null) {
      loadQuoItem(param.state.id);
    }
  }, []);

  return (
    <>
      <Page className={(classes.root, clsx(className))}>
        <Box m={3}>
          <Card style={{ width: "210mm" }}>
            <TableContainer id="contentPrint">
              <Table>
                <TableHead style={{ color: "#000", backgroundColor: "#fff" }}>
                  <TableRow>
                    <TableCell align="center">
                      <img
                        src={company.image}
                        width="70"
                        alt={company.image}
                        style={{ margin: -20 }}
                      />
                    </TableCell>
                    <TableCell align="left" colSpan={5}>
                      <Typography className={classes.TyText}>
                        {company.name}
                      </Typography>
                      <Typography className={classes.TyText}>
                        {company.engName}
                      </Typography>
                      <Typography className={classes.TyText}>
                        {company.address}
                      </Typography>
                      <Typography className={classes.TyText}>
                        เลขประจำตัวผู้เสียภาษี:{company.tax}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      <Typography className={classes.TyText} variant="h3">
                        ใบเสนอราคา <br /> QUOTATION
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      <Typography className={classes.TyText}>
                        เรียน/Attention:
                      </Typography>
                      <Typography className={classes.TyText}>
                        {quoitem.companyName}
                      </Typography>
                      <Typography className={classes.TyText}>
                        {quoitem.address}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.TyText}>
                        เลขที่/No. : {quoitem.quotationCode}
                      </Typography>

                      <Typography className={classes.TyText}>
                        วันที่/Date :{quoitem.quotationDate}
                      </Typography>

                      <Typography className={classes.TyText}>
                        หมายเหตุ/Remark : {quoitem.remark}
                      </Typography>

                      <Typography className={classes.TyText}>
                        TEL. : {quoitem.mobile}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" colSpan={5}>
                      <Typography className={classes.TyText}>
                        บริษัท ฮักโค้ด จำกัด
                        ขอเรียนเสนอราคาและเงื่อนไขสำหรับท่านดังนี้
                      </Typography>
                      <Typography className={classes.TyText}>
                        We are please to submit you the following described here
                        in at price, items and terms stated :
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography align="center" className={classes.TyText}>
                        ลำดับที่ <br />
                        ITEM
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center" className={classes.TyText}>
                        รายการ <br /> DESCRIPTION
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center" className={classes.TyText}>
                        จำนวน
                        <br />
                        Quantity
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center" className={classes.TyText}>
                        ราคาต่อหน่วย
                        <br /> Unit Per Price
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center" className={classes.TyText}>
                        จำนวนเงิน
                        <br /> Amount
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quoitem.description.map((item, index) => (
                    <TableRow key={item.quotationItemCode}>
                      <TableCell className={classes.TyText} align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.TyText} align="center">
                        {item.descriptionName}
                      </TableCell>
                      <TableCell align="center" className={classes.TyText}>
                        {item.quantity}
                      </TableCell>
                      <TableCell align="center" className={classes.TyText}>
                        {item.unitPrice}
                      </TableCell>
                      <TableCell align="center" className={classes.TyText}>
                        {item.amountPrice}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography
                        style={{
                          backgroundColor: "#f2f2f2",
                          fontSize: "2px",
                        }}
                      >
                        ตัวอักษร.({THBText(quoitem.netPrice)})
                      </Typography>
                    </TableCell>

                    <TableCell colSpan={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={5} sm={6}>
                          <Typography className={classes.TyText}>
                            รวมเงิน:
                            <br />
                            TOTAL
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={6}>
                          <Typography
                            color="textPrimary"
                            align="center"
                            className={classes.TyText}
                          >
                            {quoitem.totalPrice} บาท
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={5} sm={6}>
                          <Typography className={classes.TyText}>
                            ภาษีมูลค่าเพิ่ม {quoitem.vat}%:
                            <br />
                            VAT {quoitem.vat}%
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={6} className={classes.TyText}>
                          <Typography
                            color="textPrimary"
                            align="center"
                            className={classes.TyText}
                          >
                            {quoitem.vatPrice} บาท
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={5} sm={6}>
                          <Typography className={classes.TyText}>
                            ยอดเงินสุทธิ:
                            <br />
                            NET TOTAL
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={6}>
                          <Typography
                            color="textPrimary"
                            align="center"
                            className={classes.TyText}
                          >
                            {quoitem.netPrice} บาท
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="left">
                      <Typography className={classes.TyText}>
                        รายละเอียดเพิ่มเติมและเงื่อนไข <br />
                        1. การชำระเงิน/Terms of payment :<br />
                        <img src={company.imageBank} width="100" />
                        เลขบัญชี:
                        {company.payment} บัญชีเงินฝากออมทรัพย์
                        <br />
                        สาขา:{company.branch} {company.name}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={5} align="center">
                      <Typography
                        className={classes.TyText}
                        style={{ marginBottom: 50 }}
                      >
                        {company.name}
                      </Typography>
                      <hr />
                      <Typography className={classes.TyText}>
                        ผู้รับมอบอำนาจ
                        <br />
                        Authorized Signature
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container spacing={3}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    /*/navigate("/app/quotation", {
                      replace: true,
                    });*/
                    // let content = document.getElementById("contentPrint");
                    // let printCon = document.getElementById("ifmcontentstoprint").contentWindow;
                    // printCon.document.open();
                    // printCon.document.write(content.innerHTML);
                    // printCon.document.close();
                    // printCon.focus();
                    let css = "";
                    let lin = "";
                    let CssElem = document.getElementsByTagName("style");
                    let link = document.getElementsByTagName("link");
                    for (let index = 0; index < link.length; index++) {
                      lin = lin + link[index].outerHTML;
                    }
                    for (let index = 0; index < CssElem.length; index++) {
                      css = css + CssElem[index].outerHTML;
                    }
                    var divToPrint = document.getElementById("contentPrint"); // เลือก div id ที่เราต้องการพิมพ์
                    var html =
                      "<html>" +
                      "<head>" +
                      css +
                      lin +
                      "</head>" +
                      '<body onload="window.print(); window.close();">' +
                      divToPrint.innerHTML +
                      "</body>" +
                      "</html>";

                    var popupWin = window.open();
                    popupWin.document.open();
                    popupWin.document.write(html);
                    popupWin.document.close();
                  }}
                >
                  Print
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  size="medium"
                  onClick={() => {
                    navigate("/app/quotation", {
                      replace: true,
                    });
                  }}
                >
                  ย้อนกลับ
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Page>
    </>
  );
};

export default PrintQuotation;
