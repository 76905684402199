import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import dateFormat from "dateformat";
import Detail from "../../../icons/Detail";
import IconEdit from "../../../icons/Edit";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate } from "react-router-dom";
import { pageSize } from "../../../Data/pageSize.json";
import { Box, Card, Grid, TextField, Button, MenuItem, Table, Typography, TableBody, CardHeader, TableCell, TableHead, TablePagination, TableRow, makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
}));

const LeavesCardAdmin = ({ className, leaves, currentPage, setpagin, fetchDatas, changPageSize, changCurrentPage, ...rest }) => {
  const classes = useStyles();
  const [selectedLeaveIds] = useState([]);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    changCurrentPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead className="thead-font">
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell width={"180px"}>ชื่อ-นามสกุล</TableCell>
                <TableCell>แผนก</TableCell>
                <TableCell>ตำแหน่ง</TableCell>
                <TableCell width={"140px"}> ประเภทการลางาน</TableCell>
                <TableCell>วันที่เริ่มลางาน</TableCell>
                <TableCell width={"85px"}>ช่วงเวลา </TableCell>
                <TableCell width={"126px"}>สถานะ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaves.map((leave, index) => (
                <TableRow hover key={leave.leaveCode} selected={selectedLeaveIds.indexOf(leave.leaveCode) !== -1}>
                  <TableCell>{(setpagin.currentPage - 1) * setpagin.pageSize + (index + 1)}</TableCell>
                  <TableCell width={"1px"}>
                    {leave.empCode}
                    {leave.titleName} &nbsp;
                    {leave.firstName} &nbsp;
                    {leave.lastName} &nbsp;
                  </TableCell>
                  <TableCell>{leave.departmentName}</TableCell>
                  <TableCell>{leave.positionName}</TableCell>
                  <TableCell>{leave.leavetypeName}</TableCell>
                  <TableCell>{leave.startDate}</TableCell>
                  <TableCell>{leave.peirod == "1" ? "เต็มวัน" : leave.peirod == "2" ? "ครึ่งเช้า" : "ครึ่งบ่าย"}</TableCell>

                  <TableCell>{leave.leaveStatus == "1" ? "อนุมัติ " : leave.leaveStatus == "2" ? "ไม่อนุมัติ" : "รออนุมัติ"}</TableCell>

                  <TableCell>
                    {leave.leaveStatus == "1" ? (
                      <ButtonGroup variant="contained">
                        <Button
                          className={classes.buttoninfo}
                          size="medium"
                          onClick={(e) => {
                            navigate("/app/DetailLeaveAdmin?id" + leave.leaveCode, { state: { id: leave.leaveCode } }, { replace: true });
                          }}
                        >
                          <Detail />
                        </Button>
                      </ButtonGroup>
                    ) : leave.leaveStatus == "2" ? (
                      <ButtonGroup variant="contained">
                        <Button
                           className={classes.buttoninfo}
                          size="medium"
                          onClick={(e) => {
                            navigate("/app/DetailLeaveAdmin?id" + leave.leaveCode, { state: { id: leave.leaveCode } }, { replace: true });
                          }}
                        >
                          <Detail />
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup variant="contained">
                        <Button
                           className={classes.buttonwiring}
                          size="medium"
                          onClick={(e) => {
                            navigate("/app/FormLeaveAdmin?id=" + leave.leaveCode, { state: { id: leave.leaveCode } }, { replace: true });
                          }}
                        >
                          <IconEdit />
                        </Button>

                        <Button
                           className={classes.buttoninfo}
                          size="medium"
                          onClick={(e) => {
                            navigate("/app/DetailLeaveAdmin?id" + leave.leaveCode, { state: { id: leave.leaveCode } }, { replace: true });
                          }}
                        >
                          <Detail />
                        </Button>
                      </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <CardHeader />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {setpagin.rowCount == 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error"> "ไม่พบข้อมูล" </Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>&nbsp; ข้อมูล : &nbsp;{setpagin.rowCount}&nbsp; รายการ</Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                          changPageSize(e.target.value);
                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}> ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination count={setpagin.pageCount} page={currentPage} onChange={handleChangePage} color="primary" />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

LeavesCardAdmin.propTypes = {
  className: PropTypes.string,
  leavesAdmin: PropTypes.array.isRequired,
};

export default LeavesCardAdmin;
