import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import LeavesCard from "./leaveCard";
import Toolbar from "./Toolbar";
import { GetLeavesService } from "../../services/leaves.serervice";
import { connect } from "react-redux";
import { AUTHEN, USERINFO } from "../../actions/authen";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const LeaveView = (Authentication) => {
  const classes = useStyles();
  const [leaves, setleaves] = useState([]);
  const [setpagin, SetPagin] = useState({});
  const [search, Setsearch] = useState("");
  const [currentPage, SetcurrentPage] = useState(1);
  const [pageSize, SetpageSize] = useState(10);
  const [leavetypeCode, setleaveTypeCode] = useState(0);
  const [leaveStatus, setleaveStatus] = useState("0");
  const [date, SetDate] = useState("0");
  const [since, setSince] = useState("0");
  const [to, setTo] = useState("0");

  async function LoadData() {
    let data = await Authentication.Authentication;
    return data;
  }
  useEffect(() => {
    fetchData(search, pageSize, leaveStatus, leavetypeCode, currentPage, date, since, to);
  }, []);

  async function fetchData(search, pageSize, leaveStatus, leavetypeCode, currentPage, date, since, to) {
    let role = await LoadData();
    const result = await GetLeavesService(search, pageSize, leaveStatus, leavetypeCode, currentPage, date, since, to, role.empCode);
    setleaves(result.data);
    SetPagin(result.paging);
  }

  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, pageSize, leaveStatus, leavetypeCode, 1, date, since, to);
  }

  function changCurrentPage(value) {
    console.log(value);
    SetcurrentPage(value);
    fetchData(search, pageSize, leaveStatus, leavetypeCode, value, date, since, to);
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, value, leaveStatus, leavetypeCode, 1, date, since, to);
  }

  function flutterTypeData(value) {
    setleaveTypeCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, leaveStatus, value, 1, date, since, to);
  }

  function flutterStatusData(value) {
    console.log(value);
    SetcurrentPage(1);
    setleaveStatus(value);
    fetchData(search, pageSize, value, leavetypeCode, 1, date, since, to);
  }

  function fetchDeleted() {
    fetchData(search, pageSize, leaveStatus, leavetypeCode, 1, date, since, to);
  }
  function flutterDate(value) {
    SetDate(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, leaveStatus, leavetypeCode, 1, value, since, to);
  }

  function flutterSince(value) {
    setSince(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, leaveStatus, leavetypeCode, 1, date, value, to);
  }

  function flutterTo(value) {
    setTo(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, leaveStatus, leavetypeCode, 1, date, since, value);
  }

  function clear() {
    Setsearch("");
    setleaveTypeCode(0);
    setleaveStatus("0");
    SetcurrentPage(1);
    SetDate("0");
    setSince("0");
    setTo("0");

    fetchData("", pageSize, "0", 0, 1, "0", "0", "0");
  }

  return (
    <Page>
      <Container maxWidth={false}>
        <Toolbar
          searchData={searchData}
          fetchDatas={fetchData}
          flutterTypeData={flutterTypeData}
          flutterStatusData={flutterStatusData}
          clear={clear}
          flutterDate={flutterDate}
          date={date}
          flutterTo={flutterTo}
          flutterSince={flutterSince}
          since={since}
          to={to}
          leaveStatus={leaveStatus}
        />

        <Box mt={3}>
          <LeavesCard
            leaves={leaves}
            fetchDatas={fetchData}
            changPageSize={changPageSize}
            changCurrentPage={changCurrentPage}
            setpagin={setpagin}
            fetchDeleteds={fetchDeleted}
            currentPage={currentPage}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveView);
//export default LeaveView;
