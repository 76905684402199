import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

export async function GetWttaxService(search, pageSize, currentPage, selects, pricetypeCoode) {
  try {
    const response = await Instance.get(
      URL +
        "Withholdingtax?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&selects=" +
        selects +
        "&pricetypeCoode=" +
        pricetypeCoode
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function SaveWttaxService(values) {
  try {
    let formdata = new FormData();
    formdata.append("WtcustomerCode", values.wtcustomerCode);
    formdata.append("WtempCode", values.wtempCode);
    formdata.append("Wtselect", values.wtselect);
    formdata.append("WtpayDate", values.wtpayDate);
    formdata.append("WtAttach", values.wtAttach);
    formdata.append("Wtpricetype", values.wtpricetype);
    formdata.append("Wtamount", values.wtamount);
    formdata.append("Wtvat", values.wtvat);
    formdata.append("WtwithholdingTax", values.wtwithholdingTax);
    formdata.append("Wtinterest", values.wtinterest);
    formdata.append("Wtdividend", values.wtdividend);
    formdata.append("WtdividendProfit", values.wtdividendProfit);
    formdata.append("WtProfitable", values.wtProfitable);
    formdata.append("WtNoprofit", values.wtNoprofit);
    formdata.append("WtdividendDetail", values.wtdividendDetail);
    formdata.append("WtpaymentWithholdingtax", values.wtpaymentWithholdingtax);
    formdata.append("Wtother", values.wtother);
    formdata.append("WtPayer", values.wtPayer);
    const response = await Instance.post(URL + "Withholdingtax/Save", formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function LoadDataService(id) {
  try {
    const response = await Instance.get(URL + "Withholdingtax/LoadData?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function DetailService(id) {
  try {
    const response = await Instance.get(URL + "Withholdingtax/detail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateWttax(values, data) {
  try {
    let formdata = new FormData();
    formdata.append("WtcustomerCode", data.wtcustomerCode);
    formdata.append("WtempCode", data.wtempCode);
    formdata.append("Wtselect", data.wtselect);
    formdata.append("WtpayDate", data.wtpayDate);
    formdata.append("WtAttach", data.wtAttach);
    formdata.append("Wtpricetype", data.wtpricetype);
    formdata.append("Wtamount", data.wtamount);
    formdata.append("Wtvat", data.wtvat);
    formdata.append("WtwithholdingTax", data.wtwithholdingTax);
    formdata.append("Wtinterest", data.wtinterest);
    formdata.append("Wtdividend", data.wtdividend);
    formdata.append("WtdividendProfit", data.wtdividendProfit);
    formdata.append("WtdividendDetail", data.wtdividendDetail);
    formdata.append("WtpaymentWithholdingtax", data.wtpaymentWithholdingtax);
    formdata.append("Wtother", data.wtother);
    formdata.append("WtPayer", data.wtPayer);
    const response = await Instance.put(URL + "Withholdingtax/Update/" + values, formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteWttax(id) {
  try {
    const response = await Instance.delete(URL + "Withholdingtax/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
