import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import Pagination from "@material-ui/lab/Pagination";
import {
  CardHeader,
  Box,
  Card,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableRow,
  makeStyles,
  Typography,
  Button,
  MenuItem,
  Tooltip,
  ButtonGroup,
} from "@material-ui/core";
import swal from "sweetalert";
import IconDel from "../../icons/Del";
import Detail from "../../icons/Detail";
import IconEdit from "../../icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import { province } from "../../Data/provinceData.json";
import { useNavigate } from "react-router-dom";
import { DeleteWttax } from "../../services/wttax.services";
import { pageSize } from "../../Data/pageSize.json";
const TaxCard = ({ changPageSize, currentPage, changCurrentPage, paging, className, wttax, fetchdata, ...rest }) => {
  const navigate = useNavigate();

  const a = wttax.map((value) =>
    province.filter((x) => x.provinceCode == value.addressCode).map((item) => item.provinceName)
  );
  const Del = async (wtwithholdingtaxCode) => {
    let result = await DeleteWttax(wtwithholdingtaxCode);
    if (result.statusCode === 1) {
      fetchdata();
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };
  const handlePageChange = (e, newPage) => {
    changCurrentPage(newPage);
  };
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead className="thead-font">
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell>รหัส</TableCell>
                <TableCell>ชื่อบริษัท</TableCell>
                <TableCell>เลขที่ภาษีอากร</TableCell>
                <TableCell>ที่อยู่</TableCell>
                <TableCell>จัดการข้อมูล</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wttax.map((value, index) => (
                <TableRow hover key={value.wtwithholdingtaxCode}>
                  <TableCell>{(paging.currentPage - 1) * paging.pageSize + (index + 1)}</TableCell>
                  <TableCell>{value.customerCode == null ? value.empCodes : value.customerCode}</TableCell>
                  <TableCell>{value.companyName == null ? value.empName : value.companyName}</TableCell>
                  <TableCell>{value.taxnum == null ? value.idcard : value.taxnum}</TableCell>
                  <TableCell>
                    {value.cusAddress == null ? value.empAddress : value.cusAddress}
                    <br />
                    {province
                      .filter((x) => x.provinceCode == value.empAddressCode)
                      .map(
                        (z) =>
                          "จังหวัด" +
                          " " +
                          z.provinceName +
                          " " +
                          "อำเภอ" +
                          " " +
                          z.districtName +
                          " " +
                          "ตำบล" +
                          " " +
                          z.subdistrictName +
                          " " +
                          "รหัสไปรษณีย์" +
                          " " +
                          z.postCode
                      )}
                    {province
                      .filter((x) => x.provinceCode == value.cusAddressCode)
                      .map(
                        (z) =>
                          "จังหวัด" +
                          " " +
                          z.provinceName +
                          " " +
                          "อำเภอ" +
                          " " +
                          z.districtName +
                          " " +
                          "ตำบล" +
                          " " +
                          z.subdistrictName +
                          " " +
                          "รหัสไปรษณีย์" +
                          " " +
                          z.postCode
                      )}
                  </TableCell>

                  <TableCell>
                    <Box display="flex" justifyContent="start">
                      <ButtonGroup variant="contained">
                        <Tooltip title="แก้ไข" arrow>
                          <Button
                            className="Warning"
                            size="medium"
                            onClick={() => {
                              navigate(
                                "/app/InsertTax?id=" + value.wtwithholdingtaxCode,
                                { state: { id: value.wtwithholdingtaxCode } },
                                { replace: true }
                              );
                            }}
                          >
                            <IconEdit />
                          </Button>
                        </Tooltip>

                        <Tooltip title="ปริ้น" arrow>
                          <Button
                            className="info"
                            color="primary"
                            size="medium"
                            onClick={() => {
                              navigate(
                                "/app/detailtax?id=" + value.wtwithholdingtaxCode,
                                { state: { id: value.wtwithholdingtaxCode } },
                                { replace: true }
                              );
                            }}
                          >
                            <PrintIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="ลบ" arrow>
                          <Button
                            color="secondary"
                            size="medium"
                            onClick={(e) => {
                              swal({
                                className: "bg-modal-red",
                                icon: "warning",
                                dangerMode: true,
                                //title: value.empName == null ? value.companyName : "",
                                text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                buttons: {
                                  cancel: "ยกเลิก",
                                  confirm: {
                                    text: "ใช่",
                                    value: value.wtwithholdingtaxCode,
                                  },
                                },
                              }).then((value) => {
                                if (value !== null) {
                                  Del(value);
                                }
                              });
                            }}
                          >
                            <IconDel />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <CardHeader />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {paging.rowCount == 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error"> "ไม่พบข้อมูล" </Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>&nbsp; ข้อมูล : &nbsp;{paging.rowCount}&nbsp; รายการ</Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                          changPageSize(e.target.value);
                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}> ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination
                      onChange={handlePageChange}
                      color="primary"
                      page={currentPage}
                      count={paging.pageCount}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

TaxCard.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default TaxCard;
