import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

export async function PostLoginService(userNames, passwords) {
  try {
    const response = await Instance.post(URL + `Authenticate/Login?username=${userNames}&pass=${passwords}`);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
