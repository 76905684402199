import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme, colors, TextField, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const Sales = ({ className, fetchData, ...rest }) => {
  const theme = useTheme();
  let today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: { ...rest }.valeuExpenses,
      },
    ],
    labels: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
  };
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card className={clsx(className)} {...rest}>
      <CardHeader
        action={
          <TextField
            value={year}
            size="small"
            label="ปี"
            style={{ width: "90px" }}
            variant="outlined"
            fullWidth
            inputProps={{ defaultValue: 0 }}
            select
            onChange={(e) => {
              setYear(e.target.value);
              fetchData(e.target.value);
            }}
          >
            <MenuItem value={today.getFullYear()}>{today.getFullYear() + 543}</MenuItem>
            {Array.from(Array(today.getFullYear() + 543 - 2560), (e, i) => (
              <MenuItem key={today.getFullYear() - (i + 1)} value={today.getFullYear() - (i + 1)}>
                {today.getFullYear() + 543 - (i + 1)}
              </MenuItem>
            ))}
          </TextField>
        }
        title="ค่าใช้จ่าย"
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button color="primary" endIcon={<ArrowRightIcon />} size="small" variant="text">
          Overview
        </Button>
      </Box>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
};

export default Sales;
