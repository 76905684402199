import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Box, Button, Container, Link, TextField, makeStyles, Card, CardContent, Grid, Typography } from "@material-ui/core";
import Page from "../../components/Page";
import HClogo from "../../assets/images/hc_logo.png";
import { PostLoginService } from "../../services/login.services";
import { connect } from "react-redux";
import { AUTHEN,USERINFO } from "../../actions/authen";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = (Authentication) => {
  console.log(Authentication);
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("กรุณา ใส่อีเมล์ หรือ เบอร์โทรศัพท์ที่ถูกต้อง")
                .max(255)
                .required("กรุณา กรอกอีเมล์ หรือ เบอร์โทรศัพท์"),
              password: Yup.string()
                .max(255)
                .required("กรุณา กรอก รหัสผ่าน"),
            })}
            onSubmit={async (values, action) => {
              let result = await PostLoginService(values.email, values.password);
              if (result.statusCode === 1) {
                Authentication.AUTHEN(result.token);
                Authentication.USERINFO();
                navigate("/app/dashboard", { replace: true });
              } else {
                action.setFieldError("email", result.message);
                action.setFieldError("password", result.message);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
              <Form>
                <Box justifyContent="center" p={2} display="flex"></Box>
                <Box display="flex" justifyContent="center" className="MuiTableHead-root">
                  <img className="img-login" src={HClogo} alt="" />
                </Box>
                <CardContent className="box-content">
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="อีเมล"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="รหัสผ่าน"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />

                  <Box my={2}>
                    <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                      เข้าสู่ระบบ
                    </Button>
                  </Box>
                  <Box ml={55}>
                    <Link component={RouterLink} to="/register" variant="h6">
                      ลืมรหัสผ่าน
                    </Link>
                  </Box>
                </CardContent>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AUTHEN: (token) => dispatch(AUTHEN(token)),
    USERINFO:()=> dispatch(USERINFO())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
//export default LoginView;
