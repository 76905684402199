import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import Developer from "./DeveloperCard";
import Toolbar from "./Toolbar";
import { GetDveTaskService } from "../../services/serviceTask";
import { connect } from "react-redux";
import { USERINFO } from "../../actions/authen";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DeveloperListView = (Authentication) => {
  const classes = useStyles();
  const [tasks, SetTasks] = useState([]);
  const [search, Setsearch] = useState("");
  const [pageSize, SetpageSize] = useState(10);
  const [statusCode, SetstatusCode] = useState(0);
  const [importanceCode, SetimportanceCode] = useState(0);
  const [tasktypeCode, SettasktypeCode] = useState(0);
  const [paging, SetPaging] = useState({});
  const [currentPage, SetcurrentPage] = useState(1);
  const [since, Setsince] = useState("0");
  const [to, Setto] = useState("0");

  async function LoadData() {
    let data = await Authentication.Authentication;
    return data;
  }

  useEffect(() => {
    fetchData(search, pageSize, currentPage, statusCode, importanceCode, tasktypeCode, since, to);
  }, []);

  async function fetchData(search, pageSize, currentPage, statusCode, importanceCode, tasktypeCode, since, to) {
    let role = await LoadData();
    const result = await GetDveTaskService(search, pageSize, currentPage, statusCode, importanceCode, tasktypeCode, since, to, role.empCode);
    SetTasks(result.data);
    SetPaging(result.paging);
  }
  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, pageSize, 1, statusCode, importanceCode, tasktypeCode, since, to);
  }
  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, value, 1, statusCode, importanceCode, tasktypeCode, since, to);
  }
  function changCurrentPage(value) {
    SetcurrentPage(value);
    fetchData(search, pageSize, value, statusCode, importanceCode, tasktypeCode, since, to);
  }
  function changstatusCode(value) {
    SetstatusCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, value, importanceCode, tasktypeCode, since, to);
  }
  function changimportanceCode(value) {
    SetimportanceCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, statusCode, value, tasktypeCode, since, to);
  }
  function changtasktypeCode(value) {
    SettasktypeCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, statusCode, importanceCode, value, since, to);
  }

  function fetchDel() {
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, statusCode, importanceCode, tasktypeCode, since, to);
  }
  function flutterSince(value) {
    Setsince(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, statusCode, importanceCode, tasktypeCode, value, to);
  }

  function flutterTo(value) {
    Setto(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, statusCode, importanceCode, tasktypeCode, since, value);
  }

  function clear() {
    Setsince("0");
    Setto("0");
    Setsearch("");
    SetimportanceCode(0);
    SettasktypeCode(0);
    SetstatusCode(0);
    SetcurrentPage(1);
    fetchData("", pageSize, 1, 0, 0, 0, "0", "0");
  }

  return (
    <Page className={classes.root} title="Developers">
      <Container maxWidth={false}>
        <Toolbar
          flutterSince={flutterSince}
          since={since}
          flutterTo={flutterTo}
          to={to}
          statusCode={statusCode}
          tasktypeCode={tasktypeCode}
          importanceCode={importanceCode}
          search={search}
          clear={clear}
          searchData={searchData}
          fetchDatas={fetchData}
          changstatusCode={changstatusCode}
          changimportanceCode={changimportanceCode}
          changtasktypeCode={changtasktypeCode}
        />
        <Box mt={3}>
          <Developer
            tasks={tasks}
            fetchData={fetchData}
            changPageSize={changPageSize}
            changCurrentPage={changCurrentPage}
            paging={paging}
            currentPage={currentPage}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperListView);
//export default DeveloperListView;
