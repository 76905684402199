import React, { useEffect, useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";
import Budget from "./Budget";
import Sales from "./Sales";
import TasksProgress from "./TasksProgress";
import TotalCustomers from "./TotalCustomers";
import TotalProfit from "./TotalProfit";
import TrafficByDevice from "./TrafficByDevice";
import { Reprot } from "../../../services/report.services";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData("");
  }, []);

  async function fetchData(year) {
    let result = await Reprot(year);
    setData(result);
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            {data.expenses !== undefined ? <Budget valeuExpenses={data.expenses} /> : <Budget valeuExpenses={0} />}
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            {data.expenses !== undefined ? <Sales valeuExpenses={data.data} fetchData={fetchData} /> : <></>}
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            {data.reportTask !== undefined ? <TrafficByDevice valueTask={data.reportTask} /> : <></>}
          </Grid>
          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
