import React, { useState, useEffect } from "react";
import IconSave from "../../../icons/Save";
import IconCancel from "../../../icons/Cancel";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  makeStyles,
  Box,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import { LoadDataLeavesService } from "../../../services/leaves.serervice";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import dateFormat from "dateformat";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Page from "../../../components/Page";
import { Formik, Form, ErrorMessage } from "formik";
import { GetDetailLeavesService } from "../../../services/leaves.serervice";
import { GetDataLeavesService } from "../../../services/leaves.serervice";
import { SaveLeavesService } from "../../../services/leaves.serervice";
import { UpdateLeavesService } from "../../../services/leaves.serervice";
import swal from "sweetalert";
import CKEditor from "ckeditor4-react";
import { leaveStatus } from "../../../Data/leaveData.json";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },

  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));
const LAD = Yup.object().shape({
  leaveStatus: Yup.string().required("กรุณาเลือก สถานะ"),
  reason: Yup.string().required("กรุณากรอก เหตุผลอนุมัติการลา"),
});

const FormLeave = ({ className, fetchDatas, ...rest }) => {
  const classes = useStyles();
  const [setLe, SetLe] = useState({});
  const [setApprova, SetApprova] = useState({});
  const navigate = useNavigate();
  const param = useLocation();
  const [loading, SetLoading] = useState(true);

  useEffect(() => {
    if (param.state !== null) {
      loadLeaves(param.state.id);
      loadApprove(param.state.id);
    }
  }, []);

  async function loadLeaves(param) {
    const resultleaves = await GetDetailLeavesService(param);
    SetLe(resultleaves.data);
    SetLoading(false);
  }
  async function loadApprove(param) {
    const resultleaves = await LoadDataLeavesService(param);
    SetApprova(resultleaves.data);
    SetLoading(false);
  }

  useEffect(() => {
    fetchLeaves();
  }, []);
  async function fetchLeaves() {
    const result = await GetDataLeavesService();

    SetLoading(false);
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateLeavesService(param.state.id, values);
      console.log("update", resultUpdate);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/LeavesCardAdmin", { replace: true });
        } else {
          alert(resultUpdate.mgs);
          console.log(resultUpdate);
        }
      }
    } else {
      let result = await SaveLeavesService(values);
      console.log(result);
      if (result !== null) {
        if (result.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/LeavesCardAdmin", { replace: true });
        } else {
          alert(result.mgs);
          console.log(result);
        }
      }
    }
  };

  return (
    <Page className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/leave">
              ประวัติการลา
            </Link>
            <Link color="textPrimary">รายละเอียดการลางาน</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardHeader />
            <Box display="flex" justifyContent="center">
              <CardHeader />
              <Typography color="textPrimary" variant="h2">
                ข้อมูลการลางาน
              </Typography>
            </Box>
            <CardContent>
              <Formik
                onSubmit={(values) => {
                  SaveValue({
                    empCode: values.empCode != null ? setLe.empCode : "",
                    leavetypeCode:
                      values.leavetypeCode != null
                        ? parseInt(setLe.leavetypeCode)
                        : "",
                    startDate: values.startDate,
                    endDate: values.endDate,
                    peirod: values.peirodCode != null ? values.peirodCode : "",
                    leaveReason:
                      values.leaveReason != null ? values.leaveReason : "",
                    leaveStatus:
                      values.leaveStatus != null ? values.leaveStatus : "",
                    reason: values.reason != null ? values.reason : "",
                    approvalDate:
                      values.approvalDate != null ? values.approvalDate : "",
                    createdDate:
                      values.createdDate != null ? values.createdDate : "",
                  });
                }}
                enableReinitialize
                initialValues={{
                  empCode: setApprova.empCode != null ? setApprova.empCode : "",
                  leavetypeCode:
                    setApprova.leavetypeCode != null
                      ? parseInt(setApprova.leavetypeCode)
                      : "",
                  startDate: setApprova.startDate,
                  endDate: setApprova.endDate,
                  peirodCode:
                    setApprova.peirod != null ? setApprova.peirod : "",
                  leaveReason:
                    setApprova.leaveReason != null
                      ? setApprova.leaveReason
                      : "",

                  leaveStatus:
                    setApprova.leaveStatus != null
                      ? setApprova.leaveStatus
                      : "",
                  reason: setApprova.reason != null ? setApprova.reason : "",
                  approvalDate:
                    setApprova.approvalDate != null
                      ? setApprova.approvalDate
                      : "",
                  createdDate:
                    setApprova.createdDate != null
                      ? setApprova.createdDate
                      : "",
                }}
                validationSchema={LAD}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  values,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form>
                    <Grid container spacing={4}>
                      <Grid item md={12} xs={12} mt={3}>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              รหัสพนักงาน
                            </Typography>
                            <Typography paragraph>
                              {setLe.empCode +
                                "\n" +
                                setLe.firstName +
                                "\n" +
                                setLe.lastName}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              ประเภทการลา
                            </Typography>
                            <Typography paragraph>
                              {setLe.leavetypeName}
                            </Typography>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              ตำแหน่ง
                            </Typography>
                            <Typography paragraph>
                              {setLe.positionName}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              แผนก
                            </Typography>
                            <Typography paragraph>
                              {setLe.departmentName}
                            </Typography>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              วันที่เริ่มการลา
                            </Typography>
                            <Typography paragraph>{setLe.startDate}</Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              วันที่สิ้นสุดการลา
                            </Typography>
                            <Typography paragraph>{setLe.endDate}</Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              เบอร์โทรศัพท์
                            </Typography>
                            <Typography paragraph>
                              {setLe.phoneNumber}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              ช่วงเวลา
                            </Typography>
                            <Typography paragraph>
                              {setLe.peirod == "1"
                                ? "เต็มวัน"
                                : setLe.peirod == "2"
                                ? "ครึ่งเช้า"
                                : "ครึ่งบ่าย"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography variant="h5" component="h2">
                              เหตุผลการลา :
                            </Typography>
                            <Typography paragraph>
                              {setLe.leaveReason}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={12} xl={12} xs={12}>
                        <TextField
                          defaultValue={values.leaveStatus}
                          value={values.leaveStatus}
                          size="small"
                          label=" สถานะการลา"
                          name="leaveStatus"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          select
                          error={Boolean(
                            touched.leaveStatus && errors.leaveStatus
                          )}
                          helperText={touched.leaveStatus && errors.leaveStatus}
                          onBlur={handleBlur}
                        >
                          {leaveStatus.map((values) => (
                            <MenuItem
                              key={values.leaveStatusCode}
                              value={values.leaveStatusCode}
                            >
                              {values.leaveStatusName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography
                          className={
                            errors.reason && touched.reason
                              ? "input-feedback"
                              : ""
                          }
                        >
                          {" "}
                          เหตุผลที่อนุมัติ
                        </Typography>
                        {loading ? (
                          <></>
                        ) : (
                          <div
                            className={
                              errors.reason && touched.reason ? "border" : ""
                            }
                          >
                            <CKEditor
                              className={
                                errors.reason && touched.reason
                                  ? "text-input error"
                                  : ""
                              }
                              helperText={touched.reason && errors.reason}
                              data={values.reason}
                              type="text"
                              name="reason"
                              onChange={(e) => {
                                setFieldValue("reason", e.editor.getData());
                              }}
                              onBeforeLoad={(CKEDITOR) => {
                                CKEDITOR.disableAutoInline = true;
                                CKEDITOR.config.autoParagraph = false;
                              }}
                            />
                          </div>
                        )}

                        <ErrorMessage
                          component="div"
                          name="reason"
                          className="input-feedback "
                        />
                      </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" p={5}>
                      <Box mr={3}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          startIcon={<IconSave />}
                          size="large"
                        >
                          บันทึก
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<IconCancel />}
                          onClick={() => {
                            navigate("/app/LeavesCardAdmin", { replace: true });
                          }}
                          size="large"
                        >
                          ยกเลิก
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default FormLeave;
