import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, Card, CardContent, TextField, MenuItem, InputAdornment, SvgIcon, makeStyles, Grid } from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import IconAdd from "../../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { position, department } from "../../../Data/employeeData";
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const handleOnChange = (event) => {
  console.log(event.target.value);
};

const Toolbar = ({ className, searchData, fetchData, flutterBankData, flutterDepartmentData, flutterpositionmentData, clear, date, flutterDate, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <p style={{ fontSize: 30 }}>รายการพนักงาน</p>
        </Breadcrumbs>
      </Box>
      <Box mt={2}>
        <Card>
          <CardContent>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <Box>
                    <TextField
                      fullWidth
                      id="search"
                      onKeyUp={(e) => {
                        searchData(e.target.value);
                        console.log(e.target.value);
                      }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleOnChange}
                      placeholder="ค้นหา..."
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    onChange={(e) => {
                      flutterpositionmentData(e.target.value);
                      console.log("testValue", e.target.value);
                    }}
                    size="small"
                    inputProps={{ defaultValue: 0 }}
                    label="ตำแหน่ง"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    {position.map((item) => (
                      <MenuItem key={item.PositionCode} value={item.PositionCode}>
                        {item.PositionName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    onChange={(e) => {
                      flutterDepartmentData(e.target.value);
                      console.log("testValue", e.target.value);
                    }}
                    size="small"
                    inputProps={{ defaultValue: 0 }}
                    label="แผนก"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    {department.map((item) => (
                      <MenuItem key={item.departmentCode} value={item.departmentCode}>
                        {item.departmentName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    value={date}
                    id="date"
                    label="วันที่เริ่มทำงาน "
                    type="date"
                    fullWidth
                    name="date"
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      flutterDate(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item md={1} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Tooltip title="ล้างข้อมูลการค้นหา">
                      <IconButton
                        values={0}
                        size="small"
                        onClick={(e) => {
                          document.getElementById("search").value = "";
                          clear();
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>

                <Grid md={2}>
                  {" "}
                  <Box mt={0} display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<IconAdd />}
                      onClick={() => {
                        navigate("/app/Formemployee", { replace: true });
                      }}
                      size="large"
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
