import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import CardQuotation from "./cardQuotation";
import Toolbar from "./Toolbar";
import { GetQuotationService } from "../../services/services.Qoutation";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const QuotationListView = () => {
  const [quotation, SetQuotation] = useState([]);
  const classes = useStyles();
  const [setpagin, SetPagin] = useState({});
  const [search, Setsearch] = useState("");
  const [date, Setdate] = useState("0");
  const [since, Setsince] = useState("0");
  const [to, Setto] = useState("0");
  const [pageSize, SetpageSize] = useState(10);
  const [currentPage, SetcurrentPage] = useState(1);

  useEffect(() => {
    fetchData(search, pageSize, currentPage, date, since, to);
  }, []);

  async function fetchData(
    search,
    expensesType,
    categoryCode,
    pageSize,
    currentPage,
    date,
    since,
    to
  ) {
    const result = await GetQuotationService(
      search,
      expensesType,
      categoryCode,
      pageSize,
      currentPage,
      date,
      since,
      to
    );
    SetQuotation(result.data);
    SetPagin(result.paging);
  }

  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, pageSize, 1, date, since, to);
  }

  function flutterDate(value) {
    Setdate(value);
    SetcurrentPage(1);
    fetchData(
      search,

      pageSize,
      1,
      value,
      since,
      to
    );
  }

  function flutterSince(value) {
    Setsince(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, date, value, to);
  }

  function flutterTo(value) {
    Setto(value);
    SetcurrentPage(1);
    fetchData(
      search,

      pageSize,
      1,
      date,
      since,
      value
    );
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, value, 1, date, since, to);
  }

  function changCurrentPage(value) {
    SetcurrentPage(value);
    fetchData(search, pageSize, value, date, since, to);
  }
  function Deleted() {
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, date, since, to);
  }

  function clear() {
    Setsearch("");
    Setdate("0");
    Setsince("0");
    Setto("0");
    SetcurrentPage(1);
    fetchData("", pageSize, 1, 0, 0, 0, "0", "0");
  }
  return (
    <Page className={classes.root} title="quotation">
      <Container maxWidth={false}>
        <Toolbar
          searchData={searchData}
          date={date}
          since={since}
          flutterSince={flutterSince}
          flutterTo={flutterTo}
          to={to}
          clear={clear}
        />
        <Box mt={3}>
          <CardQuotation
            quotation={quotation}
            setpagin={setpagin}
            fetchData={Deleted}
            changPageSize={changPageSize}
            changCurrentPage={changCurrentPage}
            currentPage={currentPage}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default QuotationListView;
