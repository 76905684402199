import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, FormControl, MenuItem, Button, Card, CardContent, TextField, InputAdornment, SvgIcon, makeStyles, Grid } from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import IconAdd from "../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { GetDataToolExpensesService } from "../../services/services.Expenses";
import { category, typeExpenses } from "../../Data/expensesData";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },

}));

const Toolbar = ({ className, searchData, setCat, flutterDate, date, since, to, flutterSince, flutterTo, expensesType, clear, categoryCode, flutterData, flutterCatData, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [setType, SetType] = useState([]);

  async function fetchExpenses() {
    const result = await GetDataToolExpensesService();
    SetType(result.typeExTool)
  }
  
  useEffect(() => {
    fetchExpenses();
  }, []);

  return (
    <div noValidate className={clsx(classes.root, className,)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <p style={{ fontSize: 30 }}>รายการค่าใช้จ่าย</p>
        </Breadcrumbs>
      </Box>

      <form id="input">
        <Card>
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextField
                    id="search"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      searchData(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action" >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={"ค้นหา... รายการ, จำนวนเงิน, ภาษีมูลค่าเพิ่ม, ยอดรวมสุทธิ"}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    onChange={(e) => {
                      flutterData(e.target.value);
                    }}
                    value={expensesType}
                    id="typeExpenses"
                    name="typeExpenses"
                    size="small"
                    inputProps={{ defaultValue: 0 }}
                    label="ประเภทค่าใช้จ่าย"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    {setType.map((values) => (
                      <MenuItem key={values.expensesTypeCode} value={values.expensesTypeCode}>
                        {values.expensesTypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    onChange={(e) => {
                      flutterCatData(e.target.value);
                    }}
                    value={categoryCode}
                    id="categoryCode"
                    name="categoryCode"
                    size="small"
                    inputProps={{ defaultValue: 0 }}
                    label="หมวดหมู่ค่าใช้จ่าย"
                    variant="outlined"
                    fullWidth
                    select>
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    {setCat.map((values) => (
                      <MenuItem key={values.categoryCode} value={values.categoryCode}>
                        {values.categoryName}
                      </MenuItem>

                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<IconAdd />}
                      onClick={() => {
                        navigate("/app/Formred", { replace: true });
                      }}
                      size="large"
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    value={since}
                    id="date"
                    label="ตั้งแต่วันที่ "
                    type="date"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      flutterSince(e.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    value={to}
                    id="date"
                    label="ถึงวันที่ "
                    type="date"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      flutterTo(e.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Tooltip title="ล้างข้อมูลการค้นหา">
                      <IconButton
                        values={0}
                        size="small"
                        onClick={(e) => {
                          document.getElementById("search").value = ""
                          clear()
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
