import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import "./mixins/chartjs";
import theme from "./theme";
import routes from "./Routes/routes";
import "./App.css";
//import ControlRoutes from "./Routes/controlRoutes";
import { Provider } from "react-redux";
import store from "./stores/store";

const App = () => {
  const routing = useRoutes(routes());

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {/* 
      <BrowserRouter>
        <Routes>
          {routes.map((value) => {
            value.children.map((valueChil) => {
              console.log(valueChil);
              return <ControlRoutes exact path={"/" + valueChil.path} component={valueChil.element} layout={value.element} />;
            });
          })}
        </Routes>
      </BrowserRouter>*/}
        {routing}
      </ThemeProvider>
    </Provider>
  );
};
export default App;
