import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import Showdata from "./showdataSalary";
import Toolbar from "./Toolbar";
import data from "./data";
import { GetSalareService } from "../../services/salary.services";
import { connect } from "react-redux";
import { AUTHEN, USERINFO } from "../../actions/authen";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const ExListView = (Authentication) => {
  const classes = useStyles();
  const [salary, SetSalary] = useState([]);
  const [search, Setsearch] = useState("");
  const [month, Setmonth] = useState("0");
  const [year, Setyear] = useState("0");
  const [pageSize, SetpageSize] = useState(10);
  const [currentPage, SetcurrentPage] = useState(1);
  const [setpagin, SetPagin] = useState({});

  async function LoadData() {
    let data = await Authentication.Authentication;
    return data;
  }

  useEffect(() => {
    fetchData(search, pageSize, currentPage, month, year);
  }, []);

  async function fetchData(search, pageSize, currentPage, month, year) {
    let role = await LoadData();
    const result = await GetSalareService(search, pageSize, currentPage, month, year, role.empCode, role.finance);
    SetSalary(result.data);
    SetPagin(result.paging);
  }

  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, pageSize, 1, month, year);
  }

  function MonthFutter(value) {
    Setmonth(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, value, year);
  }

  function YearFutter(value) {
    Setyear(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, month, value);
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, value, 1, month, year);
  }

  function changCurrentPage(value) {
    SetcurrentPage(value);
    fetchData(search, pageSize, value, month, year);
  }

  function Deleted() {
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, month, year);
  }

  function clear() {
    Setsearch("");
    Setmonth("0");
    Setyear("0");
    SetcurrentPage(1);
    fetchData("", pageSize, 1, "0", "0");
  }

  return (
    <Page className={classes.root} title="expensess">
      <Container maxWidth={false}>
        <Toolbar searchData={searchData} MonthFutter={MonthFutter} YearFutter={YearFutter} monthe={month} yeare={year} clear={clear} />
        <Box mt={3}>
          <Showdata
            salary={salary}
            fetchData={Deleted}
            setpagin={setpagin}
            changPageSize={changPageSize}
            changCurrentPage={changCurrentPage}
            currentPage={currentPage}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExListView);
//export default ExListView;
