import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import { max } from "moment";
import { GetDetailLeavesService } from "../../../services/leaves.serervice";
import Page from "../../../components/Page";
import { Box, CardHeader, Card, Link, Container, CardContent, TextField, Button,Typography, makeStyles, Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: max,
    marginLeft: 50,
    marginRight: 50,
  },
  media: {
    height: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const DetailLeave = ({ className, fetchDatas, ...rest }) => {
  const classes = useStyles();
  const [setLeave, SetLeave] = useState({});
  const navigate = useNavigate();

  const param = useLocation();

  async function loadExpenses(param) {
    const resultLeave = await GetDetailLeavesService(param);
    SetLeave(resultLeave.data);
  }
  useEffect(() => {
    if (param.state !== null) {
      loadExpenses(param.state.id);
    }
  }, []);

  return (
    <Page className={classes.root} noValidate>
    <Container maxWidth={false}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/app/LeavesCardAdmin">
            หน้าแรก
          </Link>
          <Link color="inherit">รายละเอียดงาน</Link>
        </Breadcrumbs>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <CardContent>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h2" component="h2">
                      {"ประเภทการลา :   " + " " + setLeave.leavetypeName}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12} p={3}>
                    <Typography variant="h4" component="h2">
                      {"วันที่สร้าง : " + setLeave.createdDate}
                    </Typography>
                  </Grid>
                  <CardHeader />
                  <Card>
                    <PerfectScrollbar>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography className="detail" variant="h5" component="h2">
                                หัวข้อหลัก
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>รายละเอียดงาน</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                วันที่เริ่มการลา :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.startDate}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                วันที่สิ้นสุดการลา :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.endDate}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                รหัสพนักงาน :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {setLeave.empCode}&nbsp;{setLeave.firstName} &nbsp; {setLeave.lastName}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                แผนก :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.departmentName}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                ตำแหน่ง :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.positionName}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                ช่วงเวลา :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.peirod == "1" ? "เต็มวัน" : setLeave.peirod == "2" ? "ครึ่งเช้า" : "ครึ่งบ่าย" }</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                เหตุผลการลา :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.leaveReason}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                สถานะการลา :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.leaveStatus == "1" ? "อนุมัติ": setLeave.leaveStatus == "2" ?"ไม่อนุมัติ": "รออนุมัติ" }</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                เหตุผลที่อนุมัติ :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography dangerouslySetInnerHTML={{ __html: setLeave.reason }} style={{ width: "900px", wordWrap: "break-word" }} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5" component="h2">
                                วันที่อนุมัติ :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{setLeave.approvalDate}</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </PerfectScrollbar>
                  </Card>
                  <CardHeader />
                </CardContent>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      navigate("/app/Leave", {
                        replace: true,
                      });
                    }}
                    size="large"
                  >
                    ย้อนกลับ
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  </Page>
  );
};

export default DetailLeave;
