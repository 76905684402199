import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: "#f4f6f8",
        height: "100%",
        width: "100%",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".MuiTableHead-root": {
        backgroundColor: "#3f51b5",
        padding: "30px",
        color: "#ffff",
      },

      ".MuiTableHead-root	 .MuiTableRow-root .MuiTableCell-root": {
        color: "#ffff",
      },
      //สีฟ้า//
      ".info": {
        backgroundColor: "#2196f3",
        "&:hover": {
          background: "#1976d2",
        },
      },

      //สีเขียว//
      ".Success": {
        backgroundColor: "#4caf50",
        "&:hover": {
          background: "#388e3c",
        },
      },
      //สีส้ม//
      ".Warning": {
        backgroundColor: "#ff9800",
        "&:hover": {
          background: "#f57c00",
        },
      },
      //สีแดง//
      ".Error": {
        backgroundColor: "#f44336",
        "&:hover": {
          background: "#d32f2f",
        },
      },
      //สีเหลือง//
      ".Secondary": {
        backgroundColor: "#aeea00",
        "&:hover": {
          background: "#c6ff00",
        },
      },
 
      ".Popup": {
        color: "#f44336",
        "&:hover": {
          background: "#d32f2f",
        },
      },
      ".detail": {
       width:"300px"
      },
      ".grey": {
        color:"#546e7a"
       },
      ".logobg": {
        width: "800px",
        height: "720px",
      },
      ".box-content": {
        backgroundColor: "#ffff",
      },
      ".icon-refresh": {
        backgroundColor: "#3f51b5",
      },
    },
  })
);




const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
