import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Card, Link, Container, Button, CardContent, Typography, makeStyles, Grid } from "@material-ui/core";
import IconCancel from "src/icons/Cancel";
import FormLabel from "@material-ui/core/FormLabel";
import Pages from "src/components/Page";
import { DetailService } from "../../../services/wttax.services";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import PrintIcon from "@material-ui/icons/Print";
import TableRow from "@material-ui/core/TableRow";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import THBText from "thai-baht-text";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  Card: { backgroundColor: "" },
  pt6: { fontSize: "8.00px" },
  pt8: { fontSize: "10.667px" },
  pt10: { fontSize: "13.333px" },
  pt8w: { fontSize: "10.667px", fontWeight: 500 },
  pt8wp: { fontSize: "10.667px", fontWeight: 500, padding: theme.spacing(1) },
  pt12: { fontSize: "16.00px", fontWeight: 500 },
  pt13: { fontSize: "17.333px", fontWeight: 500 },
  pt16: { fontSize: "21.333px", fontWeight: 500 },
  pt16w: { fontSize: "21.333px" },
  pt12w: { fontSize: "15.50px" },
  A4: { width: "210mm", height: "297mm", backgroundColor: "#FFF" },
  box1: { margin: theme.spacing(0, 1, 1, 1), borderBottom: "  1.5px solid black", width: "100%" },
  box: { margin: theme.spacing(-0.6, 1, 1, 1), width: "100%", textAlign: "center" },
  tabel12: { fontSize: "16.00px", fontWeight: 500, padding: theme.spacing(0.5) },
  divider: { margin: theme.spacing(0, 0, -2, 0) },
  table1: { margin: theme.spacing(-1.7, 0, 0, 0), padding: theme.spacing(1) },
  lable122: { textAlign: "end" },
  //table3: { fontWeight: "100", fontSize: "13px", fontFamily: "kanit" },
  tabel10: { fontSize: "12px", padding: theme.spacing(1) },
  box2: {
    margin: theme.spacing(-1.2, 1, 1, 1),
    border: "  1.5px solid black",
    width: "100%",
    padding: theme.spacing(0),
    textAlign: "start",
  },
  boxend: {
    margin: theme.spacing(-1.2, 1, 1, 1),
    border: "  1.5px solid black",
    width: "100%",
    padding: theme.spacing(0),
    textAlign: "center",
  },
  box21: {
    margin: theme.spacing(-0.7, 1, 1, 1),
    border: "  1.5px solid black",
    width: "100%",
    padding: theme.spacing(0),
  },
  box3: {
    margin: theme.spacing(1, 1, 1, 1),
    border: " 1.5px solid black",
    width: "50px",
    height: "20px",
    fontSize: "10px",
    textAlign: "center",
  },
  box4: {
    margin: theme.spacing(-3, 1, 1, 1),
    border: "  1.5px solid black",
    width: "100%",
    padding: theme.spacing(-1.5),
  },
  box5: { border: "1.5px solid black", width: "40%", textAlign: "start" },
  tabel: {
    margin: theme.spacing(-1.7, 0, 0, 0),
    padding: theme.spacing(1),
    fontSize: "14px",
  },
  tabelhead: {
    margin: theme.spacing(-0.9, 0, 0, 0),
    padding: theme.spacing(1),
    fontSize: "14px",
  },
  tabel7: { fontSize: "11.50px" },
  tabels: { padding: theme.spacing(2), border: "1.5px solid black", width: "100%" },
  tabel1: { padding: theme.spacing(0), border: "1.5px solid black", width: "16.6666666%", textAlign: "center" },
  alert: {
    padding: theme.spacing(0, 0, 0, 0.5),
    margin: theme.spacing(0, 1),
    border: "  1.5px solid black",
    width: "150px",
    height: "20px",
    fontSize: "13.333px",
    textAlign: "center",
  },

  th: { border: "1.5px solid black" },
  td: {
    border: "1.5px solid black",
  },
}));

const Detailtax = ({ className, fetchDatas, ...rest }) => {
  const classes = useStyles();
  const [setDe, Setdatail] = useState({});
  const navigate = useNavigate();
  const param = useLocation();

  async function detailtax(param) {
    const result = await DetailService(param);
    Setdatail(result.data);
  }
  useEffect(() => {
    if (param.state !== null) {
      detailtax(param.state.id);
    }
  }, []);

  return (
    <Pages className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/taxListView">
              หนังสือรับรองการหักภาษี ณ ที่จ่าย
            </Link>
            <Link color="textPrimary">รายละเอียดหนังสือรับรองการหักภาษี ณ ที่จ่าย</Link>
          </Breadcrumbs>
        </Box>
        {console.log("setDe", setDe)}

        <Box display="flex" justifyContent="center">
          <Typography className={classes.pt16}>หนังสือรับรองการหักภาษี ณ ที่จ่าย (50 ทวิ)</Typography>
        </Box>
        <Box mt={1} display="flex" justifyContent="center" id="contentPrint">
          <div className={classes.A4}>
            <CardContent>
              <div className={classes.tabels}>
                <Grid container spacing={3}>
                  <div className={classes.box1}>
                    <Grid item xs={12}>
                      <Typography className={classes.pt6}>
                        ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแสดงรายการภาษี)
                      </Typography>
                      <Typography className={classes.pt6}>
                        ฉบับที่ 2 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)
                      </Typography>
                    </Grid>
                  </div>
                  <div className={classes.box}>
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={8}>
                          <Box display="flex" justifyContent="center">
                            <Typography className={classes.pt13}>หนังสือรับรองการหักภาษี ณ ที่จ่าย</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="center">
                            <Typography className={classes.pt10}> เล่มที่ ……………</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box display="flex" justifyContent="center">
                            <Typography className={classes.pt8}>ตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="center">
                            <Typography className={classes.pt10}> เลขที่ ……………</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.box2}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel12}>ผู้มีหน้าที่หักภาษี ณ ที่จ่าย :</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography className={classes.tabel12w}>เลขประจำตัวประชาชน :</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.alert}></Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel12}>
                            ชื่อ : บริษัท ฮักโค้ด จำกัด (สำนักงานใหญ่)
                          </Typography>
                        </Box>
                        <Typography className={classes.pt6}>
                          (ให้ระบุว่าเป็นบุคคล นิติบุคคล บริษัท สมาคม หรือคณะนิติบุคคล)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography className={classes.pt12w}>เลขประจำตัวผู้เสียภาษีอากร :</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.alert}>0-1355-60020-52-3</Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.pt6}>(กรอกเฉพาะกรณีเป็นผู้ไม่มีเลขประจำตัวประชาชน)</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel12}>
                            ที่อยู่ : 48/117 หมู่ที่ 3 ต.บ้านใหม่ อ.เมืองปทุมธานี จ.ปทุมธานี 12000 โทร .09-511-45242
                          </Typography>
                        </Box>
                        <Typography className={classes.pt6}>
                          (ให้ระบุชื่ออาคารหมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตาบล/แขวง อำเภอ/เขต
                          จังหวัด)
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.box21}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel12}>ผู้มีหน้าที่หักภาษี ณ ที่จ่าย :</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography className={classes.tabel12w}>เลขประจำตัวประชาชน : </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.alert}>{setDe.idcard}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel12}>
                            ชื่อ : {setDe.companyName != null ? setDe.companyName : setDe.empName}
                          </Typography>
                        </Box>
                        <Typography className={classes.pt6}>
                          (ให้ระบุว่าเป็นบุคคล นิติบุคคล บริษัท สมาคม หรือคณะนิติบุคคล)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography className={classes.pt12w}>เลขประจำตัวผู้เสียภาษีอากร : </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.alert}>{setDe.taxnum}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.pt6}>(กรอกเฉพาะกรณีเป็นผู้ไม่มีเลขประจำตัวประชาชน)</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel12}>
                            ที่อยู่ :{setDe.cusAddress != null ? setDe.cusAddress : setDe.empAddress}
                          </Typography>
                        </Box>
                        <Typography className={classes.pt6}>
                          (ให้ระบุชื่ออาคารหมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตาบล/แขวง อำเภอ/เขต
                          จังหวัด)
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Box display="flex" justifyContent="center">
                          <Typography className={classes.tabel10}>ลำดับที่ *</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.box3}>{setDe.wtwithholdingtaxCode}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className={classes.tabel10}>ในแบบ</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={9} className={classes.divider}>
                        <FormGroup row>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="1"
                              checked={setDe.wtAttach == "1"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(1) ภ.ง.ด.1ก</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="2"
                              checked={setDe.wtAttach == "2"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(2) ภ.ง.ด.1ก พิเศษ</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="3"
                              checked={setDe.wtAttach == "3"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(3) ภ.ง.ด.2</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="4"
                              checked={setDe.wtAttach == "4"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(4) ภ.ง.ด.3</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="5"
                              checked={setDe.wtAttach == "5"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(5) ภ.ง.ด.2ก</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="6"
                              checked={setDe.wtAttach == "6"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(6) ภ.ง.ด.3ก</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="7"
                              checked={setDe.wtAttach == "7"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(7) ภ.ง.ด.53</Typography>}
                            />
                          </Grid>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.table1}>
                    <table className={classes.table3} style={{ border: "1px" }}>
                      <tbody>
                        <tr style={{ fontWeight: "550" }}>
                          <td className="headtable">ประเภทเงินได้ที่จ่าย</td>
                          <td className="bodytable">
                            วัน เดือน ปี
                            <br />
                            ที่จ่ายเงิน
                          </td>
                          <td className="bodytable">
                            จำนวนเงิน
                            <br />
                            ที่จ่าย
                          </td>
                          <td className="bodytable">
                            ภาษี
                            <br />
                            หัก ณ ที่จ่าย
                          </td>
                        </tr>
                        <tr>
                          <td> 1.เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)</td>
                          <td>{setDe.wtpricetype == 1 ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtpricetype == 1 ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtpricetype == 1 ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        <tr>
                          <td>2.ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)</td>
                          <td>{setDe.wtpricetype == 2 ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtpricetype == 2 ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtpricetype == 2 ? setDe.wtwithholdingTax : ""}</td>
                        </tr>                    
                        <tr>
                          <td>3.ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40 (3)</td>
                          <td>{setDe.wtpricetype == 3 ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtpricetype == 3 ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtpricetype == 3 ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        
                        <tr>
                          <td rowspan="11">
                            4. (ก) ค่าดอกเบี้ย ฯลฯ ตามมาตรา 40(4) (ก)
                            <br />
                            &nbsp;&nbsp;&nbsp; (ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40 (4) (ข)
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;(1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี
                            โดยจ่ายจาก
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                            &nbsp;กำไรสุทธิของกิจการที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้
                            <br /> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;(1.1)
                            อัตราร้อยละ 30 ของกำไรสุทธิ
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; (1.2)
                            อัตราร้อยละ 25 ของกำไรสุทธิ
                            <br /> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;(1.3)
                            อัตราร้อยละ 20 ของกำไรสุทธิ
                            <br /> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;(1.4)
                            อัตราอื่น ๆ ระบุ _____________ ของกำไรสุทธิ
                            <br /> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;(2)กรณีผู้ได้รับเงินปันผลไม่ได้รับเครดิตภาษี เนื่องจากจ่ายจาก
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;(2.1)
                            กำไรสุทธิของกิจกำรที่ได้รับยกเว้น
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; (2.2)
                            เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้อง <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                            นำมารวมคำนวณเป็นรำยได้เพื่อเสียภำษีเงินได้นิติบุคคล
                            <br /> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;(2.3)
                            กำไรสุทธิส่วนที่ได้หักผลขำดทุนสุทธิยกมำไม่เกิน 5 ปี <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                            ก่อนรอบระยะบัญชีปีปัจจุบัน
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; (2.4)
                            กำไรที่รับรู้ทำงบัญชีโดยวิธีส่วนได้เสีย (equity method)
                            <br />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; (2.5) อื่น ๆ
                            (ระบุ) _______________________
                          </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>{setDe.wtdividendProfit == "30" ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtdividendProfit == "30" ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtdividendProfit == "30" ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        <tr>
                          <td>{setDe.wtdividendProfit == "25" ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtdividendProfit == "25" ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtdividendProfit == "25" ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        <tr>
                          <td>{setDe.wtdividendProfit == "20" ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtdividendProfit == "20" ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtdividendProfit == "20" ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>                      
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      
                        <tr>
                          <td>
                            5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่าย ตามคำสั่งกรมสรรพากรที่ออกตาม
                            <br />
                            มาตรา 3 เตรส (ระบุ)
                            {setDe.wtpaymentWithholdingtax != null
                              ? "______________" + setDe.wtpaymentWithholdingtax + "______________"
                              : "_____________________________________"}
                            <br />
                            (เช่น รางวัล ส่วนลดหรือประโยชน์ใด ๆ เนื่องจำกกำรส่งเสริมกำรขาย รางวัล <br />
                            ในการประกวด กำรแข่งขัน กำรชิงโชค ค่าแสดงของนักแสดงสำธารณะ ค่าจ้าง <br />
                            ทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริกำร ค่าเบี้ยประกันวินาศภัย ฯลฯ)
                          </td>
                          <td>{setDe.wtpricetype == 5 ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtpricetype == 5 ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtpricetype == 5 ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        <tr>
                          <td>6. อื่น ๆระบุ ___{setDe.wtother}___</td>
                          <td>{setDe.wtpricetype == 6 ? setDe.wtpayDate : ""}</td>
                          <td>{setDe.wtpricetype == 6 ? setDe.wtamount : ""}</td>
                          <td>{setDe.wtpricetype == 6 ? setDe.wtwithholdingTax : ""}</td>
                        </tr>
                        <tr>
                          <td colspan="2" className={classes.lable122}>
                            รวมเงินที่จ่ายและภาษีที่หักนาส่ง
                          </td>
                          <td>{setDe.wtamount}</td>
                          <td>{setDe.wtwithholdingTax}</td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            รวมเงินภาษีที่หักนำส่ง (ตัวอักษร). &nbsp; &nbsp; &nbsp; ({THBText(setDe.wtwithholdingTax)})
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>
                  <div className={classes.box4}>
                    <Grid container>
                      <Grid item xs={1}>
                        <FormLabel component="legend" className={classes.pt8wp}>
                          ผู้จ่ายเงิน
                        </FormLabel>
                      </Grid>
                      <Grid item xs={11}>
                        <FormGroup row>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="1"
                              checked={setDe.wtPayer == "1"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(1) ออกภาษีให้ครั้งเดียว</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="2"
                              checked={setDe.wtPayer == "2"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(2) ออกภาษีให้ตลอดไป</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="3"
                              checked={setDe.wtPayer == "3"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(3) หักภาษี ณ ที่จ่าย</Typography>}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value="4"
                              checked={setDe.wtPayer == "4"}
                              control={<Checkbox size="small" color="primary" />}
                              label={<Typography className={classes.pt8w}>(4) อื่น ๆ.(ระบุ) …………………</Typography>}
                            />
                          </Grid>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.tabel}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableBody>
                                <TableRow>
                                  <TableCell className={classes.box5}>
                                    <Typography className={classes.tabel7}>
                                      คำเตือน ผู้มีหน้าที่ออกหนังสือรับรองการหักภาษี ณ ที่จ่าย
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ฝ่าฝืนไม่ปฏิบัติตำมมาตรา
                                      50 ทวิ แห่งประมวล
                                      <br />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      รัษฎากรต้องรับโทษทางอาญาตามมาตรา 25
                                      <br />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แห่งประมวลรัษฎากร
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.boxend}>
                                    <Box display="flex" justifyContent="center">
                                      <Typography className={classes.tabel7}>
                                        ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้ำงต้นถูกต้องตรงกับความจริงทุกประการ
                                      </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                      <Typography className={classes.tabel7}>
                                        <br />
                                        ลงชื่อ…………………………………………………ผู้จ่ายเงิน
                                      </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                      <Typography className={classes.tabel7}>{setDe.wtpayDate}</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                      <Typography className={classes.tabel7}>
                                        ( วัน เดือน ปี ที่ออกหนังสือรับรอง)
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </div>
            </CardContent>
          </div>
        </Box>
        <Box display="flex" justifyContent="center" p={2}>
          <Box mr={3}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                let css = "";
                let lin = "";
                let CssElem = document.getElementsByTagName("style");
                let link = document.getElementsByTagName("link");
                for (let index = 0; index < link.length; index++) {
                  lin = lin + link[index].outerHTML;
                }
                for (let index = 0; index < CssElem.length; index++) {
                  css = css + CssElem[index].outerHTML;
                }
                var divToPrint = document.getElementById("contentPrint"); // เลือก div id ที่เราต้องการพิมพ์
                var html =
                  "<html>" +
                  "<head>" +
                  lin +
                  css +
                  "</head>" +
                  '<body onload="window.print(); window.close();">' +
                  divToPrint.innerHTML +
                  "</body>" +
                  "</html>";

                var popupWin = window.open();
                popupWin.document.open();
                popupWin.document.write(html);
                popupWin.document.close();
              }}
            >
              <PrintIcon /> &nbsp;&nbsp;ปริ้น
            </Button>
          </Box>
          <Box>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<IconCancel />}
              onClick={() => {
                navigate("/app/taxListView", {
                  replace: true,
                });
              }}
              size="large"
            >
              ยกเลิก
            </Button>
          </Box>
        </Box>
      </Container>
    </Pages>
  );
};

export default Detailtax;