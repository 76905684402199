import React, { useEffect, useState } from "react";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import Page from "src/components/Page";
import swal from "sweetalert";
import IconCancel from "src/icons/Cancel";
import IconSave from "src/icons/Save";
import * as Yup from "yup";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import IconDel from "src/icons/Del";
import { vat } from "../../../Data/quotationData";
import { makeStyles } from "@material-ui/core/styles";
import {
  SaveQuotationService,
  UpdateQuotationService,
  LoadDataQuotationService,
  GetDataCusService,
  DeleteQuoitemService,
} from "../../../services/services.Qoutation";
import { useNavigate, useLocation } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import {
  Grid,
  TextField,
  Box,
  MenuItem,
  Card,
  CardContent,
  Container,
  Button,
  Breadcrumbs,
  Typography,
  CardHeader,
} from "@material-ui/core";
import { VpnLock } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  media: {
    height: 0,
  },
  card: {
    height: "100%",
    width: "100%",
  },
}));

const schema = Yup.object({
  customerCode: Yup.string().required("กรุณาเลือกข้อมูล").typeError("กรุณาเลือกข้อมูล"),
  vat: Yup.number()
    .required("กรุณาเลือกเรทภาษี")
    .typeError("กรุณาเลือกเรทภาษี"),
  description: Yup.array().of(
    Yup.object().shape({
      descriptionName: Yup.string()
        .required("กรุณากรอกข้อมูลรายการ")
        .typeError("กรุณากรอกข้อมูลรายการ"),

      quantity: Yup.number("กรณากรอกข้อมูลเป็นตัวเลข")
        .max(999999999, "กรุณากรอกจำนวนเงินน้อยกว่าหรือเท่ากับ 9 หลัก")
        .required("กรุณากรอกจำนวน")
        .typeError("กรุณากรอกจำนวน"),
      unitPrice: Yup.number("กรณากรอกข้อมูลเป็นตัวเลข")
        .max(999999999, "กรุณากรอกจำนวนเงินน้อยกว่าหรือเท่ากับ 9 หลัก")
        .required("กรุณากรอกราคา")
        .typeError("กรุณากรอกราคา"),
    })
  ),
});
const InsertQuotation = (className) => {
  const navigate = useNavigate();
  const [typeCustomer, SetCustomet] = useState([]);
  const [quotation, SetQuoItem] = useState({});
  const [description, setDescription] = useState([]);

  const param = useLocation();
  const classes = useStyles();

  async function fetchQuotation() {
    const result = await GetDataCusService();
    SetCustomet(result.typeCustomer);
  }

  async function loadQuotation(param) {
    const resultQuotation = await LoadDataQuotationService(param);
    SetQuoItem(resultQuotation.quotation);
    setDescription(resultQuotation.description);
  }

  useEffect(() => {
    if (param.state !== null) {
      loadQuotation(param.state.id);
    }
    fetchQuotation();
  }, []);

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateQuotationService(param.state.id, values);
      if (resultUpdate.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/quotation", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    } else {
      let result = await SaveQuotationService(values);
      if (result.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/quotation", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    }
  };

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  const DelQuoItem = async (value) => {
    let result = await DeleteQuoitemService(value);
    if (result.statusCode === 1) {
      if (param.state !== null) {
        loadQuotation(param.state.id);
      }
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Page className={(classes.root, clsx(className))}>
      <Box m={3}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/quotation">
              บัททึกข้อมูลใบเสนอราคา
            </Link>

            <Typography color="textPrimary">รายการใบเสนอราคา</Typography>
          </Breadcrumbs>
        </Box>
        <Formik
          onSubmit={(values) => {
            SaveValue(values);
          }}
          enableReinitialize
          initialValues={{
            remark: quotation.remark != null ? quotation.remark : "",

            customerCode:
              quotation.customerCode != null ? quotation.customerCode : "",
              
            vat: quotation.vat !== null ? parseInt(quotation.vat) : "",

            description: (description.length === 0
              ? [
                  {
                    quotationItemCode:
                      description.quotationItemCode != null
                        ? description.quotationItemCode
                        : 0,
                    descriptionName:
                      description.descriptionName != null
                        ? description.descriptionName
                        : "",
                    quantity:
                      description.quantity != null
                        ? parseInt(description.quantity)
                        : null,
                    unitPrice:
                      description.unitPrice != null
                        ? parseFloat(description.unitPrice)
                        : null,
                  },
                ]
              : description),
          }}
          validationSchema={schema}
        >
          {({ errors, touched, handleBlur, values, handleChange ,setFieldValue}) => (
            <Form>
              <Box mt={3}>
                <Card className={classes.card}>
                  <CardHeader />
                  <Box display="flex" justifyContent="center">
                    <CardHeader />
                    <Typography color="textPrimary" variant="h3">
                      บัททึกข้อมูลใบเสนอราคา
                    </Typography>
                  </Box>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                    
                        <TextField
                          value={values.customerCode}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleChange}
                          size="small"
                          label="-- รหัสลูกค้า,ชื่อผู้ใช้จ่าย--"
                          name="customerCode"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          select
                   
                          error={Boolean(
                            touched.customerCode && errors.customerCode
                          )}
                          helperText={
                            touched.customerCode && errors.customerCode
                          }
                          onBlur={handleBlur}
                        >
                          {typeCustomer.map((item) => (
                            <MenuItem
                              key={item.customerCode}
                              value={item.customerCode}
                            >
                              {item.customerCode + "" + item.companyName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            value={values.vat}
                            size="small"
                            label="-- อัตตราภาษี --"
                            name="vat"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.vat && errors.vat)}
                            helperText={touched.vat && errors.vat}
                            onBlur={handleBlur}
                          >
                            {vat.map((values) => (
                              <MenuItem value={values.vat} key={values.vat}>
                                {values.vatName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            value={values.remark}
                            name="remark"
                            size="small"
                            fullWidth
                            label="หมายเหตุ"
                            autoComplete="off"
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.remark && errors.remark)}
                            helperText={touched.remark && errors.remark}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={1}>
                <Card className={classes.card}>
                  <CardHeader />
                  <Box display="flex" justifyContent="start">
                    <CardHeader />
                    <Typography color="textPrimary" variant="h4">
                      รายการใบเสนอราคา
                    </Typography>
                  </Box>
                  <CardContent>
                    <FieldArray
                      name="description"
                      render={({ insert, remove, push }) => (
                        <Box>
                          {values.description.length > 0 &&
                            values.description.map((friend, index) => (
                              <Box
                                key={
                                  values.description[index].quotationItemCode
                                }
                              >
                                <Typography>รายการที่ {index + 1}</Typography>
                                <Grid container spacing={1}>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      value={
                                        values.description[index]
                                          .descriptionName
                                      }
                                      fullWidth
                                      multiline
                                      rows={4}
                                      type="text"
                                      label="รายการ"
                                      autoComplete="off"
                                      variant="outlined"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`description.${index}.descriptionName`}
                                      helperText={
                                        errors &&
                                        errors.description &&
                                        errors.description[index] &&
                                        errors.description[index]
                                          .descriptionName &&
                                        touched &&
                                        touched.description &&
                                        touched.description[index] &&
                                        touched.description[index]
                                          .descriptionName && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.description[index]
                                                .descriptionName
                                            }
                                          </div>
                                        )
                                      }
                                      error={
                                        errors &&
                                        errors.description &&
                                        errors.description[index] &&
                                        errors.description[index]
                                          .descriptionName &&
                                        touched &&
                                        touched.description &&
                                        touched.description[index] &&
                                        touched.description[index]
                                          .descriptionName && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.description[index]
                                                .descriptionName
                                            }
                                          </div>
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item md={5} xs={12}>
                                    <TextField
                                      value={values.description[index].quantity}
                                      fullWidth
                                      type="text"
                                      label="จำนวน"
                                      autoComplete="off"
                                      variant="outlined"
                                      size="small"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`description.${index}.quantity`}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      helperText={
                                        errors &&
                                        errors.description &&
                                        errors.description[index] &&
                                        errors.description[index].quantity &&
                                        touched &&
                                        touched.description &&
                                        touched.description[index] &&
                                        touched.description[index].quantity && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {errors.description[index].quantity}
                                          </div>
                                        )
                                      }
                                      error={
                                        errors &&
                                        errors.description &&
                                        errors.description[index] &&
                                        errors.description[index].quantity &&
                                        touched &&
                                        touched.description &&
                                        touched.description[index] &&
                                        touched.description[index].quantity && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {errors.description[index].quantity}
                                          </div>
                                        )
                                      }
                                    />
                                  </Grid>

                                  <Grid item md={5} xs={12}>
                                    <TextField
                                      value={
                                        values.description[index].unitPrice
                                      }
                                      fullWidth
                                      type="text"
                                      label="ราคา/หน่วย"
                                      autoComplete="off"
                                      variant="outlined"
                                      size="small"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`description.${index}.unitPrice`}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      helperText={
                                        errors &&
                                        errors.description &&
                                        errors.description[index] &&
                                        errors.description[index].unitPrice &&
                                        touched &&
                                        touched.description &&
                                        touched.description[index] &&
                                        touched.description[index]
                                          .unitPrice && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.description[index]
                                                .unitPrice
                                            }
                                          </div>
                                        )
                                      }
                                      error={
                                        errors &&
                                        errors.description &&
                                        errors.description[index] &&
                                        errors.description[index].unitPrice &&
                                        touched &&
                                        touched.description &&
                                        touched.description[index] &&
                                        touched.description[index]
                                          .unitPrice && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.description[index]
                                                .unitPrice
                                            }
                                          </div>
                                        )
                                      }
                                    />
                                  </Grid>

                                  <Grid item md={2}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        if (
                                          param.state !== null &&
                                          values.description[index]
                                            .quotationItemCode != undefined
                                        ) {
                                          swal({
                                            className: "bg-modal-red",
                                            icon: "warning",
                                            dangerMode: true,
                                            text:
                                              "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                            buttons: {
                                              cancel: "ยกเลิก",
                                              confirm: {
                                                text: "ใช่",
                                                value:
                                                  values.description[index]
                                                    .quotationItemCode,
                                              },
                                            },
                                          }).then((value) => {
                                            if (value !== null) {
                                              DelQuoItem(value);
                                              remove(index);
                                            }
                                          });
                                        } else {
                                          swal({
                                            className: "bg-modal-red",
                                            icon: "warning",
                                            dangerMode: true,
                                            text:
                                              "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                            buttons: {
                                              cancel: "ยกเลิก",
                                              confirm: {
                                                text: "ใช่",
                                                value: 1,
                                              },
                                            },
                                          }).then((value) => {
                                            if (value !== null) {
                                              swal({
                                                title: "สำเร็จ!",
                                                text: "",
                                                icon: "success",
                                                showConfirmButton: false,
                                                button: "ปิด",
                                                timer: 1500,
                                              });
                                              remove(index);
                                            }
                                          });
                                        }
                                      }}
                                      size="medium"
                                    >
                                      <IconDel />
                                    </Button>

                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        push({
                                          descriptionName:
                                            description.descriptionName != null
                                              ? description.descriptionName
                                              : null,
                                          quantity:
                                            description.quantity != null
                                              ? description.quantity
                                              : null,
                                          unitPrice:
                                            description.unitPrice != null
                                              ? description.unitPrice
                                              : null,
                                        })
                                      }
                                      size="medium"
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))}
                          {values.description.length === 0
                            ? push({
                                descriptionName:
                                  description.descriptionName != null
                                    ? description.descriptionName
                                    : null,
                                quantity:
                                  description.quantity != null
                                    ? description.quantity
                                    : null,
                                unitPrice:
                                  description.unitPrice != null
                                    ? description.unitPrice
                                    : null,
                              })
                            : ""}
                        </Box>
                      )}
                    />

                    <Box display="flex" justifyContent="center" p={2}>
                      <Box mr={3}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          startIcon={<IconSave />}
                          size="large"
                        >
                          บันทึก
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<IconCancel />}
                          size="large"
                          onClick={() => {
                            navigate("/app/quotation", { replace: true });
                          }}
                        >
                          ยกเลิก
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

export default InsertQuotation;
