import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import IconSave from "../../../icons/Save";
import CKEditor from "ckeditor4-react";
import IconCancel from "../../../icons/Cancel";
import swal from "sweetalert";
import {
  UpdateDevTaskService,
  GetdataTaskService,
  LoadNewTaskService,
  GetDetailTaskService,
} from "../../../services/serviceTask";
import {
  Divider,
  Box,
  Button,
  CardHeader,
  Card,
  Container,
  Link,
  CardContent,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Page from "../../../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const taskSchema = Yup.object().shape({
  taskSolution: Yup.string().required("กรุณากรอก วิธีแก้ไขปัญหา"),
});

const SolveDev = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [emp, Setemp] = useState([]);
  const [customer, Setcustomer] = useState([]);
  const [project, Setproject] = useState([]);
  const [loading, SetLoading] = useState(true);
  const param = useLocation();
  const [setTask, SetTask] = useState({});
  const [setDetail, Setdetail] = useState({});

  async function loadTask(param) {
    const resultTask = await LoadNewTaskService(param);
    SetTask(resultTask.data);
    SetLoading(false);
  }
  async function loadDetail(param) {
    const resultTask = await GetDetailTaskService(param);
    Setdetail(resultTask.data);
  }

  useEffect(() => {
    if (param.state !== null) {
      loadTask(param.state.id);
      loadDetail(param.state.id);
    }
  }, []);

  async function fetchdata() {
    const result = await GetdataTaskService();
    Setemp(result.emp);
    Setcustomer(result.customer);
    Setproject(result.project);
  }
  useEffect(() => {
    fetchdata();
  }, []);

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateDevTaskService(param.state.id, values);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/Developer", { replace: true });
        } else {
          alert(resultUpdate.mgs);
        }
      }
    }
  };
  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/Developer">
              รายการงานที่ได้รับหมอบหมาย
            </Link>
            <Link color="inherit">อัปเดพหน้าที่</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardHeader />
            <CardContent>
              <Box display="flex" justifyContent="center">
                <CardHeader />
                <Typography color="textPrimary" variant="h2">
                  ข้อมูลการมอบหมายงาน
                </Typography>
              </Box>
              <CardHeader />
              <CardContent>
                <Formik
                  onSubmit={(values) => {
                    SaveValue({
                      taskprojectsCode: parseInt(values.taskprojectsCode),
                      taskcustomerCode: values.taskcustomerCode,
                      tasktypeCode: parseInt(values.tasktypeCode),
                      taskstatusCode: parseInt(values.taskstatusCode),
                      taskImportanceCode: parseInt(values.taskImportanceCode),
                      taskempCode: values.taskempCode,
                      taskcreatedBy: parseInt(values.taskcreatedBy) != null ? parseInt(values.taskcreatedBy) : "",
                      notes: values.notes,
                      tasklist: values.tasklist,
                      taskIsuse: values.taskIsuse,
                      taskRefers: values.taskRefers,
                      taskDetail: values.taskDetail,
                      taskSolution: values.taskSolution,
                      taskstartDate: values.taskstartDate,
                      taskgetjobDate: values.taskgetjobDate,
                      taskresolveDate: values.taskresolveDate,
                    });
                  }}
                  enableReinitialize
                  initialValues={{
                    taskstatusCode: setDetail.statusCode != null ? 1 : parseInt(setDetail.statusCode),
                    taskprojectsCode: parseInt(setDetail.projectsCode),
                    taskcustomerCode: setTask.customerCode != null ? setTask.customerCode : "",
                    tasktypeCode: parseInt(setDetail.tasktypeCode),
                    taskImportanceCode: parseInt(setDetail.importanceCode),
                    taskempCode: setDetail.taskempCode,
                    taskstartDate: setDetail.taskstartDate,
                    taskresolveDate: setDetail.taskresolveDate,
                    taskDetail: setDetail.taskDetail,
                    tasklist: setDetail.tasklist,
                    taskgetjobDate: setDetail.taskgetjobDate,
                    taskcreatedBy: setDetail.taskcreatedBy != null ? parseInt(setDetail.taskcreatedBy) : "",
                    taskSolution: setDetail.taskSolution != null ? setDetail.taskSolution : "",
                  }}
                  validationSchema={taskSchema}
                >
                  {({ setFieldValue, touched, errors, values }) => (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12} mt={3}>
                          <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                วันที่มอบหมายงาน
                              </Typography>
                              <Typography paragraph>{setTask.taskcreatedDate}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                วันที่เริ่มงาน
                              </Typography>
                              <Typography paragraph>{setTask.taskstartDate + " " + "น."}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                วันที่สิ้นสุด
                              </Typography>
                              <Typography paragraph>{setTask.taskresolveDate + " " + "น."}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                ผู้มอบหมายงาน
                              </Typography>
                              <Typography paragraph>{setTask.taskcreatedByName}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                โครงกาาร
                              </Typography>
                              <Typography paragraph>{setTask.projectsName}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                ลูกค้า
                              </Typography>
                              <Typography paragraph>{setTask.companyName}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                ระดับความสำคัญ
                              </Typography>
                              <Typography paragraph>{setTask.importanceName}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                ประเภทงาน
                              </Typography>
                              <Typography paragraph>{setTask.tasktypeName}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h5" component="h2">
                                เรื่องที่แจ้ง
                              </Typography>
                              <Typography paragraph>{setTask.tasklist}</Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography variant="h5" component="h2">
                                รายละเอียดงาน...
                              </Typography>
                              <Typography paragraph dangerouslySetInnerHTML={{ __html: setTask.taskDetail }} />
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography className={errors.taskSolution && touched.taskSolution ? "input-feedback" : ""}>
                            วิธีแก้ไขปัญหา
                          </Typography>
                          {loading ? (
                            <></>
                          ) : (
                            <div className={errors.taskSolution && touched.taskSolution ? "border" : ""}>
                              <CKEditor
                                dangerouslySetInnerHTML={{ __html: "taskDetail &middot; Second" }}
                                activeClass="editor"
                                className={errors.taskSolution && touched.taskSolution ? "text-input error" : ""}
                                variant="outlined"
                                data={values.taskSolution}
                                label="รายละเอียด"
                                name="taskSolution"
                                onChange={(e) => {
                                  setFieldValue("taskSolution", e.editor.getData());
                                }}
                                onBeforeLoad={(CKEDITOR) => {
                                  CKEDITOR.disableAutoInline = true;
                                  CKEDITOR.config.autoParagraph = false;
                                }}
                              />
                            </div>
                          )}
                          <ErrorMessage component="div" name="taskSolution" className="input-feedback " />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Typography>หมายเหตุ</Typography>
                          {loading ? (
                            <></>
                          ) : (
                            <CKEditor
                              dangerouslySetInnerHTML={{ __html: "taskDetail &middot; Second" }}
                              activeClass="editor"
                              variant="outlined"
                              data={values.notes}
                              type="text"
                              label="รายละเอียด"
                              name="notes"
                              id="notes"
                              onChange={(e) => {
                                setFieldValue("notes", e.editor.getData());
                              }}
                              onBeforeLoad={(CKEDITOR) => {
                                CKEDITOR.disableAutoInline = true;
                                CKEDITOR.config.autoParagraph = false;
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Box display="flex" justifyContent="center" p={2}>
                        <Box mr={3}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<IconSave />}
                            size="large"
                          >
                            บันทึก
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<IconCancel />}
                            onClick={() => {
                              navigate("/app/Developer", {
                                replace: true,
                              });
                            }}
                            size="large"
                          >
                            ยกเลิก
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default SolveDev;
