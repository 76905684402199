import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import Pagination from "@material-ui/lab/Pagination";
import {
  TextField,
  MenuItem,
  CardHeader,
  Avatar,
  Box,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Button,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ED from "../../icons/Edite";
import DT from "../../icons/Detail";
import { useNavigate } from "react-router-dom";
import { pageSize } from "../../Data/pageSize.json";
import { Addfunctions } from "../../Helper/addfunc";
const useStyles = makeStyles(() => ({
  buttonsuccess: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
}));

const Developer = ({ tasks, paging, currentPage, changPageSize, changCurrentPage }) => {
  const classes = useStyles();

  const handlePageChange = (e, newPage) => {
    changCurrentPage(newPage);
  };
  const navigate = useNavigate();

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1100}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell>ประเภทงาน</TableCell>
                <TableCell>เรื่องแจ้ง</TableCell>
                <TableCell>วันที่กำหนดการแก้ไขปัญหา </TableCell>
                <TableCell>ความสำคัญ</TableCell>
                <TableCell>สถานะ</TableCell>
                <TableCell>จัดการข้อมูล</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tasks.map((item, index) => (
                <TableRow hover key={item.taskCode}>
                  <TableCell>{(paging.currentPage - 1) * paging.pageSize + (index + 1)}</TableCell>
                  <TableCell>{item.tasktypeName}</TableCell>
                  <TableCell>{item.tasklist}</TableCell>
                  <TableCell>
                    {item.taskstartDate}&nbsp;ถึง&nbsp;
                    {item.taskresolveDate}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <Addfunctions.Importance vara={item.importanceName} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Addfunctions.Status para={item.statusName} />
                  </TableCell>
                  <TableCell>
                    {item.statusCode != 2 ? (
                      <Tooltip title="รายละเอียด" arrow>
                        <Button
                          className={classes.buttoninfo}
                          size="medium"
                          onClick={(e) => {
                            navigate("/app/detaildev", { state: { id: item.taskCode } }, { replace: true });
                          }}
                        >
                          <DT />
                        </Button>
                      </Tooltip>
                    ) : (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Tooltip title="แก้ไข" arrow>
                          <Button
                            className={classes.buttonwiring}
                            size="medium"
                            onClick={() => {
                              navigate(
                                "/app/SolveDev?id=" + item.taskCode,
                                { state: { id: item.taskCode } },
                                { replace: true }
                              );
                            }}
                          >
                            <ED />
                          </Button>
                        </Tooltip>
                        <Tooltip title="รายละเอียด" arrow>
                          <Button
                            className={classes.buttoninfo}
                            size="medium"
                            onClick={(e) => {
                              navigate("/app/detaildev", { state: { id: item.taskCode } }, { replace: true });
                            }}
                          >
                            <DT />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <CardHeader />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {paging.rowCount == 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error"> "ไม่พบข้อมูล" </Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>&nbsp; ข้อมูล : &nbsp;{paging.rowCount}&nbsp; รายการ</Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                          changPageSize(e.target.value);
                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}>ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination
                      onChange={handlePageChange}
                      color="primary"
                      page={currentPage}
                      count={paging.pageCount}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

Developer.propTypes = {
  className: PropTypes.string,
  tasks: PropTypes.array.isRequired,
};

export default Developer;
