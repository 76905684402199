import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes, { func } from "prop-types";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import { Avatar, Divider, Drawer, Hidden, List, Typography, makeStyles, Box } from "@material-ui/core";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
} from "react-feather";
import NavItem from "./NavItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { connect } from "react-redux";
import { USERINFO } from "../../../actions/authen";
import { URLPDF } from "../../../Helper/baseURL";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function NestedList(props) {
  const [open, setOpen] = React.useState(false);
  const [openTask, setOpenTask] = React.useState(false);
  const [openLeave, setopenLeave] = React.useState(false);
  const [openaccount, setopenaccount] = React.useState(false);
  const [role, setRole] = React.useState(props.role);

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    setRole(props.role);
  }, [props]);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickTask = () => {
    setOpenTask(!openTask);
  };

  const handleClickLeave = () => {
    setopenLeave(!openLeave);
  };

  const handleClickaccount = () => {
    setopenaccount(!openaccount);
  };
  return (
    <List>
      <ListItem
        button
        onClick={() => {
          navigate("/app/dashboard", { replace: true });
        }}
      >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="หน้าหลัก" />
      </ListItem>
      {role.tasks === "0" ? (
        <></>
      ) : (
        <>
          <ListItem button onClick={handleClickTask}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="มอบหมายงาน" />
            {openTask ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openTask} timeout="auto" unmountOnExit>
            {role.tasks ==="2" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  navigate("/app/Task", { replace: true });
                }}
              >
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="มอบหมายงาน" />
              </ListItem>
            ) : (
              <></>
            )}

            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                navigate("/app/Developer", { replace: true });
              }}
            >
              <ListItemIcon>
                <AssignmentLateIcon />
              </ListItemIcon>
              <ListItemText primary="งานที่ได้รับมอบหมาย" />
            </ListItem>
          </Collapse>
        </>
      )}

      {role.level === "0" ? (
        <></>
      ) : (
        <>
          <ListItem button onClick={handleClickLeave}>
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText primary="การลา" />
            {openLeave ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openLeave} timeout="auto" unmountOnExit>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                navigate("/app/Leave", { replace: true });
              }}
            >
              <ListItemIcon>
                <HowToVoteIcon />
              </ListItemIcon>
              <ListItemText primary="การลางาน" />
            </ListItem>
            {role.level ==="2" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  navigate("/app/LeavesCardAdmin", { replace: true });
                }}
              >
                <ListItemIcon>
                  <ContactMailIcon />
                </ListItemIcon>
                <ListItemText primary="อนุมัติการลางาน" />
              </ListItem>
            ) : (
              <></>
            )}
          </Collapse>
        </>
      )}

      {role.finance === "0" ? (
        <></>
      ) : (
        <>
          {role.finance === "1" ? (
            <>
              <ListItem button onClick={handleClickaccount}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary="การเงิน" />
                {openaccount ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openaccount} timeout="auto" unmountOnExit>
                <ListItem
                  button
                  onClick={() => {
                    navigate("/app/indexSalary", { replace: true });
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="การจ่ายเงินเดือน" />
                </ListItem>
              </Collapse>
            </>
          ) : (
            <>
              <ListItem button onClick={handleClickaccount}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary="การเงิน" />
                {openaccount ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openaccount} timeout="auto" unmountOnExit>
                <ListItem
                  button
                  onClick={() => {
                    navigate("/app/indexRED", { replace: true });
                  }}
                >
                  <ListItemIcon>
                    <MoneyOffIcon />
                  </ListItemIcon>
                  <ListItemText primary="ค่าใช้จ่าย" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    navigate("/app/quotation", { replace: true });
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="ใบเสนอราคา" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    navigate("/app/taxListView", { replace: true });
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="หนังสือรับรองการหักภาษีณ ที่จ่าย" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    navigate("/app/indexSalary", { replace: true });
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="การจ่ายเงินเดือน" />
                </ListItem>
              </Collapse>
            </>
          )}
        </>
      )}

      {role.setting === "0" ? (
        <></>
      ) : (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="ตั้งค่า" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  navigate("/app/customers ", { replace: true });
                }}
              >
                <ListItemIcon>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary="ข้อมูลลูกค้า" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  navigate("/app/employee", { replace: true });
                }}
              >
                <ListItemIcon>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary="พนักงาน" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}

const NavBar = (Authentication, { onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [role, SetRole] = useState({});

  async function LoadData() {
    let data = await Authentication.Authentication;
    SetRole(data);
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    LoadData();
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={role.img === "0" ? user.avatar : URLPDF + "/pic/" + role.img}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {role.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {role.position}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <NestedList role={role} />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
//export default NavBar;
