import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  CardHeader,
  Card,
  Link,
  Container,
  Button,
  CardContent,
  TextField,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Pages from "../../../components/Page";
import { GetDetailSalaryService } from "../../../services/salary.services";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { tax, incomeType, costType } from "../../../Data/salaryData";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  detail: {
    width: 150,
  },
  income: {
   color: "#228B22",
  },
  cost: {
    color: "#B22222",
   },
  heardtable: {
    backgroundColor: "#3f51b5",
    width: "100%",
  },
}));

const DtailSalary = ({ className, fetchDatas, ...rest }) => {
  const classes = useStyles();
  const [setSal, SetSal] = useState({});
  const [setIn, SetIn] = useState([{}]);
  const [setCost, SetCost] = useState([{}]);
  const navigate = useNavigate();
  const param = useLocation();

  async function loadExpenses(param) {
    const result = await GetDetailSalaryService(param);
    SetSal(result.salary);
    SetIn(result.income);
    SetCost(result.cost);

    console.log(result.salary);
  }

  useEffect(() => {
    if (param.state !== null) {
      loadExpenses(param.state.id);
    }
  }, []);

  return (
    <Pages className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/indexSalary">
              ข้อมูลการจ่ายเงินเดือน
            </Link>
            <Link color="textPrimary">รายละเอียดข้อมูลการจ่ายเงินเดือน</Link>
          </Breadcrumbs>
        </Box>

        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <CardContent>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h2" component="h2">
                        {setSal.empCode}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h4" component="h2">
                        {"วันที่จ่ายเงินเดือน : " + setSal.date}
                      </Typography>
                    </Grid>
                    <CardHeader />
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  รายการ
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography style={{ width: "500px" }}>
                                  รายละเอียด
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  ประจำเดือนที่ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {setSal.month + "/" + setSal.year}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  เงินเดือน :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setSal.salary + " " + "บาท"}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  รายรับ/รายจ่าย  :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  <Grid container spacing={1}>
                                    
                                      <Grid item xs={1}>
                                        <Typography className={classes.income}  variant="h5" component="h2">
                                          {"รายได้ :"}
                                        </Typography>
                                      </Grid>
                                    
                                   
                                      <Grid className={classes.income}  item xs={4}>
                                        {setIn.map((item) => (
                                          <TableRow  key={item.incomeCode}>
                                            {incomeType
                                              .filter(
                                                (a) =>
                                                  a.incomeCode ==
                                                  item.incomeType
                                              )
                                              .map((value) => (
                                                <TableCell className={classes.income} >
                                                  {value.incomeName}
                                                </TableCell>
                                              ))}
                                            <TableCell className={classes.income} >
                                              {item.amount + " " + "บาท"}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                        <Typography>
                                          {"=" +
                                            " " +
                                            setSal.totalIncome +
                                            " " +
                                            "บาท"}
                                        </Typography>
                                      </Grid>
                                    

                                    <Grid item xs={1}>
                                      <Typography className={classes.cost} variant="h5" component="h2">
                                        {"รายจ่าย :"}
                                      </Typography>
                                    </Grid>
                                    <Grid className={classes.cost} item xs={6}>
                                      {setCost.map((item) => (
                                        <TableRow key={item.costCode}>
                                          {costType
                                            .filter(
                                              (a) => a.costCode == item.costType
                                            )
                                            .map((value) => (
                                              <TableCell className={classes.cost}>
                                                {value.costName}
                                              </TableCell>
                                            ))}
                                          <TableCell className={classes.cost}>
                                            {item.amount + " " + "บาท"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      <Typography>
                                        {"=" +
                                          " " +
                                          setSal.totalCost +
                                          " " +
                                          "บาท"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  ยอดรวมสุทธิ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setSal.netSalary + " " + "บาท"}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  ผู้บันทึกข้อมูล :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setSal.createBy}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  วันที่บันทึกข้อมูล :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setSal.createDate}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  หมายเหตุ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setSal.note}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </CardContent>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate("/app/indexSalary", {
                          replace: true,
                        });
                      }}
                      size="large"
                    >
                      ย้อนกลับ
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Pages>
  );
};

export default DtailSalary;
