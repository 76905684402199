import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import Pagination from "@material-ui/lab/Pagination";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import {
  CardHeader,
  Box,
  Card,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableRow,
  makeStyles,
  Typography,
  Button,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconDel from "../../icons/Del";
import ED from "../../icons/Edite";
import DT from "../../icons/Detail";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { DeleteTaskService } from "../../services/serviceTask";
import { pageSize } from "../../Data/pageSize.json";
import { Addfunctions } from "../../Helper/addfunc";
const useStyles = makeStyles(() => ({
  buttonsuccess: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
}));

const TaskCard = ({ tasks, changPageSize, currentPage, changCurrentPage, paging, fetchData, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handlePageChange = (e, newPage) => {
    changCurrentPage(newPage);
  };

  const Del = async (taskCode) => {
    let result = await DeleteTaskService(taskCode);
    if (result.statusCode === 1) {
      fetchData();
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell>วันที่แจ้งปัญหา</TableCell>
                <TableCell>ประเภทงาน</TableCell>
                <TableCell>เรื่องแจ้ง</TableCell>
                <TableCell>ผู้แจ้ง</TableCell>
                <TableCell>ความสำคัญ</TableCell>
                <TableCell>สถานะ</TableCell>
                <TableCell>จัดการข้อมูล</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((item, index) => (
                <TableRow hover key={item.taskCode}>
                  <TableCell>{(paging.currentPage - 1) * paging.pageSize + (index + 1)}</TableCell>
                  <TableCell>{item.taskcreatedDate}</TableCell>
                  <TableCell>{item.tasktypeName}</TableCell>
                  <TableCell>{item.tasklist}</TableCell>
                  <TableCell>{item.taskempCode}</TableCell>
                  <TableCell>
                    <Addfunctions.Importance vara={item.importanceName} />
                  </TableCell>
                  <TableCell>
                    <Addfunctions.Status para={item.statusName} />
                  </TableCell>
                  <TableCell>
                    {item.statusCode == 4 ? (
                      <ButtonGroup variant="contained" aria-label="contained primary button group" justify="flex-end">
                        <Tooltip title="รายละเอียด" arrow>
                          <Button
                            className={classes.buttoninfo}
                            size="medium"
                            onClick={(e) => {
                              navigate("/app/Detailtask", { state: { id: item.taskCode } }, { replace: true });
                            }}
                          >
                            <DT />
                          </Button>
                        </Tooltip>
                        <Tooltip title="ลบ" arrow>
                          <Button
                            color="secondary"
                            size="medium"
                            onClick={(e) => {
                              swal({
                                className: "bg-modal-red",
                                icon: "warning",
                                dangerMode: true,
                                title: "รายการ : " + item.tasklist,
                                text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                buttons: {
                                  cancel: "ยกเลิก",
                                  confirm: {
                                    text: "ใช่",
                                    value: item.taskCode,
                                  },
                                },
                              }).then((value) => {
                                if (value !== null) {
                                  Del(value);
                                }
                              });
                            }}
                          >
                            <IconDel />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    ) : item.statusCode == 1 ? (
                      <ButtonGroup variant="contained" aria-label="contained primary button group" justify="flex-end">
                        <Tooltip title="ตรวจงาน" arrow>
                          <Button
                            className={classes.buttonsuccess}
                            size="medium"
                            color="primary"
                            onClick={(e) => {
                              navigate("/app/Detailtask", { state: { id: item.taskCode } }, { replace: true });
                            }}
                          >
                            <AssignmentTurnedInIcon />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup variant="contained" aria-label="contained primary button group" justify="flex-end">
                        <Tooltip title="แก้ไข" arrow>
                          <Button
                            className={classes.buttonwiring}
                            size="medium"
                            onClick={(e) => {
                              navigate(
                                "/app/InsertView?id=" + item.taskCode,
                                { state: { id: item.taskCode } },
                                { replace: true }
                              );
                            }}
                          >
                            <ED />
                          </Button>
                        </Tooltip>
                        <Tooltip title="รายละเอียด" arrow>
                          <Button
                            className={classes.buttoninfo}
                            size="medium"
                            onClick={(e) => {
                              navigate("/app/Detailtask", { state: { id: item.taskCode } }, { replace: true });
                            }}
                          >
                            <DT />
                          </Button>
                        </Tooltip>
                        <Tooltip title="ลบ" arrow>
                          <Button
                            color="secondary"
                            size="medium"
                            onClick={(e) => {
                              swal({
                                className: "bg-modal-red",
                                icon: "warning",
                                dangerMode: true,
                                title: "รายการ : " + item.tasklist,
                                text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                buttons: {
                                  cancel: "ยกเลิก",
                                  confirm: {
                                    text: "ใช่",
                                    value: item.taskCode,
                                  },
                                },
                              }).then((value) => {
                                if (value !== null) {
                                  Del(value);
                                }
                              });
                            }}
                          >
                            <IconDel />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <CardHeader />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {paging.rowCount == 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error"> "ไม่พบข้อมูล" </Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>&nbsp; ข้อมูล : &nbsp;{paging.rowCount}&nbsp; รายการ</Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                          changPageSize(e.target.value);
                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}> ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination
                      onChange={handlePageChange}
                      color="primary"
                      page={currentPage}
                      count={paging.pageCount}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

TaskCard.propTypes = {
  className: PropTypes.string,
  tasks: PropTypes.array.isRequired,
};

export default TaskCard;
