import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import Showdata from "./showdata";
import Toolbar from "./Toolbar";
import { GetExpensesService } from "../../services/services.Expenses";
import { GetDataCatExpensesService } from "../../services/services.Expenses";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const ExListView = () => {
  const classes = useStyles();
  const [expensess, SetExpenses] = useState([]);
  const [setpagin, SetPagin] = useState({});
  const [search, Setsearch] = useState("");
  const [since, Setsince] = useState("0");
  const [to, Setto] = useState("0");
  const [expensesType, SetTypeflutter] = useState(0);
  const [categoryCode, SetCatflutter] = useState(0);
  const [pageSize, SetpageSize] = useState(10);
  const [currentPage, SetcurrentPage] = useState(1);
  const [setCat, SetCat] = useState([]);

 
  useEffect(() => {  
    fetchData(search, expensesType, categoryCode, pageSize, currentPage, since, to);
    fetchCatExpenses();
  }, []);

  async function fetchData(search, expensesType, categoryCode, pageSize, currentPage, since, to) {
    const result = await GetExpensesService(search, expensesType, categoryCode, pageSize, currentPage, since, to);
    SetExpenses(result.data)
    SetPagin(result.paging)
  }
  async function fetchCatExpenses(expensesTypeCode) {
    const result = await GetDataCatExpensesService(expensesTypeCode);
    if (expensesTypeCode != null) {
      SetCat(result.category)
    }
  }

  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, expensesType, categoryCode, pageSize, 1, since, to);
  }


  function flutterSince(value) {
    Setsince(value);
    SetcurrentPage(1);
    fetchData(search, expensesType, categoryCode, pageSize, 1, value, to);
  }

  function flutterTo(value) {
    Setto(value);
    SetcurrentPage(1);
    fetchData(search, expensesType, categoryCode, pageSize, 1, since, value);
  }

  function flutterData(value) {
    SetTypeflutter(value);
    fetchCatExpenses(value);
    SetcurrentPage(1);
    SetCatflutter(0);
    fetchData(search, value, 0, pageSize, 1, since, to);
  }

  function flutterCatData(value) {
    SetCatflutter(value);
    SetcurrentPage(1);
    fetchData(search, expensesType, value, pageSize, 1, since, to);
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, expensesType, categoryCode, value, 1, since, to);
  }

  function changCurrentPage(value) {
    SetcurrentPage(value);
    fetchData(search, expensesType, categoryCode, pageSize, value, since, to);
  }

  function Deleted() {
    SetcurrentPage(1);
    fetchData(search, expensesType, categoryCode, pageSize, 1, since, to);
  }

  function clear() {
    Setsearch("");
    Setsince("0");
    Setto("0");
    SetCatflutter(0);
    SetTypeflutter(0);
    SetcurrentPage(1);
    fetchCatExpenses(0)
    fetchData("", 0, 0, pageSize, 1, "0", "0");
  }

 
  return (
    <Page className={classes.root} title="expensess">
      <Container maxWidth={false}>
        <Toolbar searchData={searchData} flutterSince={flutterSince} since={since} flutterTo={flutterTo} to={to}
        clear={clear} setCat={setCat} expensesType={expensesType} categoryCode={categoryCode}  flutterData={flutterData} flutterCatData={flutterCatData} />
        <Box mt={3}>
          <Showdata expensess={expensess} setpagin={setpagin} fetchData={Deleted} changPageSize={changPageSize} changCurrentPage={changCurrentPage} currentPage={currentPage} />
        </Box>
      </Container>
    </Page>
  );
};

export default ExListView;
