import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableRow,
  Grid,
  TextField,
  Typography,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import IconDel from "../../icons/Del";
import Detail from "../../icons/Detail";
import IconEdit from "../../icons/Edit";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { DeleteCustomerService } from "../../services/customer.services";
import swal from "sweetalert";
import { pageSize } from "../../Data/pageSize.json";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(1),
  },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
}));

const Results = ({
  className,
  setCustomers,
  fetchDatas,
  changPageSize,
  changeCurrentPage,
  currentPage,
  fetchDelete,
  setpagin,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    changeCurrentPage(newPage);
  };

  const Del = async (customerCode) => {
    let result = await DeleteCustomerService(customerCode);
    console.log(customerCode);
    if (result.statusCode == 1) {
      fetchDelete();
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };
  const navigate = useNavigate();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead className="thead-font">
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell>ชื่อบริษัท</TableCell>
                <TableCell>ที่อยู่</TableCell>
                <TableCell>เลขที่ภาษีอากร</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {setCustomers.map((item,index) => (
                <TableRow>
                  <TableCell>      {(setpagin.currentPage - 1) * setpagin.pageSize +
                      (index + 1)}</TableCell>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {item.companyName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {`
                    ${item.address}
                    ${item.provinceCode}`}
                  </TableCell>
                  <TableCell>{item.taxnum}</TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <ButtonGroup variant="contained">
                        <Button
                          className="Warning"
                          size="medium"
                          onClick={() => {
                            navigate(
                              "/app/indexcustomer?id=" + item.customerCode,
                              { state: { id: item.customerCode } },
                              { replace: true }
                            );
                          }}
                        >
                          <IconEdit />
                        </Button>
                        <Button
                          className={classes.buttoninfo}
                          size="medium"
                          onClick={(e) => {
                            navigate(
                              "/app/detailCustomer?id" + item.customerCode,
                              { state: { id: item.customerCode } },
                              { replace: true }
                            );
                          }}
                        >
                          <Detail />
                        </Button>
                        <Button
                          className="Error"
                          size="medium"
                          onClick={(e) => {
                            swal({
                              icon: "warning",
                              dangerMode: true,
                              title: "รายการ : " + item.companyName,
                              text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                              buttons: {
                                cancel: "ยกเลิก",
                                confirm: {
                                  text: "ใช่",
                                  value: item.customerCode,
                                },
                              },
                            }).then((value) => {
                              if (value !== null) {
                                Del(value);
                              }
                            });
                          }}
                        >
                          <IconDel />
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <CardHeader />

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {setpagin.rowCount == 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error">"ไม่พบข้อมูล" </Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>
                      &nbsp; ข้อมูล : &nbsp;{setpagin.rowCount}&nbsp; รายการ
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                          changPageSize(e.target.value);
                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}> ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination
                      count={setpagin.pageCount}
                      page={currentPage}
                      onChange={handleChangePage}
                      color="primary"
                    />
                    {console.log("currentPage", currentPage)}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default Results;
