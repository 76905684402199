import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import TaxCard from "./TaxCard";
import Toolbar from "./Toolbar";
import data from "./data";
import { GetWttaxService } from "../../services/wttax.services";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TaxListView = () => {
  const classes = useStyles();
  const [wttax, Settax] = useState([]);
  const [pageSize, SetpageSize] = useState(10);
  const [currentPage, SetcurrentPage] = useState(1);
  const [paging, SetPaging] = useState({});
  const [search, Setsearch] = useState("");
  const [selects, SetSelects] = useState("-1");
  const [pricetypeCoode, SetPricetype] = useState("-1");
  useEffect(() => {
    fetchdata(search, pageSize, currentPage, selects, pricetypeCoode);
  }, []);

  async function fetchdata(search, pageSize, currentPage, selects, pricetypeCoode) {
    const result = await GetWttaxService(search, pageSize, currentPage, selects, pricetypeCoode);
    Settax(result.data);
    SetPaging(result.paging);
  }
  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchdata(value, pageSize, 1, selects, pricetypeCoode);
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchdata(search, value, 1, selects, pricetypeCoode);
  }
  function changCurrentPage(value) {
    SetcurrentPage(value);
    fetchdata(search, pageSize, value, selects, pricetypeCoode);
  }

  function changSelects(value) {
    SetSelects(value);
    SetcurrentPage(1);
    fetchdata(search, pageSize, 1, value, pricetypeCoode);
  }
  function Selectpricetype(value) {
    SetPricetype(value);
    SetcurrentPage(1);
    fetchdata(search, pageSize, 1, selects, value);
  }

  function clear() {
    Setsearch("");
    SetcurrentPage(1);
    Selectpricetype("-1");
    changSelects("-1");
    fetchdata("", pageSize, 1, "-1", "-1");
  }

  return (
    <Page className={classes.root} title="tax">
      <Container maxWidth={false}>
        <Toolbar
          searchData={searchData}
          fetchdata={fetchdata}
          search={search}
          changSelects={changSelects}
          selects={selects}
          clear={clear}
          Selectpricetype={Selectpricetype}
          pricetypeCoode={pricetypeCoode}
        />
        <Box mt={3}>
          <TaxCard
            wttax={wttax}
            fetchdata={fetchdata}
            changPageSize={changPageSize}
            changCurrentPage={changCurrentPage}
            paging={paging}
            currentPage={currentPage}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TaxListView;
