import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";
import EmpCard from "./employeeCard";
import Toolbar from "./Toolbar";
import { GetEmployeeService } from "../../../services/services.Employee";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const EmployeeListView = () => {
  const classes = useStyles();
  const [employees, SetEmployees] = useState([]);
  const [setpagin, SetPagin] = useState({});
  const [search, Setsearch] = useState("");
  const [currentPage, SetcurrentPage] = useState(1);
  const [pageSize, SetpageSize] = useState(10);
  const [bankCode, setbankCode] = useState(0);
  const [departmentCode, setdepartmentCode] = useState(0);
  const [positionCode, setpositionCode] = useState(0);
  const [date, Setdate] = useState("0");

  useEffect(() => {
    fetchData(search, pageSize, currentPage, bankCode, departmentCode, positionCode, date);
  }, []);

  async function fetchData(search, pageSize, currentPage, bankCode, departmentCode, positionCode, date) {
    const result = await GetEmployeeService(search, pageSize, currentPage, bankCode, departmentCode, positionCode, date);
    SetEmployees(result.data);
    SetPagin(result.paging);
  }

  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, pageSize, 1, bankCode, departmentCode, positionCode, date);
  }

  function changCurrentPage(value) {
    console.log(value);
    SetcurrentPage(value);
    fetchData(search, pageSize, value, bankCode, departmentCode, positionCode, date);
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, value, 1, bankCode, departmentCode, positionCode, date);
  }

  function flutterBankData(value) {
    setbankCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, value, departmentCode, positionCode, date);
  }

  function flutterDepartmentData(value) {
    setdepartmentCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, bankCode, value, positionCode, date);
  }

  function flutterpositionmentData(value) {
    setpositionCode(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, bankCode, departmentCode, value, date);
  }

  function fetchDeleted() {
    fetchData(search, pageSize, 1, bankCode, departmentCode, positionCode, date);
  }

  function flutterDate(value) {
    Setdate(value);
    SetcurrentPage(1);
    fetchData(search, pageSize, 1, bankCode, departmentCode, positionCode, value);
  }

  function clear() {
    Setsearch("");
    setbankCode(0);
    setdepartmentCode(0);
    setpositionCode(0);
    SetcurrentPage(1);
    Setdate("0");

    fetchData("", pageSize, 1, "0", 0, 0, 0);
  }
  return (
    <Page className={classes.root} title="employees">
      <Container maxWidth={false}>
        <Toolbar searchData={searchData} fetchData={fetchData} flutterBankData={flutterBankData} flutterDepartmentData={flutterDepartmentData} flutterpositionmentData={flutterpositionmentData} clear={clear} date={date} flutterDate={flutterDate} />

        <Box mt={3}>
          <EmpCard employees={employees} fetchData={fetchData} changPageSize={changPageSize} changCurrentPage={changCurrentPage} setpagin={setpagin} fetchDeleted={fetchDeleted} currentPage={currentPage} />
        </Box>
      </Container>
    </Page>
  );
};

export default EmployeeListView;
