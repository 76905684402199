import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, CardHeader, Card, Link, Container, Button, CardContent, TextField, Typography, makeStyles, Grid } from "@material-ui/core";
import Pages from "../../../components/Page";
import { GetDetailExpensesService } from "../../../services/services.Expenses";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { URLPDF } from "../../../Helper/baseURL";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  detail: {
    width: 150,
  },
  heardtable: {
    backgroundColor: "#3f51b5",
    width: "100%",
  },
}));

const DetailRED = ({ className, fetchDatas, ...rest }) => {
  const classes = useStyles();
  const [setEx, SetEx] = useState({});
  const navigate = useNavigate();
  const param = useLocation();

  async function loadExpenses(param) {
    const resultExpenses = await GetDetailExpensesService(param);
    SetEx(resultExpenses.data);
    console.log(resultExpenses.data);
  }
  useEffect(() => {
    if (param.state !== null) {
      loadExpenses(param.state.id);
    }
  }, []);

  var fileName = URLPDF + setEx.exDocument;
  var fileExtension = fileName.split(".").pop(); //"pdf"

  return (
    <Pages className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/indexRED">
              รายการค่าใช้จ่าย
            </Link>
            <Link color="textPrimary">รายละเอียดค่าใช้จ่าย</Link>
          </Breadcrumbs>
        </Box>

        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <CardContent>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h2" component="h2">
                        {setEx.exList}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h4" component="h2">
                        {"วันที่ทำรายการ : " + setEx.exTransactionDate}
                      </Typography>
                    </Grid>
                    <CardHeader />
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  รายการ
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>รายละเอียด</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  ประเภทค่าใช้จ่าย :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.expensesTypeName}</Typography>
                              </TableCell>
                            </TableRow>
                            {setEx.expensesTypeCode == 2 ? (
                              <TableRow>
                                <TableCell>
                                  <Typography className={classes.detail} variant="h5" component="h2">
                                    โครงการ :
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{setEx.projectsName}</Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  หมวดหมู่ค่าใช้จ่าย :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.categoryName}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  รายละเอียด :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  dangerouslySetInnerHTML={{
                                    __html: setEx.exDetail,
                                  }}
                                  style={{
                                    width: "500px",
                                    wordWrap: "break-word",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  จำนวนเงิน :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exValue + " " + "บาท"}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  ภาษีมูลค่าเพิ่ม :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exVat + "%"}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  ภาษีมูลค่าเพิ่มรวม :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exVatTotal + " " + "บาท"}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  ยอดรวมสุทธิ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exTotal + " " + "บาท"}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  เอกสารประกอบ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {/* <Button
                                  className={classes.buttonwiring}
                                  size="medium"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/app/PDFView", { state: { id: setEx.expesesCode } }, { replace: true });
                                  }}>
                                  PDF
                                </Button> */}

                                <Button className={classes.buttonwiring} target="_blank" href={URLPDF + setEx.exDocument}>
                                  {fileExtension}
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  ผู้เบิกเงิน :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exDepartmentCode}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  วันที่บันทึกข้อมูล :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exRecordDate}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  ผู้บันทึกข้อมูล :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exRecordUser}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.detail} variant="h5" component="h2">
                                  หมายเหตุ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEx.exNote}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </CardContent>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate("/app/indexRED", {
                          replace: true,
                        });
                      }}
                      size="large"
                    >
                      ย้อนกลับ
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Pages>
  );
};

export default DetailRED;
