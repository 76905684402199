import React, { useState, useEffect } from "react";
import IconSave from "../../../icons/Save";
import IconCancel from "../../../icons/Cancel";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Box, Card, CardContent, CardHeader, Button, Typography, Grid, TextField, makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Page from "../../../components/Page";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { SaveExpensesService } from "../../../services/services.Expenses";
import { UpdateExpensesService } from "../../../services/services.Expenses";
import { GetDataExpensesService } from "../../../services/services.Expenses";
import { GetDataCatExpensesService } from "../../../services/services.Expenses";
import { LoadDataExpensesService } from "../../../services/services.Expenses";
import { category, typeExpenses, exVat } from "../../../Data/expensesData";
import swal from "sweetalert";
import NumberFormat from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip';
import dateFormat from 'dateformat';
import CKEditor from "ckeditor4-react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  input: {
    display: "none",
  },
}));

const RED = Yup.object().shape({
  exDepartmentCode: Yup.string().required("กรุณาเลือกข้อมูล"),
  exList: Yup.string()
    .max(40, "กรุณากรอกข้อมูลน้อยกว่า 40 ตัวอักษร")
    .required("กรุณากรอกรายการ"),
  exCategoryCode: Yup.string().when("expensesType", (value, schema) => {
    if (value == undefined) {
      return schema.required("กรุณาเลือกประเภทค่าใช่จ่ายก่อน");
    } else {
      return schema.required("กรุณาเลือกหมวดหมู่ค่าใช้จ่าย");
    }
  }),

  exTransactionDate: Yup.string().required("กรุณาเลือกวันที่"),
  exVat: Yup.string().required("กรุณาเลือกค่าภาษี"),
  exValue: Yup.number("กรณากรอกข้อมูลเป็นตัวเลข")
    .max(999999999, "กรุณากรอกจำนวนเงินน้อยกว่าหรือเท่ากับ 9 หลัก")
    .required("กรุณากรองจำนวนเงิน")
    .typeError("กรุณากรอกข้อมูลเป็นตัวเลข"),
  expensesType: Yup.string().required("กรุณาเลือกประเภทค่าใช่จ่าย"),
  exProjectsCode: Yup.string().when("expensesType", (value, schema) => {
    if (value === "1") {
      return schema.notRequired();
    } else {
      return schema.required("กรุณาเลือกโครงการ");
    }
  }),
  exDocument: Yup.string().required("กรุณาแนบไฟล์เอกสารอ้างอิงการเบิกเงิน"),
  exDetail: Yup.string().required("กรุณากรองรายละเอียด"),
  exNote: Yup.string().max(150, "กรุณากรอกข้อมูลน้อยกว่า 150 ตัวอักษร"),
});

const Formred = ({ className, fetchData, ...rest }) => {
  const classes = useStyles();
  const [setEmp, SetEmp] = useState([]);
  const [setPro, SetPro] = useState([]);
  const [setType, SetType] = useState([]);
  const [setCat, SetCat] = useState([]);
  const [setEx, SetEx] = useState({});
  const navigate = useNavigate();
  const param = useLocation();
  const [loading, SetLoading] = useState(true);
  const [value, setValue] = useState("0");

  async function loadExpenses(param) {
    const resultExpenses = await LoadDataExpensesService(param);
    SetEx(resultExpenses.data);
    fetchCatExpenses(resultExpenses.data.expensesTypeCode);
    SetLoading(false);
    console.log("resultExpenses", resultExpenses.data);
  }

  async function fetchExpenses() {
    const result = await GetDataExpensesService();
    SetEmp(result.emp);
    SetPro(result.project);
    SetType(result.typeEx);
    SetLoading(false);
  }

  async function fetchCatExpenses(expensesTypeCode) {
    const result = await GetDataCatExpensesService(expensesTypeCode);
    if (expensesTypeCode != null) {
      SetCat(result.category);
    }
    SetLoading(false);
  }

  useEffect(() => {
    if (param.state !== null) {
      loadExpenses(param.state.id);
    }
    fetchCatExpenses();
    fetchExpenses();
  }, []);

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateExpensesService(param.state.id, values);
      if (resultUpdate.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/indexRED", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    } else {
      let result = await SaveExpensesService(values);
      if (result.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/indexRED", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/indexRED">
              รายการค่าใช้จ่าย
            </Link>
            <Link color="textPrimary">บันทึกรายการค่าใช้จ่าย</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardHeader />
            <Box display="flex" justifyContent="center">
              <CardHeader />
              <Typography color="textPrimary" variant="h3">
                บันทึกรายการค่าใช้จ่าย
              </Typography>
            </Box>
            <CardContent>
              <Formik
                onSubmit={(values) => {
                  SaveValue({
                    exList: values.exList,
                    exDetail: values.exDetail,
                    exValue: parseFloat(values.exValue),
                    exVat: parseFloat(values.exVat),
                    exVatTotal: parseFloat(0),
                    exTotal: parseFloat(0),
                    exNote: values.exNote ? values.exNote : "",
                    exDocument: values.exDocument,
                    exCategoryCode: parseInt(values.exCategoryCode),
                    exDepartmentCode: values.exDepartmentCode,
                    exProjectsCode: parseInt(values.exProjectsCode),
                    expensesType: values.expensesType,
                    exTransactionDate: values.exTransactionDate,
                  });
                }}
                enableReinitialize
                initialValues={{
                  exList: setEx.exList,
                  exDetail: setEx.exDetail,
                  exValue: setEx.exValue,
                  exVat: setEx.exVat != null ? parseInt(setEx.exVat) : 7,
                  exDocument: setEx.exDocument,
                  exCategoryCode:
                    setEx.categoryCode != null
                      ? parseInt(setEx.categoryCode)
                      : "",
                  exDepartmentCode:
                    setEx.exDepartmentCode != null
                      ? setEx.exDepartmentCode
                      : "",
                  exProjectsCode:
                    setEx.projectsCode != null
                      ? parseInt(setEx.projectsCode)
                      : "",
                  exTransactionDate:
                    setEx.exTransactionDate != null
                      ? dateFormat(setEx.exTransactionDate, "yyyy-mm-dd")
                      : "",
                  expensesType:
                    setEx.expensesTypeCode != null
                      ? parseInt(setEx.expensesTypeCode)
                      : "",
                  exNote: setEx.exNote != null ? setEx.exNote : "",
                }}
                validationSchema={RED}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form>
                    {setValue(values.exVat == 0 ? "0" : "1")}
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          value={values.exDepartmentCode}
                          size="small"
                          label="-- รหัสพนักงาน,ชื่อ-นามสกุล (ผู้เบิกเงิน) --"
                          name="exDepartmentCode"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          select
                          error={Boolean(
                            touched.exDepartmentCode && errors.exDepartmentCode
                          )}
                          helperText={
                            touched.exDepartmentCode && errors.exDepartmentCode
                          }
                          onBlur={handleBlur}
                        >
                          {setEmp.map((values) => (
                            <MenuItem
                              key={values.empCode}
                              value={values.empCode}
                            >
                              {values.empCode +
                                " " +
                                values.titleName +
                                " " +
                                values.firstName +
                                " " +
                                values.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid
                        item
                        md={
                          values.expensesType === 2
                            ? 4
                            : values.expensesType !== ""
                            ? 6
                            : 12
                        }
                        xs={12}
                      >
                        <TextField
                          value={values.expensesType}
                          size="small"
                          label="-- ประเภทค่าใช่จ่าย --"
                          name="expensesType"
                          onChange={(e) => {
                            console.log(e.target.value);
                            fetchCatExpenses(e.target.value);
                            setFieldValue("expensesType", e.target.value);
                            if (e.target.value === 2) {
                              setFieldValue("exProjectsCode", "");
                              setFieldValue("exCategoryCode", "");
                            } else {
                              setFieldValue("exProjectsCode", "0");
                              setFieldValue("exCategoryCode", "");
                            }
                          }}
                          variant="outlined"
                          fullWidth
                          select
                          error={Boolean(
                            touched.expensesType && errors.expensesType
                          )}
                          helperText={
                            touched.expensesType && errors.expensesType
                          }
                          onBlur={handleBlur}
                        >
                          {setType.map((values) => (
                            <MenuItem
                              key={values.expensesTypeCode}
                              value={values.expensesTypeCode}
                            >
                              {values.expensesTypeName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {values.expensesType === 2 ? (
                        <Grid
                          item
                          md={values.expensesType === 2 ? 4 : ""}
                          xs={12}
                        >
                          <TextField
                            InputLabelProps={{
                              shrink:
                                values.exProjectsCode == "" ? false : true,
                            }}
                            InputProps={{
                              readOnly:
                                values.expensesType === 1 ? true : false,
                            }}
                            value={values.exProjectsCode}
                            size="small"
                            label="-- โครงการ --"
                            name="exProjectsCode"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            select
                            error={
                              values.expensesType == 2
                                ? Boolean(
                                    touched.exProjectsCode &&
                                      errors.exProjectsCode
                                  )
                                : ""
                            }
                            helperText={
                              values.expensesType == 2
                                ? touched.exProjectsCode &&
                                  errors.exProjectsCode
                                : ""
                            }
                            onBlur={handleBlur}
                          >
                            {setPro.map((values) => (
                              <MenuItem
                                key={values.projectsCode}
                                value={values.projectsCode}
                              >
                                {values.projectsName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {values.expensesType !== "" ? (
                        <Grid
                          item
                          md={values.expensesType === 2 ? 4 : 6}
                          xs={12}
                        >
                          <TextField
                            value={values.exCategoryCode}
                            size="small"
                            label="-- หมวดหมู่ --"
                            name="exCategoryCode"
                            onChange={(e) => {
                              setFieldValue("exCategoryCode", e.target.value);
                            }}
                            variant="outlined"
                            fullWidth
                            error={Boolean(
                              touched.exCategoryCode && errors.exCategoryCode
                            )}
                            helperText={
                              touched.exCategoryCode && errors.exCategoryCode
                            }
                            onBlur={handleBlur}
                            select
                          >
                            {setCat.map((values) => (
                              <MenuItem
                                key={values.categoryCode}
                                value={values.categoryCode}
                              >
                                {values.categoryName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        ""
                      )}

                      <Grid item md={12} xs={12}>
                        <TextField
                          value={values.exList}
                          size="small"
                          fullWidth
                          label="รายการ"
                          name="exList"
                          autoComplete="off"
                          error={Boolean(touched.exList && errors.exList)}
                          helperText={touched.exList && errors.exList}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: values.exList != null ? true : false,
                          }}
                        />
                      </Grid>
                      <Grid item md={value == 1 ? 2 : 3} xs={12}>
                        <TextField
                          value={values.exTransactionDate}
                          id="date"
                          label="วันที่ชำระเงิน "
                          type="date"
                          fullWidth
                          name="exTransactionDate"
                          size="small"
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            touched.exTransactionDate &&
                              errors.exTransactionDate
                          )}
                          helperText={
                            touched.exTransactionDate &&
                            errors.exTransactionDate
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={value == 1 ? 2 : 3} xs={12}>
                        <TextField
                          value={values.exValue}
                          size="small"
                          fullWidth
                          label="จำนวนเงิน"
                          name="exValue"
                          autoComplete="off"
                          onChange={handleChange}
                          error={Boolean(touched.exValue && errors.exValue)}
                          helperText={touched.exValue && errors.exValue}
                          onBlur={handleBlur}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: values.exValue != null ? true : false,
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <FormControl>
                          <RadioGroup
                            row
                            size="small"
                            name="gender1"
                            value={value}
                            onChange={(e) => {
                              setValue(e.target.value);
                              if (e.target.value == "1") {
                                setFieldValue("exVat", 7);
                              }
                              if (e.target.value == "0") {
                                setFieldValue("exVat", 0);
                              }
                            }}
                          >
                            <FormControlLabel
                              size="small"
                              value="0"
                              control={<Radio />}
                              label="ไม่มีภาษี"
                            />
                            <FormControlLabel
                              size="small"
                              value="1"
                              control={<Radio />}
                              label="มีภาษี"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {value == 1 || values.exVat != "" ? (
                        <Grid item md={2} xs={12}>
                          <TextField
                            value={values.exVat}
                            size="small"
                            label="-- ภาษีมูลค่าเพิ่ม --"
                            name="exVat"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.exVat && errors.exVat)}
                            helperText={touched.exVat && errors.exVat}
                            onBlur={handleBlur}
                          >
                            {exVat.map((values) => (
                              <MenuItem
                                key={values.exVatCode}
                                value={values.exVatCode}
                              >
                                {values.exVatName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        ""
                      )}

                      <Grid item md={1} xs={12}>
                        <Tooltip title="แนบไฟล์ PDF" placement="top-start">
                          <Button
                            variant="contained"
                            component="label"
                            className="primary"
                          >
                            {<AttachmentIcon />}
                            <input
                              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                              className={classes.input}
                              type="file"
                              name="exDocument"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue("exDocument", e.target.files[0]);
                              }}
                              hidden
                            />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item md={3} xs={12}>
                        {setEx.exDocument === undefined ? (
                          <TextField
                            InputLabelProps={{
                              shrink:
                                values.exDocument != undefined ? true : false,
                            }}
                            InputProps={{ readOnly: true }}
                            label="ไฟล์แนบ"
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={
                              values.exDocument === undefined
                                ? " "
                                : values.exDocument.name
                            }
                            error={Boolean(
                              touched.exDocument && errors.exDocument
                            )}
                            helperText={touched.exDocument && errors.exDocument}
                          />
                        ) : (
                          <TextField
                            InputLabelProps={{
                              shrink:
                                values.exDocument != undefined ? true : false,
                            }}
                            InputProps={{ readOnly: true }}
                            label="ไฟล์แนบ"
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={
                              setEx.exDocument === undefined
                                ? " "
                                : values.exDocument === undefined
                                ? setEx.exDocument
                                : values.exDocument.name
                            }
                            error={Boolean(
                              touched.exDocument && errors.exDocument
                            )}
                            helperText={touched.exDocument && errors.exDocument}
                          />
                        )}
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography
                          className={
                            errors.exDetail && touched.exDetail
                              ? "input-feedback"
                              : ""
                          }
                        >
                          รายละเอียด
                        </Typography>
                        {loading ? (
                          <></>
                        ) : (
                          <div
                            className={
                              errors.exDetail && touched.exDetail
                                ? "border"
                                : ""
                            }
                          >
                            <CKEditor
                              className={
                                errors.exDetail && touched.exDetail
                                  ? "text-input error"
                                  : ""
                              }
                              helperText={touched.exDetail && errors.exDetail}
                              data={values.exDetail}
                              type="text"
                              name="exDetail"
                              onChange={(e) => {
                                setFieldValue("exDetail", e.editor.getData());
                              }}
                              onBeforeLoad={(CKEDITOR) => {
                                CKEDITOR.disableAutoInline = true;
                                CKEDITOR.config.autoParagraph = false;
                              }}
                            />
                          </div>
                        )}
                        <ErrorMessage
                          component="div"
                          name="exDetail"
                          className="input-feedback "
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          value={values.exNote}
                          size="small"
                          fullWidth
                          label="หมายเหตุ"
                          name="exNote"
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: values.exNote != "" ? true : false,
                          }}
                          error={Boolean(touched.exNote && errors.exNote)}
                          helperText={touched.exNote && errors.exNote}
                        />
                      </Grid>
                    </Grid>

                    <Box display="flex" justifyContent="center" p={2}>
                      <Box mr={3}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          startIcon={<IconSave />}
                          size="large"
                        >
                          บันทึก
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<IconCancel />}
                          onClick={() => {
                            navigate("/app/indexRED", { replace: true });
                          }}
                          size="large"
                        >
                          ยกเลิก
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default Formred;
