import React from "react";

const NewLogo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/BLUE_LOGO-removebg-preview.png"
      {...props}
      width="100"

    />
  );
};

export default NewLogo;
