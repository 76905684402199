import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import MainLayout from "../layouts/MainLayout";
import AccountView from "../views/account/AccountView";
import DashboardView from "../views/reports/DashboardView";
import LoginView from "../views/auth/LoginView";
import NotFoundView from "../views/errors/NotFoundView";
import TaskListView from "../views/assignTask/index";
import RegisterView from "../views/auth/RegisterView";
import SettingsView from "../views/settings/SettingsView";
import InsertView from "../views/assignTask/manage/Insertask";
import IndexCustomer from "../views/customer/manager/formcustomer";
import EmployeeListView from "../views/employee/EmployeeListView";
import IndexRED from "../views/expense/index";
import IndexSalary from "../views/salary/index";
import FormSalary from "../views/salary/manager/formSalary";
import DetailSalary from "../views/salary/manager/detailSalary";
import IndexCus from "../views/customer/index";
import Formred from "../views/expense/manage/form";
import DetailRED from "../views/expense/manage/detail";
import Developer from "../views/assignMent/index";
import SolveDev from "../views/assignMent/manage/InserDev";
import LeaveView from "../views/leave/index";
import FormLeave from "../views/leave/ManagerLeave/FormLeave";
import Detailtask from "../views/assignTask/detailTask";
import DetailLeave from "../views/leave/ManagerLeave/DetailLeave";
import LeavesCardAdmin from "../views/leave/Adminleave/indexAdmin";
import DetailLeaveAdmin from "../views/leave/ManagerAdmin/DetailLeaveAdmin";
import FormLeaveAdmin from "../views/leave/ManagerAdmin/FormLeaveAdmin";
import Detaildev from "../views/assignMent/detailDev";
import Pdfview from "../views/expense/manage/pdfVIEW";
import Formemployee from "../views/employee/EmployeeListView/ManageEmp/formemployee";
import DetailEmployee from "../views/employee/EmployeeListView/ManageEmp/detailEmployee";
import QuotationList from "../views/quotation/index";
import InserQuotation from "../views/quotation/manage/insertQuotation";
import CustomerListView from "../views/customer";
import DetailQuotation from "../views/quotation/detailQuotation";
import TaxListView from "../views/withholdingTax/index";
import InsertTax from "../views/withholdingTax/manage/InsertTax";
import DetailCustomer from "../views/customer/manager/detailCustomer";
import PrintQuotation from "../views/quotation/printQuotation";
import Detailtax from "../views/withholdingTax/manage/detailtax";

function routes() {
  // let dd =store.getState().Authentication
  //console.log("token",dd)
  return [
    {
      path: "/",
      // element: <MainLayout />,
      element: "",
      children: [
        {
          path: "/",
          element:
            localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null ? (
              <Navigate to="/app/dashboard" />
            ) : (
              <Navigate to="/login" />
            ),
          role: "1",
        },
      ],
    },
    localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null
      ? {
          path: "app",
          element: <DashboardLayout />,
          children: [
            { path: "/", element: <Navigate to="/app/dashboard" />, role: "1" },
            { path: "detailCustomer", element: <DetailCustomer />, role: localStorage.getItem("setting") },
            { path: "account", element: <AccountView />, role: "0" },
            { path: "detailtask", element: <Detailtask />, role: localStorage.getItem("tasks") === "1" ? "0" : localStorage.getItem("tasks") },
            { path: "detaildev", element: <Detaildev />, role: localStorage.getItem("tasks") },
            { path: "PDFView", element: <Pdfview />, role: localStorage.getItem("finance") },
            { path: "customers", element: <CustomerListView />, role: localStorage.getItem("setting") },
            { path: "formemployee", element: <Formemployee />, role: localStorage.getItem("finance") },
            { path: "indexRED", element: <IndexRED />, role: localStorage.getItem("finance") },
            { path: "indexSalary", element: <IndexSalary />, role: localStorage.getItem("finance") },
            { path: "formSalary", element: <FormSalary />, role: localStorage.getItem("finance") === "1" ? "0" : localStorage.getItem("finance") },
            { path: "detailSalary", element: <DetailSalary />, role: localStorage.getItem("finance") },
            { path: "indexCus", element: <IndexCus />, role: localStorage.getItem("setting") },
            { path: "formred", element: <Formred />, role: localStorage.getItem("finance") },
            { path: "detailRED", element: <DetailRED />, role: localStorage.getItem("finance") },
            { path: "indexcustomer", element: <IndexCustomer />, role: localStorage.getItem("setting") },
            { path: "employee", element: <EmployeeListView />, role: localStorage.getItem("setting") },
            { path: "dashboard", element: <DashboardView />, role: "1" },
            { path: "Task", element: <TaskListView />, role: localStorage.getItem("tasks") === "1" ? "0" : localStorage.getItem("tasks") },
            { path: "settings", element: <SettingsView />, role: localStorage.getItem("setting") },
            { path: "*", element: <Navigate to="/app/404" />, role: "1" },
            { path: "InsertView", element: <InsertView />, role: localStorage.getItem("tasks") === "1" ? "0" : localStorage.getItem("tasks") },
            { path: "Developer", element: <Developer />, role: localStorage.getItem("tasks") },
            { path: "SolveDev", element: <SolveDev />, role: localStorage.getItem("tasks") },
            { path: "Leave", element: <LeaveView />, role: localStorage.getItem("level") },
            { path: "FormLeave", element: <FormLeave />, role: localStorage.getItem("level") },
            { path: "DetailLeave", element: <DetailLeave />, role: localStorage.getItem("level") },
            {
              path: "LeavesCardAdmin",
              element: <LeavesCardAdmin />,
              role: localStorage.getItem("level") === "1" ? "0" : localStorage.getItem("level"),
            },
            { path: "DetailLeaveAdmin", element: <DetailLeaveAdmin />, role: localStorage.getItem("level") },
            {
              path: "FormLeaveAdmin",
              element: <FormLeaveAdmin />,
              role: localStorage.getItem("level") === "1" ? "0" : localStorage.getItem("level"),
            },
            { path: "detailEmployee", element: <DetailEmployee />, role: localStorage.getItem("setting") },
            { path: "quotation", element: <QuotationList />, role: localStorage.getItem("finance") },
            { path: "insertQuotation", element: <InserQuotation />, role: localStorage.getItem("finance") },
            { path: "printQuotation", element: <PrintQuotation />, role: localStorage.getItem("finance") },
            { path: "detailQuotation", element: <DetailQuotation />, role: localStorage.getItem("finance") },
            { path: "taxListView", element: <TaxListView />, role: localStorage.getItem("finance") },
            { path: "detailtax", element: <Detailtax />, role: localStorage.getItem("finance") },
            { path: "insertTax", element: <InsertTax />, role: localStorage.getItem("finance") },
            { path: "404", element: <NotFoundView />, role: "1" },
          ].filter((a) => a.role !== "0" && a.role !== undefined),
        }
      : {
          path: "/",
          // element: <MainLayout />,
          element: "",
          children: [
            { path: "register", element: <RegisterView />, role: "1" },
            { path: "login", element: <LoginView />, role: "1" },
            { path: "404", element: <NotFoundView />, role: "1" },
            { path: "/", element: <Navigate to="/login" />, role: "1" },
            { path: "*", element: <Navigate to="/404" />, role: "1" },
          ].filter((a) => a.role !== "0" && a.role !== undefined),
        },
  ];
}

/*
const routes = [
  store.getState().Authentication.token !== undefined && store.getState().Authentication.token !== null
    ? {
        path: "app",
        element: <DashboardLayout />,
        children: [
          { path: "detailCustomer", element: <DetailCustomer />, role: localStorage.getItem("setting") },
          { path: "account", element: <AccountView />, role: "0" },
          { path: "detailtask", element: <Detailtask />, role: localStorage.getItem("tasks")},
          { path: "detaildev", element: <Detaildev />, role: localStorage.getItem("tasks")},
          { path: "PDFView", element: <Pdfview />, role:  localStorage.getItem("finance")},
          { path: "customers", element: <CustomerListView />, role: localStorage.getItem("setting") },
          { path: "formemployee", element: <Formemployee />, role:  localStorage.getItem("finance")},
          { path: "indexRED", element: <IndexRED />, role:  localStorage.getItem("finance")},
          { path: "indexSalary", element: <IndexSalary />, role: "0" },
          { path: "formSalary", element: <FormSalary />, role: "0" },
          { path: "detailSalary", element: <DetailSalary />, role: "0" },
          { path: "indexCus", element: <IndexCus />, role: localStorage.getItem("setting") },
          { path: "formred", element: <Formred />, role:  localStorage.getItem("finance")},
          { path: "detailRED", element: <DetailRED />, role:  localStorage.getItem("finance")},
          { path: "indexcustomer", element: <IndexCustomer />, role: localStorage.getItem("setting") },
          { path: "employee", element: <EmployeeListView />, role: "0" },
          { path: "dashboard", element: <DashboardView />, role: "1" },
          { path: "Task", element: <TaskListView />, role: localStorage.getItem("tasks")},
          { path: "settings", element: <SettingsView />, role: "0" },
          { path: "*", element: <Navigate to="/404" />, role: "1" },
          { path: "InsertView", element: <InsertView />, role: localStorage.getItem("tasks")},
          { path: "Developer", element: <Developer />, role: store.getState().Authentication.gettasks },
          { path: "SolveDev", element: <SolveDev />, role: store.getState().Authentication.gettasks },
          { path: "Leave", element: <LeaveView />, role: store.getState().Authentication.level },
          { path: "FormLeave", element: <FormLeave />, role: store.getState().Authentication.level },
          { path: "DetailLeave", element: <DetailLeave />, role: store.getState().Authentication.level },
          { path: "LeavesCardAdmin", element: <LeavesCardAdmin />, role: store.getState().Authentication.level },
          { path: "DetailLeaveAdmin", element: <DetailLeaveAdmin />, role: store.getState().Authentication.level },
          { path: "FormLeaveAdmin", element: <FormLeaveAdmin />, role: store.getState().Authentication.level },
          { path: "detailEmployee", element: <DetailEmployee />, role: "0" },
          { path: "quotation", element: <QuotationList />, role: "0" },
          { path: "insertQuotation", element: <InserQuotation />, role: "0" },
          { path: "printQuotation", element: <PrintQuotation />, role: "0" },
          { path: "detailQuotation", element: <DetailQuotation />, role: "0" },
          { path: "taxListView", element: <TaxListView />, role: "0" },
          { path: "detailtax", element: <Detailtax />, role: "0" },
          { path: "insertTax", element: <InsertTax />, role: "0" },
        ].filter((a) => a.role !== "0" && a.role !== undefined),
      }
    : {
        path: "/",
        // element: <MainLayout />,
        element: "",
        children: [
          { path: "register", element: <RegisterView />, role: "1" },
          { path: "login", element: <LoginView />, role: "1" },
          { path: "404", element: <NotFoundView />, role: "1" },
          { path: "/", element: <Navigate to="/login" />, role: "1" },
          { path: "*", element: <Navigate to="/404" />, role: "1" },
        ].filter((a) => a.role !== "0" && a.role !== undefined),
      },
];
*/
export default routes;
