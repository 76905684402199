import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import { Box, Card, CardContent, CardHeader, Divider, Typography, colors, makeStyles, useTheme, Grid } from "@material-ui/core";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import PhoneIcon from "@material-ui/icons/Phone";
import TabletIcon from "@material-ui/icons/Tablet";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
}));

const TrafficByDevice = ({ className, valueTask, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const data = {
    datasets: [
      {
        data: valueTask.amountTask,
        backgroundColor: [colors.green[500], colors.orange[600], colors.lightBlue[600], colors.indigo[500]],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: valueTask.statusName,
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  const devices = [
    {
      title: "ดำเนินการเรียบร้อย",
      value: valueTask.amountTask[0],
      color: colors.green[500],
    },
    {
      title: "อยู่ระหว่างดำเนินการ",
      value: valueTask.amountTask[1],
      color: colors.orange[600],
    },
    {
      title: "รอดำเนินการ",
      value: valueTask.amountTask[2],
      color: colors.lightBlue[600],
    },
    {
      title: "ปิดงาน",
      value: valueTask.amountTask[3],
      color: colors.indigo[500],
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="สถานะการดำเนินงาน" />
      <Divider />
      <CardContent>
        <Box height={300} position="relative">
          <Doughnut data={data} options={options} />
        </Box>
        <Grid container direction="column">
          {devices.map(({ color, icon: Icon, title, value }) => (
            <Box mb={-1} key={uuid()}>
              <Grid container item xs={12}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <Grid container item xs={6}>
                    <Typography color="textPrimary" variant="body1">
                      {title}
                    </Typography>
                  </Grid>
                  <Grid container item xs={5}>
                    <Typography style={{ color }} variant="h3">
                      :{"  "}
                      {value}
                    </Typography>
                  </Grid>
                  <Grid container item xs={1}>
                    <Typography variant="body1" color="textPrimary">
                      งาน
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string,
};

export default TrafficByDevice;
