import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

//แสดงข้อมูลลูกค้า
export async function GetEmployeeService(
  search,
  pageSize,
  currentPage,
  bankCode,
  departmentCode,
  positionCode,
  date
) {
  try {
    const response = await Instance.get(
      URL +
        "Employee?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&bankCode=" +
        bankCode +
        "&departmentCode=" +
        departmentCode +
        "&positionCode=" +
        positionCode +
        "&date=" +
        date
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงรายละเอียดการลางาน
export async function GetDetailEmployeeService(id) {
  try {
    const response = await Instance.get(URL + "Employee/GetDetail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//โหลดวันที่ภาษาไทย
export async function LoadDataEmployeeService(id) {
  try {
    const response = await Instance.get(URL + "Employee/LoadData?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//แสดงข้อมูลค่าใช้จ่าย dropdown
export async function GetDataEmployeeService() {
  try {
    const response = await Instance.get(URL + "Employee/GetData");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//บันทึกข้อมูล ลูกจ้าง
export async function SaveEmployeeService(values) {
  try {
    let formdata = new FormData();
    formdata.append("TitleCode", values.titleCode);
    formdata.append("FirstName", values.firstName);
    formdata.append("LastName", values.lastName);
    formdata.append("Email", values.email);
    formdata.append("Username", values.username);
    formdata.append("Password", values.password);
    formdata.append("PhoneNumber", values.phoneNumber);
    formdata.append("StartDate", values.startDate);
    formdata.append("Salary", values.salary);
    formdata.append("NumAccount", values.numAccount);
    formdata.append("BankCode", values.bankCode);
    formdata.append("BankBranch", values.bankBranch);
    formdata.append("DepartmentCode", values.departmentCode);
    formdata.append("PositionCode", values.positionCode);
    formdata.append("EmpDocument", values.empDocument);
    formdata.append("AddressCode", values.addressCode);
    formdata.append("Address", values.address);
    formdata.append("StatusWork", values.statusWork);
    formdata.append("Idcardnumber", values.idcardnumber);
    formdata.append("Birthday", values.birthday);
    formdata.append("Pictures", values.pictures);
    formdata.append("IsUsed", values.isUsed);



    const response = await Instance.post(URL + "Employee/Save", formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//อัพเดพข้อมูล
export async function UpdateEmployeeService(values, data) {
  try {
    let formdata = new FormData();
    formdata.append("TitleCode", data.titleCode);
    formdata.append("FirstName", data.firstName);
    formdata.append("LastName", data.lastName);
    formdata.append("Email", data.email);
    formdata.append("Username", data.username);
    formdata.append("Password", data.password);
    formdata.append("PhoneNumber", data.phoneNumber);
    formdata.append("StartDate", data.startDate);
    formdata.append("Salary", data.salary);
    formdata.append("NumAccount", data.numAccount);
    formdata.append("BankCode", data.bankCode);
    formdata.append("BankBranch", data.bankBranch);
    formdata.append("DepartmentCode", data.departmentCode);
    formdata.append("PositionCode", data.positionCode);
    formdata.append("EmpDocument", data.empDocument);
    formdata.append("AddressCode", data.addressCode);
    formdata.append("Address", data.address);
    formdata.append("StatusWork", data.statusWork);
    formdata.append("Idcardnumber", data.idcardnumber);
    formdata.append("Birthday", data.birthday);
    formdata.append("Pictures", data.pictures);
    formdata.append("IsUsed", data.isUsed);

    const response = await Instance.put(
      URL + "Employee/Update/" + values,
      formdata
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteEmployeeService(id) {
  try {
    const response = await Instance.put(URL + "Employee/Delete/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//ลบข้อมูลลูกค้า
// export async function DeleteEmployeeService(id) {
//   try {
//     const response = await Instance.delete(URL + "Employee/" + id + "/");
//     return await response.data;
//   } catch (error) {
//     console.log("error", error);
//   }
// }
