import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import IconAdd from "../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Page from "../../components/Page";
import clsx from "clsx";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";

const Toolbar = ({
  className,
  searchData,
  since,
  to,
  flutterSince,
  flutterTo,
  clear,
}) => {
  const navigate = useNavigate();
  return (
    <Page className={clsx(className)}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <p style={{ fontSize: 30 }}>ข้อมูลใบเสนอราคา</p>
        </Breadcrumbs>
      </Box>

      <Box mt={1}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="search"
                  onKeyUp={(e) => {
                    searchData(e.target.value);
                  }}
                  placeholder="ค้นหา..เลขที่,ชื่อบริษัท"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <TextField
                    value={since}
                    id="date"
                    label="ตั้งแต่วันที่ "
                    type="date"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      flutterSince(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>

              <Grid item md={2} xs={12}>
                <TextField
                  value={to}
                  id="date"
                  label="ถึงวันที่ "
                  type="date"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    flutterTo(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={1} xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Tooltip title="ล้างข้อมูลการค้นหา">
                    <IconButton
                      values={0}
                      size="small"
                      onClick={(e) => {
                        document.getElementById("search").value = "";
                        clear();
                      }}
                    >
                      <AutorenewIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item md={3} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<IconAdd />}
                    onClick={() => {
                      navigate("/app/insertQuotation", { replace: true });
                    }}
                    size="large"
                  >
                    เพิ่มรายการ
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
