import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import IconSave from "../../../icons/Save";
import IconCancel from "../../../icons/Cancel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { pricetype, percent, Wtselect, Rate, Norate } from "../../../Data/tax.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { province } from "../../../Data/provinceData.json";
import { useNavigate, useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import {
  Box,
  Button,
  CardHeader,
  Card,
  MenuItem,
  Link,
  Container,
  CardContent,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Divider,
} from "@material-ui/core";
import { GetdataTaskService } from "../../../services/serviceTask";
import Page from "../../../components/Page";
import swal from "sweetalert";
import { SaveWttaxService, LoadDataService, UpdateWttax } from "../../../services/wttax.services";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WtSchema = Yup.object().shape({
  wtpricetype: Yup.string().required("กรุณาเลือก ประเภทการจ่ายเงิน"),
  wtvat: Yup.string().required("กรุณาเลือก อัตราภาษี"),
  wtamount: Yup.string().required("กรุณากรอก จำนวนเงิน"),
  wtpayDate: Yup.date().required("กรุณากรอก วันที่"),
  wtpaymentWithholdingtax: Yup.string().required("กรุณากรอก มาตรา 3 เตรส"),
});

const InsertTax = ({}) => {
  const navigate = useNavigate();
  const param = useLocation();
  const classes = useStyles();
  const [setpayer, setValuepayer] = useState("3");
  const [emp, Setemp] = useState([]);
  const [customer, Setcustomer] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [Wt, SetWttax] = useState([]);
  const [customerCode, setcustomerCode] = useState([]);
  const [empCode, setempCode] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChangepayer = (event) => {
  //   setValuepayer(event.target.value);
  // };

  async function loadwttax(param) {
    const resultWt = await LoadDataService(param);
    setcustomerCode(resultWt.data.wtcustomerCode);
    setempCode(resultWt.data.wtempCode);
    SetWttax(resultWt.data);
  }

  async function fetchdata(param) {
    const resultdata = await GetdataTaskService(param);
    Setemp(resultdata.emp);
    Setcustomer(resultdata.customer);
  }

  useEffect(() => {
    fetchdata();
    if (param.state !== null) {
      loadwttax(param.state.id);
    }
  }, []);

  const clear = () => {
    setInputValue("");
  };

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateWttax(param.state.id, values);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/taxListView", { replace: true });
        } else {
          alert(resultUpdate.mgs);
        }
      }
    } else {
      let result = await SaveWttaxService(values);

      if (result !== null) {
        if (result.statusCode === 1) {
          swal({
            title: "สำเร็จ",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/taxListView", { replace: true });
        } else {
          alert(result.mgs);
        }
      }
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        {console.log("wt", Wt)}
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/taxListView">
              หนังสือรับรองการหักภาษี ณ ที่จ่าย
            </Link>
            <Link color="inherit">เพิ่มรายการหนังสือรับรองการหักภาษี ณ ที่จ่าย</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardHeader />
            <CardContent>
              <Box display="flex" justifyContent="center">
                <CardHeader />
                <Typography color="textPrimary" variant="h2">
                  เพิ่มรายการหนังสือรับรองการหักภาษี ณ ที่จ่าย
                </Typography>
              </Box>
              <CardContent>
                <Formik
                  onSubmit={(values) => {
                    SaveValue({
                      wtcustomerCode: customerCode,
                      wtempCode: empCode,
                      wtselect: values.wtselect,
                      wtAttach: values.wtAttach,
                      wtpricetype: values.wtpricetype,
                      wtamount: values.wtamount,
                      wtvat: values.wtvat,
                      wtwithholdingTax: values.wtwithholdingTax == "undefined" ? values.wtwithholdingTax : "",
                      wtpayDate: values.wtpayDate,
                      wtinterest: values.wtinterest,
                      wtdividend: values.wtdividend,
                      wtdividendProfit: values.wtdividendProfit,
                      wtProfitable: values.wtProfitable != "undefined" ? values.wtProfitable : "",
                      wtNoprofit: values.wtNoprofit,
                      wtdividendDetail: values.wtdividendDetail,
                      wtpaymentWithholdingtax: values.wtpaymentWithholdingtax,
                      wtother: values.wtother,
                      wtPayer: values.wtPayer,
                    });
                  }}
                  enableReinitialize
                  initialValues={{
                    wtcustomerCode: Wt.wtcustomerCode != null ? Wt.wtcustomerCode : "",
                    wtempCode: Wt.wtempCode != null ? Wt.wtempCode : "",
                    wtselect: Wt.wtselect != null ? Wt.wtselect : "0",
                    wtpricetype: Wt.wtpricetype != null ? Wt.wtpricetype : "",
                    wtAttach: Wt.wtAttach != null ? Wt.wtAttach : "",
                    wtamount: Wt.wtamount != null ? Wt.wtamount : "",
                    wtwithholdingTax: Wt.wtwithholdingTax,
                    wtvat: Wt.wtvat != null ? Wt.wtvat : "",
                    wtpayDate: Wt.wtpayDate != null ? dateFormat(Wt.wtpayDate, "yyyy-mm-dd") : "",
                    wtinterest: Wt.wtinterest,
                    wtdividend: Wt.wtdividend != null ? Wt.wtdividendDetail : "",
                    wtdividendProfit: Wt.wtdividendProfit != null ? Wt.wtdividendProfit : "",
                    wtdividendDetail: Wt.wtdividendDetail != null ? Wt.wtdividendDetail : "",
                    wtpaymentWithholdingtax: Wt.wtpaymentWithholdingtax,
                    wtother: Wt.wtother,
                    wtPayer: Wt.wtPayer != null ? Wt.wtPayer : "3",
                  }}
                  // validationSchema={WtSchema}
                >
                  {({ errors, touched, handleBlur, values, handleChange, setFieldValue }) => (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          {console.log("save", values)}
                          {console.log("Wt", Wt)}
                          <Divider />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <FormLabel component="legend"></FormLabel>
                          <RadioGroup
                            defaultValue={values.wtselect}
                            value={values.wtselect}
                            aria-label="wtselect"
                            onClick={(e) => {
                              setFieldValue("taxnum", "");
                              setFieldValue("address", "");
                              clear();
                            }}
                            name="wtselect"
                            onChange={(event) => {
                              setFieldValue("wtselect", event.target.value);
                            }}
                          >
                            <Grid item md={12} xs={12}>
                              <FormControlLabel
                                onChange={handleChange}
                                value="0"
                                control={<Radio color="secondary" />}
                                label="นิติบุคคล"
                              />
                              <FormControlLabel
                                onChange={handleChange}
                                value="1"
                                control={<Radio color="secondary" />}
                                label="บุคคลธรรมดา"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {values.wtselect == 0 ? (
                            <Autocomplete
                              freeSolo
                              size="small"
                              defaultValue={values.wtcustomerCode}
                              inputValue={inputValue}
                              value={values.wtcustomerCode}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              options={customer}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  setcustomerCode(newValue.customerCode);
                                  setFieldValue(newValue.customerCode);
                                  setFieldValue("taxnum", newValue.taxnum);
                                  setFieldValue(
                                    "address",
                                    newValue.address +
                                      " " +
                                      province
                                        .filter((x) => x.provinceCode === newValue.provinceCode)
                                        .map(
                                          (z) =>
                                            "จังหวัด" +
                                            " " +
                                            z.provinceName +
                                            " " +
                                            "อำเภอ" +
                                            " " +
                                            z.districtName +
                                            " " +
                                            "ตำบล" +
                                            " " +
                                            z.subdistrictName +
                                            " " +
                                            "รหัสไปรษณีย์" +
                                            " " +
                                            z.postCode
                                        )
                                  );
                                  setFieldValue("companyName", newValue.companyName);
                                }
                              }}
                              getOptionLabel={(option) => option.companyName}
                              renderInput={(params) => (
                                <TextField
                                  // error={Boolean(touched.wtcustomerCode && errors.wtcustomerCode)}
                                  // helperText={touched.wtcustomerCode && errors.wtcustomerCode}
                                  {...params}
                                  name="wtcustomerCode"
                                  label="ชี่อหน่วยงาน/ชื่อบุคคล"
                                  variant="outlined"
                                />
                              )}
                            />
                          ) : values.wtselect == 1 ? (
                            <Autocomplete
                              freeSolo
                              size="small"
                              value={values.wtempCode}
                              defaultValue={values.wtempCode}
                              inputValue={inputValue}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              options={emp}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  setempCode(newValue.empCode);
                                  setFieldValue(newValue.empCode);
                                  setFieldValue("taxnum", newValue.idcardnumber);
                                  setFieldValue(
                                    "address",
                                    newValue.address +
                                      " " +
                                      province
                                        .filter((x) => x.provinceCode === newValue.addressCode)
                                        .map(
                                          (z) =>
                                            "จังหวัด" +
                                            " " +
                                            z.provinceName +
                                            " " +
                                            "อำเภอ" +
                                            " " +
                                            z.districtName +
                                            " " +
                                            "ตำบล" +
                                            " " +
                                            z.subdistrictName +
                                            " " +
                                            "รหัสไปรษณีย์" +
                                            " " +
                                            z.postCode
                                        )
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option.emps}
                              renderInput={(params) => (
                                <TextField
                                  // error={Boolean(touched.wtempCode && errors.wtempCode)}
                                  // helperText={touched.wtempCode && errors.wtempCode}
                                  {...params}
                                  name="wtempCode"
                                  label="ชี่อหน่วยงาน/ชื่อบุคคล"
                                  variant="outlined"
                                />
                              )}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            InputLabelProps={{
                              shrink: values.taxnum,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            value={values.taxnum}
                            name="taxnum"
                            size="small"
                            fullWidth
                            label="เลขประจำตัวเสียภาษีอากร/เลขบัตรประจำตัวประชาชน"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{
                              shrink: values.address,
                            }}
                            value={values.address}
                            name="address"
                            size="small"
                            fullWidth
                            multiline
                            rows={3}
                            label="ที่อยู่"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <FormLabel component="legend">ในแบบ</FormLabel>
                          <RadioGroup
                            aria-label="wtAttach"
                            name="wtAttach"
                            onChange={(event) => {
                              setFieldValue("wtAttach", event.target.value);
                            }}
                            defaultValue={values.wtAttach}
                            value={values.wtAttach}
                          >
                            <Grid item md={12} xs={12}>
                              <FormControlLabel value="1" control={<Radio />} label="(1) ภ.ง.ด.1ก" />
                              <FormControlLabel value="2" control={<Radio />} label="(2) ภ.ง.ด.1ก พิเศษ" />
                              <FormControlLabel value="3" control={<Radio />} label="(3) ภ.ง.ด.2" />
                              <FormControlLabel value="4" control={<Radio />} label="(4) ภ.ง.ด.3" />
                              <FormControlLabel value="5" control={<Radio />} label="(5) ภ.ง.ด.2ก" />
                              <FormControlLabel value="6" control={<Radio />} label="(6) ภ.ง.ด.3ก" />
                              <FormControlLabel value="7" control={<Radio />} label="(7) ภ.ง.ด.53" />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            defaultValue={values.wtpayDate}
                            value={values.wtpayDate}
                            fullWidth
                            id="date"
                            type="date"
                            variant="outlined"
                            name="wtpayDate"
                            size="small"
                            label="วัน เดือน ปี ที่จ่ายเงิน"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={Boolean(touched.wtpayDate && errors.wtpayDate)}
                            helperText={touched.wtpayDate && errors.wtpayDate}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            error={Boolean(touched.wtpricetype && errors.wtpricetype)}
                            helperText={touched.wtpricetype && errors.wtpricetype}
                            defaultValue={values.wtpricetype}
                            value={values.wtpricetype}
                            size="small"
                            label="-- ประเภทเงินที่จ่าย --"
                            name="wtpricetype"
                            onChange={(event) => {
                              setFieldValue("wtpricetype", event.target.value);
                            }}
                            onClick={(e) => {
                              setFieldValue("wtdividendDetail", "");
                              setFieldValue("wtdividendProfit", "");
                              setFieldValue("wtother", "");
                              setFieldValue("wtinterest", "");
                              setFieldValue("wtdividend", "");
                              setFieldValue("wtpaymentWithholdingtax");
                            }}
                            variant="outlined"
                            fullWidth
                            select
                            onBlur={handleBlur}
                          >
                            {pricetype.map((values) => (
                              <MenuItem key={values.pricetypeCode} value={values.pricetypeCode}>
                                {values.pricetypeName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {values.wtpricetype == 4 ? (
                          <Grid item md={2} xs={12}>
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup
                              onClick={(e) => {
                                setFieldValue("wtdividend", "");
                                setFieldValue("wtdividendDetail", "");
                                setFieldValue("wtdividendProfit", "");
                                setFieldValue("wtNoprofit", "");
                                setFieldValue("wtProfitable", "");
                              }}
                              aria-label="wtinterest"
                              value={values.wtinterest}
                              name="wtinterest"
                              onChange={(event) => {
                                setFieldValue("wtinterest", event.target.value);
                              }}
                            >
                              <Grid item md={12} xs={12}>
                                <FormControlLabel
                                  onChange={handleChange}
                                  value="1"
                                  control={<Radio color="secondary" />}
                                  label="ค่าดอกเบี้ย"
                                />
                                <FormControlLabel
                                  onChange={handleChange}
                                  value="0"
                                  control={<Radio color="secondary" />}
                                  label="เงินปันผล"
                                  onClick={handleClickOpen}
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Dialog open={open} TransitionComponent={Transition}>
                          <DialogTitle>{"กรณีผู้ได้รับ หรือ ไม่ได้รับ เงินปันผลได้รับเครดิตภาษี"}</DialogTitle>
                          <DialogContent>
                            <Grid item md={12} xs={12}>
                              <FormLabel component="legend"></FormLabel>
                              <RadioGroup
                                aria-label="wtdividend"
                                value={values.wtdividend}
                                onClick={(e) => {
                                  setFieldValue("wtdividendDetail", "");
                                  setFieldValue("wtdividendProfit", "");
                                  setFieldValue("wtNoprofit", "");
                                  setFieldValue("wtProfitable", "");
                                }}
                                name="wtdividend"
                                onChange={(event) => {
                                  setFieldValue("wtdividend", event.target.value);
                                }}
                              >
                                <FormControlLabel
                                  onChange={handleChange}
                                  onClick={handleClose}
                                  value="1"
                                  control={<Radio color="secondary" />}
                                  label="ผู้ได้รับเงินปันผล"
                                />
                                <FormControlLabel
                                  onChange={handleChange}
                                  onClick={handleClose}
                                  value="2"
                                  control={<Radio color="secondary" />}
                                  label="ผู้ไม่ได้รับเงินปันผล"
                                />
                              </RadioGroup>
                            </Grid>
                          </DialogContent>
                        </Dialog>

                        {values.wtdividend == 1 ? (
                          <Grid item md={4} xs={12}>
                            <TextField
                              onClick={(e) => {
                                setFieldValue("wtdividendDetail", "");
                                setFieldValue("wtNoprofit", "");
                                setFieldValue("wtProfitable", "");
                              }}
                              defaultValue={values.wtdividendProfit}
                              value={values.wtdividendProfit}
                              size="small"
                              label="-- อัตราเงินปันผล --"
                              name="wtdividendProfit"
                              variant="outlined"
                              fullWidth
                              select
                              type="text"
                              onBlur={handleBlur}
                              onChange={(event) => {
                                setFieldValue("wtdividendProfit", event.target.value);
                              }}
                            >
                              {Rate.map((item) => (
                                <MenuItem key={item.rateCode} value={item.rateCode}>
                                  {item.rateName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {values.wtdividend == 2 ? (
                          <Grid item md={4} xs={12}>
                            <TextField
                              onClick={(e) => {
                                setFieldValue("wtdividendProfit", "");
                                setFieldValue("wtNoprofit", "");
                                setFieldValue("wtProfitable", "");
                              }}
                              size="small"
                              fullWidth
                              defaultValue={values.wtdividendDetail}
                              value={values.wtdividendDetail}
                              label="ผู้ไม่ได้รับเงินปันผล เพราะ..."
                              type="text"
                              select
                              name="wtdividendDetail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                            >
                              {Norate.map((values) => (
                                <MenuItem key={values.norateCode} value={values.norateCode}>
                                  {values.noRateName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {values.wtdividendProfit == "-1" ? (
                          <Grid item md={6} xs={12}>
                            <TextField
                              value={values.wtProfitable}
                              defaultValue={values.wtProfitable}
                              size="small"
                              fullWidth
                              label="-- อัตราเงินปันผล (ระบุ) --"
                              type="text"
                              onChange={(event) => {
                                setFieldValue("wtProfitable", event.target.value);
                              }}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="wtProfitable"
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                        {values.wtdividendDetail == 5 ? (
                          <Grid item md={6} xs={12}>
                            <TextField
                              value={values.wtNoprofit}
                              defaultValue={values.wtNoprofit}
                              size="small"
                              fullWidth
                              multiline
                              rows={1}
                              label="อื่นๆ (ระบุ)..."
                              type="text"
                              onChange={(event) => {
                                setFieldValue("wtNoprofit", event.target.value);
                              }}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="wtNoprofit"
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                        {values.wtpricetype == "6" ? (
                          <Grid item md={6} xs={12}>
                            <TextField
                              value={values.wtother}
                              name="wtother"
                              size="small"
                              fullWidth
                              label="ระบุละเอียดอื่น ๆ"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                            />
                          </Grid>
                        ) : values.wtpricetype == "5" ? (
                          <Grid item md={6} xs={12}>
                            <TextField
                              value={values.wtpaymentWithholdingtax}
                              name="wtpaymentWithholdingtax"
                              size="small"
                              fullWidth
                              label="มาตรา 3 เตรส"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              error={Boolean(touched.wtpaymentWithholdingtax && errors.wtpaymentWithholdingtax)}
                              helperText={touched.wtpaymentWithholdingtax && errors.wtpaymentWithholdingtax}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Divider />

                        {values.wtpricetype != 4 && values.wtdividend != 2 ? (
                          <Grid item md={3} xs={12}>
                            <TextField
                              value={values.wtamount}
                              defaultValue={values.wtamount}
                              size="small"
                              fullWidth
                              label="จำนวนเงิน ที่จ่าย"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="wtamount"
                              error={Boolean(touched.wtamount && errors.wtamount)}
                              helperText={touched.wtamount && errors.wtamount}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}

                        {values.wtpricetype != 4 ? (
                          <Grid item md={1} xs={12}>
                            <TextField
                              defaultValue={values.wtvat}
                              value={values.wtvat}
                              size="small"
                              select
                              fullWidth
                              name="wtvat"
                              label=" % ภาษี"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              error={Boolean(touched.wtvat && errors.wtvat)}
                              helperText={touched.wtvat && errors.wtvat}
                            >
                              {percent.map((values) => (
                                <MenuItem key={values.percentCode} value={values.percentCode}>
                                  {values.percentName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        ) : values.wtdividend == 1 ? (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                value={values.wtamount}
                                defaultValue={values.wtamount}
                                size="small"
                                fullWidth
                                label="จำนวนเงิน ที่จ่าย"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                name="wtamount"
                                error={Boolean(touched.wtamount && errors.wtamount)}
                                helperText={touched.wtamount && errors.wtamount}
                              />
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <TextField
                                value={
                                  values.wtdividend != 1
                                    ? (values.wtamount * values.wtvat) / 100
                                    : (values.wtamount *
                                        (values.wtdividendProfit == "-1"
                                          ? values.wtProfitable
                                          : values.wtdividendProfit)) /
                                      100
                                }
                                defaultValue={values.wtwithholdingTax}
                                size="small"
                                fullWidth
                                label="ภาษี หัก ณ ที่จ่าย"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                name="wtwithholdingTax"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}

                        {/* ////////////////////////////////////////////////////////////////////////////////////// */}

                        {values.wtpricetype != 4 && values.wtdividend != 1 ? (
                          <Grid item md={2} xs={12}>
                            <TextField
                              value={
                                values.wtdividend != 1
                                  ? (values.wtamount * values.wtvat) / 100
                                  : (values.wtamount *
                                      (values.wtdividendProfit == "-1"
                                        ? values.wtProfitable
                                        : values.wtdividendProfit)) /
                                    100
                              }
                              defaultValue={values.wtwithholdingTax}
                              size="small"
                              fullWidth
                              label="ภาษี หัก ณ ที่จ่าย"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="wtwithholdingTax"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}

                        <Grid item md={12} xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item md={7} xs={12}>
                          <FormLabel component="legend">ผู้จ่ายเงิน</FormLabel>
                          <RadioGroup
                            aria-label="wtPayer"
                            name="wtPayer"
                            //value={setpayer}
                            value={values.wtPayer}
                            defaultValue={values.wtPayer}
                            //onChange={handleChangepayer}
                            onChange={(event) => {
                              setFieldValue("wtPayer", event.target.value);
                            }}
                          >
                            <Grid item md={12} xs={12}>
                              <FormControlLabel value="1" control={<Radio />} label="(1) ออกภาษีให้ครั้งเดียว" />
                              <FormControlLabel value="2" control={<Radio />} label="(2) ออกภาษีให้ตลอดไป" />
                              <FormControlLabel value="3" control={<Radio />} label="(3) หักภาษี ณ ที่จ่าย" />
                              <FormControlLabel value="4" control={<Radio />} label="(4) อื่น ๆ " />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                        {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
                      </Grid>
                      <Box display="flex" justifyContent="center" p={2}>
                        <Box mr={3}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<IconSave />}
                            size="large"
                          >
                            บันทึก
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<IconCancel />}
                            onClick={() => {
                              navigate("/app/taxListView", {
                                replace: true,
                              });
                            }}
                            size="large"
                          >
                            ยกเลิก
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default InsertTax;
