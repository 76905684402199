import React, { useState, useEffect } from "react";
import IconSave from "../../../icons/Save";
import IconCancel from "../../../icons/Cancel";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Page from "../../../components/Page";
import AttachmentIcon from "@material-ui/icons/Attachment";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat from "dateformat";
import CKEditor from "ckeditor4-react";
import IconDel from "../../../icons/Del";
import { tax, incomeType, costType } from "../../../Data/salaryData";
import {
  LoadDataSalaryService,
  SaveSalaryService,
  UpdateSalaryService,
  GetDataSalaryService,
  DeleteIncomeService,
  DeleteCostService,
} from "../../../services/salary.services";
import AddIcon from "@material-ui/icons/Add";
import { inc } from "nprogress";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  input: {
    display: "none",
  },
}));

const FormSalary = ({ className, fetchData, ...rest }) => {
  const classes = useStyles();
  const [setEmp, SetEmp] = useState([]);
  const navigate = useNavigate();
  const param = useLocation();
  const [setSal, SetSal] = useState({});
  const [setIn, SetIn] = useState([]);
  const [setCost, SetCost] = useState([]);
  const [ErrorMessIn, SetErrorMessIn] = useState(0);
  const [setIncomeType, SetIncomeType] = useState("");
  const [setIncomeAmount, SetIncomeAmount] = useState("");
  const [setSalary, SetSalary] = useState("");

  const Salary = Yup.object({
    empCode: Yup.string().required("กรุณาเลือกพนักงาน"),
    salaryAmount: Yup.string().required("กรุณากรอกข้อมูล"),
    date: Yup.string().required("กรุณากรอกข้อมูล"),
    month: Yup.string().required("กรุณากรอกข้อมูล"),
    year: Yup.string().required("กรุณากรอกข้อมูล"),
    income:
      ErrorMessIn > 0 || setIncomeType != "" || setIncomeAmount != ""
        ? Yup.array().of(
            Yup.object().shape({
              incomeType: Yup.string()
                .required("กรุณาเลือกประเภทรายได้")
                .typeError("กรุณาเลือกประเภทรายได้"),

              amount: Yup.number("กรณากรอกข้อมูลเป็นตัวเลข")
                .max(999999999, "กรุณากรอกจำนวนเงินน้อยกว่าหรือเท่ากับ 9 หลัก")
                .required("กรุณากรอกจำนวนเงิน")
                .typeError("กรุณากรอกจำนวนเงิน"),
            })
          )
        : "",
    cost: Yup.array().of(
      Yup.object().shape({
        costType:
          setSalary == ""
            ? Yup.string()
                .required("กรุณาเลือกพนักงานก่อน")
                .typeError("กรุณาเลือกพนักงานก่อน")
            : Yup.string()
                .required("กรุณาเลือกประเภทรายจ่าย")
                .typeError("กรุณาเลือกประเภทรายจ่าย"),
        taxRate: Yup.string().required("กรุณาเลือกเรทภาษี"),
        amount:
          setSalary == ""
            ? Yup.string()
                .required("กรุณาเลือกพนักงานก่อน")
                .typeError("กรุณาเลือกพนักงานก่อน")
            : Yup.number("กรณากรอกข้อมูลเป็นตัวเลข")
                .max(999999999, "กรุณากรอกจำนวนเงินน้อยกว่าหรือเท่ากับ 9 หลัก")
                .required("กรุณากรอกจำนวนเงิน")
                .typeError("กรุณากรอกจำนวนเงิน"),
      })
    ),
  });

  async function loadSalary(param) {
    const resultExpenses = await LoadDataSalaryService(param);
    SetSal(resultExpenses.salary);
    SetIn(resultExpenses.income);
    SetCost(resultExpenses.cost);

    console.log("salary", resultExpenses.salary);
    console.log("income", setIn);
    console.log("cost", resultExpenses.cost);
  }

  async function fetchSalary() {
    const result = await GetDataSalaryService();
    SetEmp(result.emp);
  }

  useEffect(() => {
    if (param.state !== null) {
      loadSalary(param.state.id);
    }
    fetchSalary();
  }, []);

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let result = await UpdateSalaryService(param.state.id, values);
      if (result.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/indexSalary", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    } else {
      let result = await SaveSalaryService(values);
      console.log(result);
      if (result.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/indexSalary", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    }
  };

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  var date = new Date().getDate();
  var monthlist = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(2), (e, index) => year - index);

  const DelIncome = async (value) => {
    let result = await DeleteIncomeService(value);
    if (result.statusCode === 1) {
      if (param.state !== null) {
        loadSalary(param.state.id);
      }
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };
  const DelCost = async (value) => {
    let result = await DeleteCostService(value);
    if (result.statusCode === 1) {
      if (param.state !== null) {
        loadSalary(param.state.id);
      }
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/indexSalary">
              รายการข้อมูลการจ่ายเงินเดือน
            </Link>
            <Link color="textPrimary">บันทึกข้อมูลการจ่ายเงินเดือน</Link>
          </Breadcrumbs>
        </Box>

        <CardContent>
          <Formik
            onSubmit={(values) => {
              SaveValue(values);
              console.log("values", values);
            }}
            enableReinitialize
            initialValues={{
              month: setSal.month != null ? setSal.month : month.toString(),
              year: setSal.year != null ? setSal.year : year.toString(),
              date:
                setSal.date != null
                  ? dateFormat(setSal.date, "yyyy-mm-dd")
                  : dateFormat(year + "-" + month + "-" + date, "yyyy-mm-dd"),
              empCode: setSal.empCode != null ? setSal.empCode : "",
              salaryAmount:
                setSal.salaryAmount != null ? setSal.salaryAmount : "",
              note: setSal.note != null ? setSal.note : "",

              income:
                setIn.length == 0
                  ? [
                      {
                        incomeCode:
                          setIn.incomeCode != null ? setIn.incomeCode : 0,
                        incomeType:
                          setIn.incomeType != null
                            ? parseInt(setIn.incomeType)
                            : null,
                        amount:
                          setIn.amount != null
                            ? parseFloat(setIn.amount)
                            : null,
                      },
                    ]
                  : setIn,

              cost:
                setCost.length == 0
                  ? [
                      {
                        costCode: setIn.costCode != null ? setIn.costCode : 0,
                        costType:
                          setCost.costType != null
                            ? parseInt(setCost.costType)
                            : "",
                        taxRate:
                          setCost.taxRate != null
                            ? parseInt(setCost.taxRate)
                            : "",
                        amount:
                          setCost.amount != null
                            ? parseFloat(setCost.amount)
                            : "",
                      },
                    ]
                  : setCost,
            }}
            validationSchema={Salary}
          >
            {({
              errors,
              touched,
              handleBlur,
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={3}>
                  <Card className={classes.card}>
                    <CardHeader />
                    <Box display="flex" justifyContent="center">
                      <CardHeader />
                      <Typography color="textPrimary" variant="h3">
                        บันทึกข้อมูลการจ่ายเงินเดือน
                      </Typography>
                    </Box>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          {console.log("aaa", values.cost)}
                          <TextField
                            value={values.empCode}
                            size="small"
                            label="-- รหัสพนักงาน, ชื่อพนักงาน --"
                            name="empCode"
                            onChange={(e, value) => {
                              setFieldValue("empCode", e.target.value);
                              var i;
                              for (i = 0; i < values.cost.length; i++) {
                                setFieldValue(`cost.${i}.taxRate`, "")
                                setFieldValue(`cost.${i}.amount`, "")
                              }
                            

                              if (value.props.salaryAmount != null) {
                                setFieldValue(
                                  "salaryAmount",
                                  value.props.salaryAmount
                                );
                                SetSalary(value.props.salaryAmount);
                              }
                              console.log(e.target.value);
                            }}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.empCode && errors.empCode)}
                            helperText={touched.empCode && errors.empCode}
                            onBlur={handleBlur}
                          >
                            {setEmp.map((values) => (
                              <MenuItem
                                key={values.empCode}
                                value={values.empCode}
                                salaryAmount={values.salary}
                              >
                                {values.empCode +
                                  " " +
                                  values.titleName +
                                  " " +
                                  values.firstName +
                                  " " +
                                  values.lastName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            value={values.salaryAmount}
                            InputProps={{
                              readOnly: true,
                              inputComponent: NumberFormatCustom,
                            }}
                            size="small"
                            fullWidth
                            label="เงินเดือน"
                            name="salaryAmount"
                            autoComplete="off"
                            onChange={handleChange}
                            error={Boolean(
                              touched.salaryAmount && errors.salaryAmount
                            )}
                            helperText={
                              touched.salaryAmount && errors.salaryAmount
                            }
                            onBlur={handleBlur}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: values.salaryAmount != "" ? true : false,
                            }}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            value={values.date}
                            id="date"
                            label="วันที่จ่ายเงินเดือน "
                            type="date"
                            fullWidth
                            name="date"
                            size="small"
                            variant="outlined"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.date && errors.date)}
                            helperText={touched.date && errors.date}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            value={values.month}
                            size="small"
                            label="-- เดือน --"
                            name="month"
                            onChange={(e) => {
                              setFieldValue("month", e.target.value.toString());
                            }}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.month && errors.month)}
                            helperText={touched.month && errors.month}
                            onBlur={handleBlur}
                          >
                            {monthlist.map((month, i) => (
                              <MenuItem value={i + 1}>{month}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            value={values.year}
                            size="small"
                            label="-- ปี --"
                            name="year"
                            onChange={(e) => {
                              setFieldValue("year", e.target.value.toString());
                            }}
                            variant="outlined"
                            fullWidth
                            select
                            error={Boolean(touched.year && errors.year)}
                            helperText={touched.year && errors.year}
                            onBlur={handleBlur}
                          >
                            {years.map((year, index) => (
                              <MenuItem key={`year${index}`} value={year}>
                                {year + 543}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>

                <Box mt={1}>
                  <Card className={classes.card}>
                    <CardHeader />
                    <Box display="flex" justifyContent="start">
                      <CardHeader />
                      <Typography color="textPrimary" variant="h4">
                        รายการรายรับ
                      </Typography>
                    </Box>
                    <CardContent>
                      <FieldArray
                        name="income"
                        render={({ insert, remove, push }) => (
                          <Box>
                            {values.income.length > 0 &&
                              values.income.map((friend, index) => (
                                <Box key={index}>
                                  <Typography>รายการที่ {index + 1}</Typography>
                                  <Grid container spacing={1}>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        value={
                                          values.income[index].incomeType !=
                                          null
                                            ? values.income[index].incomeType
                                            : ""
                                        }
                                        size="small"
                                        label="-- ประเภทรายรับ --"
                                        name={`income.${index}.incomeType`}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `income.${index}.incomeType`,
                                            e.target.value
                                          );
                                          SetIncomeType(
                                            `income.${index}.incomeType`,
                                            e.target.value
                                          );
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        error={
                                          errors &&
                                          errors.income &&
                                          errors.income[index] &&
                                          errors.income[index].incomeType &&
                                          touched &&
                                          touched.income &&
                                          touched.income[index] &&
                                          touched.income[index].incomeType && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.income[index].incomeType}
                                            </div>
                                          )
                                        }
                                        helperText={
                                          errors &&
                                          errors.income &&
                                          errors.income[index] &&
                                          errors.income[index].incomeType &&
                                          touched &&
                                          touched.income &&
                                          touched.income[index] &&
                                          touched.income[index].incomeType && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.income[index].incomeType}
                                            </div>
                                          )
                                        }
                                        onBlur={handleBlur}
                                        select
                                      >
                                        {incomeType.map((item) => (
                                          <MenuItem value={item.incomeCode}>
                                            {item.incomeName}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        value={
                                          values.income[index].amount != null
                                            ? values.income[index].amount
                                            : ""
                                        }
                                        fullWidth
                                        type="text"
                                        label="จำนวนเงิน"
                                        autoComplete="off"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `income.${index}.amount`,
                                            e.target.value
                                          );
                                          SetIncomeAmount(
                                            `income.${index}.amount`,
                                            e.target.value
                                          );
                                          // const sum = values.income.reduce((totalCalories, meal) => parseFloat(meal.amount)  + totalCalories, 0)

                                          // setFieldValue("incomeTotal", sum);
                                        }}
                                        error={
                                          errors &&
                                          errors.income &&
                                          errors.income[index] &&
                                          errors.income[index].amount &&
                                          touched &&
                                          touched.income &&
                                          touched.income[index] &&
                                          touched.income[index].amount && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.income[index].amount}
                                            </div>
                                          )
                                        }
                                        helperText={
                                          errors &&
                                          errors.income &&
                                          errors.income[index] &&
                                          errors.income[index].amount &&
                                          touched &&
                                          touched.income &&
                                          touched.income[index] &&
                                          touched.income[index].amount && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.income[index].amount}
                                            </div>
                                          )
                                        }
                                        onBlur={handleBlur}
                                        name={`income.${index}.amount`}
                                        InputProps={{
                                          inputComponent: NumberFormatCustom,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item md={2}>
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                          SetErrorMessIn(ErrorMessIn - 1);
                                          if (
                                            param.state !== null &&
                                            values.income[index].incomeCode !=
                                              undefined
                                          ) {
                                            swal({
                                              className: "bg-modal-red",
                                              icon: "warning",
                                              dangerMode: true,
                                              text:
                                                "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                              buttons: {
                                                cancel: "ยกเลิก",
                                                confirm: {
                                                  text: "ใช่",
                                                  value:
                                                    values.income[index]
                                                      .incomeCode,
                                                },
                                              },
                                            }).then((value) => {
                                              if (value !== null) {
                                                DelIncome(value);
                                                remove(index);
                                              }
                                            });
                                          } else {
                                            swal({
                                              className: "bg-modal-red",
                                              icon: "warning",
                                              dangerMode: true,
                                              text:
                                                "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                              buttons: {
                                                cancel: "ยกเลิก",
                                                confirm: {
                                                  text: "ใช่",
                                                  value: 1,
                                                },
                                              },
                                            }).then((value) => {
                                              if (value != null) {
                                                swal({
                                                  title: "สำเร็จ!",
                                                  text: "",
                                                  icon: "success",
                                                  showConfirmButton: false,
                                                  button: "ปิด",
                                                  timer: 1500,
                                                });
                                                remove(index);
                                                SetIncomeType("");
                                                SetIncomeAmount("");
                                              }
                                            });
                                          }
                                        }}
                                        size="medium"
                                      >
                                        <IconDel />
                                      </Button>

                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                          SetErrorMessIn(ErrorMessIn + 1);
                                          push({
                                            amount:
                                              setIn.amount != null
                                                ? setIn.amount
                                                : null,
                                            incomeType:
                                              setIn.incomeType != null
                                                ? setIn.incomeType
                                                : null,
                                          });
                                        }}
                                        size="medium"
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))}

                            {values.income.length == 0
                              ? push({
                                  amount:
                                    setIn.amount != null ? setIn.amount : null,
                                  incomeType:
                                    setIn.incomeType != null
                                      ? setIn.incomeType
                                      : null,
                                }) + SetErrorMessIn(0)
                              : ""}
                          </Box>
                        )}
                      />
                    </CardContent>
                  </Card>
                </Box>

                <Box mt={1}>
                  <Card className={classes.card}>
                    <CardHeader />
                    <Box display="flex" justifyContent="start">
                      <CardHeader />
                      <Typography color="textPrimary" variant="h4">
                        รายการรายจ่าย
                      </Typography>
                    </Box>
                    <CardContent>
                      <FieldArray
                        name="cost"
                        render={({ insert, remove, push }) => (
                          <Box>
                            {values.cost.length > 0 &&
                              values.cost.map((friend, index) => (
                                <Box key={index}>
                                  <Typography>รายการที่ {index + 1}</Typography>
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      md={
                                        values.cost[index].costType == ""
                                          ? 5
                                          : 3
                                      }
                                      xs={12}
                                    >
                                      <TextField
                                        value={values.cost[index].costType}
                                        InputProps={{
                                          readOnly:
                                            values.salaryAmount != ""
                                              ? false
                                              : true,
                                        }}
                                        size="small"
                                        label="-- ประเภทรายจ่าย --"
                                        name={`cost.${index}.costType`}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setFieldValue(
                                            `cost.${index}.taxRate`,
                                            ""
                                          );
                                          setFieldValue(
                                            `cost.${index}.amount`,
                                            ""
                                          );
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        error={
                                          errors &&
                                          errors.cost &&
                                          errors.cost[index] &&
                                          errors.cost[index].costType &&
                                          touched &&
                                          touched.cost &&
                                          touched.cost[index] &&
                                          touched.cost[index].costType && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.cost[index].costType}
                                            </div>
                                          )
                                        }
                                        helperText={
                                          errors &&
                                          errors.cost &&
                                          errors.cost[index] &&
                                          errors.cost[index].costType &&
                                          touched &&
                                          touched.cost &&
                                          touched.cost[index] &&
                                          touched.cost[index].costType && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.cost[index].costType}
                                            </div>
                                          )
                                        }
                                        onBlur={handleBlur}
                                        select
                                      >
                                        {costType.map((item) => (
                                          <MenuItem value={item.costCode}>
                                            {item.costName}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    {values.cost[index].costType != "" ? (
                                      <Grid item md={2} xs={12}>
                                        <TextField
                                          value={values.cost[index].taxRate}
                                          size="small"
                                          label="-- อัตราภาษี --"
                                          name={`cost.${index}.taxRate`}
                                          onChange={(e) => {
                                            handleChange(e);
                                            if (
                                              values.salaryAmount > 15000 &&
                                              values.cost[index].costType == 2
                                            ) {
                                              var Amount =
                                                (15000 * e.target.value) / 100;
                                            } else {
                                              var Amount =
                                                (values.salaryAmount *
                                                  e.target.value) /
                                                100;
                                            }
                                            setFieldValue(
                                              `cost.${index}.amount`,
                                              Amount
                                            );
                                          }}
                                          variant="outlined"
                                          fullWidth
                                          error={
                                            errors &&
                                            errors.cost &&
                                            errors.cost[index] &&
                                            errors.cost[index].taxRate &&
                                            touched &&
                                            touched.cost &&
                                            touched.cost[index] &&
                                            touched.cost[index].taxRate && (
                                              <div
                                                className="field-error"
                                                style={{
                                                  color: "red",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {errors.cost[index].taxRate}
                                              </div>
                                            )
                                          }
                                          helperText={
                                            errors &&
                                            errors.cost &&
                                            errors.cost[index] &&
                                            errors.cost[index].taxRate &&
                                            touched &&
                                            touched.cost &&
                                            touched.cost[index] &&
                                            touched.cost[index].taxRate && (
                                              <div
                                                className="field-error"
                                                style={{
                                                  color: "red",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {errors.cost[index].taxRate}
                                              </div>
                                            )
                                          }
                                          onBlur={handleBlur}
                                          select
                                        >
                                          {tax.map((item) => (
                                            <MenuItem value={item.tax}>
                                              {item.taxName}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    ) : (
                                      ""
                                    )}

                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        value={values.cost[index].amount}
                                        fullWidth
                                        type="text"
                                        label="จำนวนเงิน"
                                        autoComplete="off"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name={`cost.${index}.amount`}
                                        error={
                                          errors &&
                                          errors.cost &&
                                          errors.cost[index] &&
                                          errors.cost[index].amount &&
                                          touched &&
                                          touched.cost &&
                                          touched.cost[index] &&
                                          touched.cost[index].amount && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.cost[index].amount}
                                            </div>
                                          )
                                        }
                                        helperText={
                                          errors &&
                                          errors.cost &&
                                          errors.cost[index] &&
                                          errors.cost[index].amount &&
                                          touched &&
                                          touched.cost &&
                                          touched.cost[index] &&
                                          touched.cost[index].amount && (
                                            <div
                                              className="field-error"
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {errors.cost[index].amount}
                                            </div>
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink:
                                            values.cost[index].amount != ""
                                              ? true
                                              : false,
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                          inputComponent: NumberFormatCustom,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item md={2}>
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                          if (
                                            param.state !== null &&
                                            values.cost[index].costCode !=
                                              undefined
                                          ) {
                                            swal({
                                              className: "bg-modal-red",
                                              icon: "warning",
                                              dangerMode: true,
                                              text:
                                                "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                              buttons: {
                                                cancel: "ยกเลิก",
                                                confirm: {
                                                  text: "ใช่",
                                                  value:
                                                    values.cost[index].costCode,
                                                },
                                              },
                                            }).then((value) => {
                                              if (value !== null) {
                                                DelCost(value);
                                                remove(index);
                                              }
                                            });
                                          } else {
                                            swal({
                                              className: "bg-modal-red",
                                              icon: "warning",
                                              dangerMode: true,
                                              text:
                                                "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                              buttons: {
                                                cancel: "ยกเลิก",
                                                confirm: {
                                                  text: "ใช่",
                                                  value: 1,
                                                },
                                              },
                                            }).then((value) => {
                                              if (value != null) {
                                                swal({
                                                  title: "สำเร็จ!",
                                                  text: "",
                                                  icon: "success",
                                                  showConfirmButton: false,
                                                  button: "ปิด",
                                                  timer: 1500,
                                                });
                                                remove(index);
                                              }
                                            });
                                          }
                                        }}
                                        size="medium"
                                      >
                                        <IconDel />
                                      </Button>

                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          push({
                                            taxRate:
                                              setCost.taxRate != null
                                                ? setCost.taxRate
                                                : "",
                                            amount:
                                              setCost.amount != null
                                                ? setCost.amount
                                                : "",
                                            costType:
                                              setCost.costType != null
                                                ? setCost.costType
                                                : "",
                                          })
                                        }
                                        size="medium"
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))}
                            {values.cost.length == 0
                              ? push({
                                  taxRate:
                                    setCost.taxRate != null
                                      ? setCost.taxRate
                                      : "",
                                  amount:
                                    setCost.amount != null
                                      ? setCost.amount
                                      : "",
                                  costType:
                                    setCost.costType != null
                                      ? setCost.costType
                                      : "",
                                })
                              : ""}
                          </Box>
                        )}
                      />
                    </CardContent>
                  </Card>
                </Box>

                <Box mt={1}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Box mt={1}>
                        <Grid container spacing={1}>
                          <Grid item md={12} xs={12}>
                            <TextField
                              value={values.note}
                              name="note"
                              size="small"
                              fullWidth
                              label="หมายเหตุ"
                              autoComplete="off"
                              variant="outlined"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box display="flex" justifyContent="center" p={2}>
                        <Box mr={3}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<IconSave />}
                            size="large"
                          >
                            บันทึก
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<IconCancel />}
                            size="large"
                            onClick={() => {
                              navigate("/app/indexSalary", { replace: true });
                            }}
                          >
                            ยกเลิก
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Container>
    </Page>
  );
};

export default FormSalary;
