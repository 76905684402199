import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

export async function GetCustomerService(search, pageSize, currentPage) {
  try {
    const response = await Instance.get(
      URL +
        "Customer?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//Dropdown ลูกค้า
export async function GetDataCustomerSevice() {
  try {
    const response = await Instance.get(URL + "Customer/GetData");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงรายละเอียดลูกค้า
export async function GetDetailCustomerService(id) {
  try {
    const response = await Instance.get(URL + "Customer/GetDetail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//บันทึกข้อมูล ลูกค้า
export async function SaveCustomerService(values) {
  try {
    const response = await Instance.post(URL + "Customer/Save", values);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateCustomer(values, data) {
  try {
  const response = await Instance.put(URL + "Customer/Update/" + values ,data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteCustomerService(id) {
  try {
    const response = await Instance.put(URL + "Customer/Delete/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function DeleteContactService(id) {
  try {
    const response = await Instance.put(URL + "Customer/Delete/con/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}


//โหลดวันที่ภาษาไทย
export async function LoadDataCustomerService(id) {
  try {
    const response = await Instance.get(URL + "Customer/LoadData?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
