import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconSave from "../../..//icons/Save";
import IconCancel from "../../..//icons/Cancel";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import {
  Container,
  Box,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Page from "../../..//components/Page";
import swal from "sweetalert";
import { province } from "../../../Data/provinceData";
import AddIcon from "@material-ui/icons/Add";
import {
  UpdateCustomer,
  SaveCustomerService,
} from "../../../services/customer.services";
import IconDel from "../../..//icons/Del";
import { DeleteContactService } from "../../../services/customer.services";
import { LoadDataCustomerService } from "../../../services/customer.services";
import { GetDataCustomerSevice } from "../../../services/customer.services";
import { makeStyles } from "@material-ui/core/styles";
const CUSError = Yup.object().shape({
  companyName: Yup.string()
    .max(255)
    .required("กรุณากรอกชื่อบริษัท"),
  taxnum: Yup.number("กรณากรอกข้อมูลเป็นตัวเลข")
    .required("กรุณากรอกเลขประจำตัวผู้เสียภาษีอากร ")
    .typeError("กรุณากรอกข้อมูลเป็นตัวเลข"),
  address: Yup.string()
    .max(500)
    .required("กรุณากรอกรายละเอียดที่อยู่"),

  provinceName: Yup.string().required("กรุณาเลือกจังหวัด"),
  districtName: Yup.string().required("กรุณาเลือกอำเภอ"),
  subdistrictName: Yup.string().required("กรุณาเลือกตำบล"),
  postCode: Yup.string().required("กรุณากรอกหมายเลขรหัสไปรษณีย์"),
  customerContact: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("กรุณากรอก ชื่อจริง"),
      lastName: Yup.string().required("กรุณากรอก นามสกุล"),
      mobile: Yup.number("กรณากรอก ข้อมูลเป็นตัวเลข")
        .required("กรุณากรอก หมายเลขเบอร์โทรศัพท์")
        .typeError("กรุณากรอก ข้อมูลเป็นตัวเลข"),
      projectsCode: Yup.string().required("กรุณาเลือก ชื่อโปรเจ็ค"),
    })
  ),
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  card: {
    height: "100%",
    width: "100%",
  },
}));
const Formcustomer = (className) => {
  const navigate = useNavigate();
  const [setCus, SetCus] = useState([]);
  // const [AddressCode, setAddressCode] = useState([]);
  const [customerContact, SetContact] = useState([]);
  const [setProjectCode, SetprojectCode] = useState([]);
  const classes = useStyles();
  const param = useLocation();

  useEffect(() => {
    if (param.state !== null) {
      loadCus(param.state.id);
    }
    fetchDataCus();
  }, []);
  async function loadCus(param) {
    const resultCus = await LoadDataCustomerService(param);
    // setAddressCode(resultCus.cus.provinceCode); /************ */
    SetCus(resultCus.cus);
    SetContact(resultCus.con);
  }

  async function fetchDataCus() {
    const result = await GetDataCustomerSevice();
    SetprojectCode(result.pros);
  }

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateCustomer(param.state.id, values);
      if (resultUpdate.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/indexCus", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    } else {
      let result = await SaveCustomerService(values);
      alert("SAVE");
      if (result.statusCode === 1) {
        swal({
          title: "สำเร็จ!",
          text: "",
          icon: "success",
          button: "ปิด",
        });
        navigate("/app/indexCus", { replace: true });
      } else {
        swal({
          title: "ไม่สำเร็จ!",
          text: "",
          icon: "warning",
          button: "ปิด",
        });
      }
    }
  };

  const DelContact = async (value) => {
    let result = await DeleteContactService(value);
    if (result.statusCode === 1) {
      if (param.state !== null) {
        loadCus(param.state.id);
      }
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Page className={(classes.root, clsx(className))}>
      <Box m={3}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/indexCus">
              ลูกค้า
            </Link>
            <Typography color="textPrimary">เพิ่มรายระเอียดลูกค้า</Typography>
          </Breadcrumbs>
        </Box>

        <Formik
          onSubmit={(values) => {
            SaveValue(values);
          }}
          enableReinitialize
          initialValues={{
            companyName: setCus.companyName != null ? setCus.companyName : "",
            address: setCus.address != null ? setCus.address : "",
            taxnum: setCus.taxnum != null ? setCus.taxnum : "",
            provinceCode:
              setCus.provinceCode != null ? parseInt(setCus.provinceCode) : "",
            provinceName: province
              .filter((x) => x.provinceCode == setCus.provinceCode)
              .map((z) => z.provinceName),
            districtName: province
              .filter((x) => x.provinceCode == setCus.provinceCode)
              .map((z) => z.districtName),
            subdistrictName: province
              .filter((x) => x.provinceCode == setCus.provinceCode)
              .map((z) => z.subdistrictName),
            postCode: province
              .filter((x) => x.provinceCode == setCus.provinceCode)
              .map((z) => z.postCode),
            customerContact:
              customerContact.length == 0
                ? [
                    {
                      custContactCode:
                        customerContact.custContactCode != null
                          ? customerContact.custContactCode
                          : 0,
                      firstName:
                        customerContact.firstName != null
                          ? customerContact.firstName
                          : "",
                      lastName:
                        customerContact.lastName != null
                          ? customerContact.lastName
                          : "",
                      mobile:
                        customerContact.mobile != null
                          ? customerContact.mobile
                          : "",
                      projectsCode:
                        customerContact.projectsCode != null
                          ? parseInt(customerContact.projectsCode)
                          : "",
                    },
                  ]
                : customerContact,
          }}
          validationSchema={CUSError}
        >
          {({
            errors,
            touched,
            handleBlur,
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <Box mt={3}>
                <Card className={classes.card}>
                  <CardHeader />
                  <Box display="flex" justifyContent="center">
                    <CardHeader />
                    <Typography color="textPrimary" variant="h3">
                      เพิ่มรายละเอียดลูกค้า
                    </Typography>
                  </Box>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={9} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.companyName == "" ? false : true,
                          }}
                          value={values.companyName}
                          size="small"
                          fullWidth
                          label="ชื่อบริษัท"
                          name="companyName"
                          error={Boolean(
                            touched.companyName && errors.companyName
                          )}
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.taxnum == "" ? false : true,
                          }}
                          value={values.taxnum}
                          size="small"
                          fullWidth
                          label="เลขประจำตัวผู้เสียภาษีอากร"
                          name="taxnum"
                          error={Boolean(touched.taxnum && errors.taxnum)}
                          helperText={touched.taxnum && errors.taxnum}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.address == "" ? false : true,
                          }}
                          value={values.address}
                          size="small"
                          fullWidth
                          label="บ้านเลขที่"
                          name="address"
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Autocomplete
                          size="small"
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              setFieldValue(
                                "provinceCode",
                                parseInt(newValue.provinceCode)
                              );
                              setFieldValue(
                                "subdistrictName",
                                newValue.subdistrictName
                              );
                              setFieldValue(
                                "districtName",
                                newValue.districtName
                              );
                              setFieldValue(
                                "provinceName",
                                newValue.provinceName
                              );
                              setFieldValue("postCode", newValue.postCode);
                            }
                          }}
                          options={province}
                          getOptionLabel={(option) =>
                            option.subdistrictName +
                            " " +
                            option.districtName +
                            " " +
                            option.provinceName +
                            " " +
                            option.postCode
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ค้นหาที่อยู่"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.subdistrictName == "" ? false : true,
                          }}
                          value={values.subdistrictName}
                          size="small"
                          fullWidth
                          label="ตำบล"
                          name="subdistrictName"
                          onChange={handleChange}
                          variant="outlined"
                          error={Boolean(
                            values.subdistrictName == ""
                              ? touched.subdistrictName &&
                                  errors.subdistrictName
                              : ""
                          )}
                          helperText={
                            values.subdistrictName == ""
                              ? touched.subdistrictName &&
                                errors.subdistrictName
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.districtName == "" ? false : true,
                          }}
                          value={values.districtName}
                          size="small"
                          fullWidth
                          label="อำเภอ"
                          name="districtName"
                          onChange={handleChange}
                          variant="outlined"
                          error={Boolean(
                            values.districtName == ""
                              ? touched.districtName && errors.districtName
                              : ""
                          )}
                          helperText={
                            values.districtName == ""
                              ? touched.districtName && errors.districtName
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.provinceName == "" ? false : true,
                          }}
                          value={values.provinceName}
                          size="small"
                          fullWidth
                          label="จังหวัด"
                          name="provinceName"
                          onChange={handleChange}
                          variant="outlined"
                          error={Boolean(
                            values.provinceName == ""
                              ? touched.provinceName && errors.provinceName
                              : ""
                          )}
                          helperText={
                            values.provinceName == ""
                              ? touched.provinceName && errors.provinceName
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: values.postCode == "" ? false : true,
                          }}
                          value={values.postCode}
                          size="small"
                          fullWidth
                          label="รหัสไปรษณีย์"
                          name="postCode"
                          onChange={handleChange}
                          variant="outlined"
                          error={Boolean(touched.postCode && errors.postCode)}
                          helperText={touched.postCode && errors.postCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={1}>
                <Card className={classes.card}>
                  <CardHeader />
                  <Box display="flex" justifyContent="start">
                    <CardHeader />
                    <Typography color="textPrimary" variant="h4">
                      รายการผู้ประสานงาน
                    </Typography>
                  </Box>
                  <CardContent>
                    <FieldArray
                      name="customerContact"
                      render={({ insert, remove, push }) => (
                        <Box>
                          {values.customerContact.length > 0 &&
                            values.customerContact.map((god, index) => (
                              <Box key={index}>
                                <Typography>รายการที่ {index + 1}</Typography>
                                <Grid container spacing={1}>
                                  <Grid item md={5} xs={12}>
                                    <TextField
                                      value={
                                        values.customerContact[index].firstName
                                      }
                                      fullWidth
                                      type="text"
                                      label="ชื่อจริง"
                                      autoComplete="off"
                                      variant="outlined"
                                      size="small"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`customerContact.${index}.firstName`}
                                      helperText={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index]
                                          .firstName &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .firstName && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .firstName
                                            }
                                          </div>
                                        )
                                      }
                                      error={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index]
                                          .firstName &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .firstName && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .firstName
                                            }
                                          </div>
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item md={5} xs={12}>
                                    <TextField
                                      value={
                                        values.customerContact[index].lastName
                                      }
                                      fullWidth
                                      type="text"
                                      label="นามสกุล"
                                      autoComplete="off"
                                      variant="outlined"
                                      size="small"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`customerContact.${index}.lastName`}
                                      helperText={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index]
                                          .lastName &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .lastName && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .lastName
                                            }
                                          </div>
                                        )
                                      }
                                      error={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index]
                                          .lastName &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .lastName && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .lastName
                                            }
                                          </div>
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item md={5} xs={12}>
                                    <TextField
                                      value={
                                        values.customerContact[index].mobile
                                      }
                                      fullWidth
                                      type="text"
                                      label="เบอร์โทรศัพท์"
                                      autoComplete="off"
                                      variant="outlined"
                                      size="small"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`customerContact.${index}.mobile`}
                                      helperText={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index].mobile &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .mobile && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .mobile
                                            }
                                          </div>
                                        )
                                      }
                                      error={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index].mobile &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .mobile && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .mobile
                                            }
                                          </div>
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item md={5} xs={12}>
                                    <TextField
                                      value={
                                        values.customerContact[index]
                                          .projectsCode
                                      }
                                      fullWidth
                                      type="text"
                                      label="ชื่อโปรเจค"
                                      autoComplete="off"
                                      variant="outlined"
                                      select
                                      size="small"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`customerContact.${index}.projectsCode`}
                                      onChange={handleChange}
                                      error={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index]
                                          .projectsCode &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .projectsCode && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .projectsCode
                                            }
                                          </div>
                                        )
                                      }
                                      helperText={
                                        errors &&
                                        errors.customerContact &&
                                        errors.customerContact[index] &&
                                        errors.customerContact[index]
                                          .projectsCode &&
                                        touched &&
                                        touched.customerContact &&
                                        touched.customerContact[index] &&
                                        touched.customerContact[index]
                                          .projectsCode && (
                                          <div
                                            className="field-error"
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors.customerContact[index]
                                                .projectsCode
                                            }
                                          </div>
                                        )
                                      }
                                    >
                                      {setProjectCode.map((item) => (
                                        <MenuItem
                                          key={item.projectsCode}
                                          value={item.projectsCode}
                                        >
                                          {item.projectsName}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={2}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        if (
                                          param.state !== null &&
                                          values.customerContact[index]
                                            .custContactCode != undefined
                                        ) {
                                          swal({
                                            className: "bg-modal-red",
                                            icon: "warning",
                                            dangerMode: true,
                                            text:
                                              "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                            buttons: {
                                              cancel: "ยกเลิก",
                                              confirm: {
                                                text: "ใช่",
                                                value:
                                                  values.customerContact[index]
                                                    .custContactCode,
                                              },
                                            },
                                          }).then((value) => {
                                            if (value !== null) {
                                              DelContact(value);
                                              remove(index);
                                            }
                                          });
                                        } else {
                                          swal({
                                            className: "bg-modal-red",
                                            icon: "warning",
                                            dangerMode: true,
                                            text:
                                              "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                            buttons: {
                                              cancel: "ยกเลิก",
                                              confirm: {
                                                text: "ใช่",
                                                value: 1,
                                              },
                                            },
                                          }).then((value) => {
                                            if (value != null) {
                                              swal({
                                                title: "สำเร็จ!",
                                                text: "",
                                                icon: "success",
                                                showConfirmButton: false,
                                                button: "ปิด",
                                                timer: 1500,
                                              });
                                              remove(index);
                                            }
                                          });
                                        }
                                      }}
                                      size="medium"
                                    >
                                      <IconDel />
                                    </Button>

                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        push({
                                          firstName:
                                            customerContact.firstName != null
                                              ? customerContact.firstName
                                              : null,
                                          lastName:
                                            customerContact.lastName != null
                                              ? customerContact.lastName
                                              : null,
                                          mobile:
                                            customerContact.mobile != null
                                              ? customerContact.mobile
                                              : null,

                                          customerCode:
                                            customerContact.customerCode != null
                                              ? customerContact.customerCode
                                              : null,
                                          projectsCode:
                                            customerContact.projectsCode != null
                                              ? customerContact.projectsCode
                                              : null,
                                        })
                                      }
                                      size="medium"
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))}
                          {values.customerContact.length === 0
                            ? push({
                                firstName:
                                  customerContact.firstName != null
                                    ? customerContact.firstName
                                    : null,
                                lastName:
                                  customerContact.lastName != null
                                    ? customerContact.lastName
                                    : null,
                                mobile:
                                  customerContact.mobile != null
                                    ? customerContact.mobile
                                    : null,
                                projectsCode:
                                  customerContact.projectsCode != null
                                    ? customerContact.projectsCode
                                    : null,
                              })
                            : ""}
                        </Box>
                      )}
                    />

                    <Box display="flex" justifyContent="center" p={2}>
                      <Box mr={3}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          startIcon={<IconSave />}
                          size="large"
                        >
                          บันทึก
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<IconCancel />}
                          size="large"
                          onClick={() => {
                            navigate("/app/customers", { replace: true });
                          }}
                        >
                          ยกเลิก
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

Formcustomer.propTypes = {
  className: PropTypes.string,
};

export default Formcustomer;
