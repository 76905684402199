import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import { max } from "moment";
import { GetDetailEmployeeService } from "../../../../services/services.Employee";
import Page from "../../../../components/Page";
import {
  Box,
  CardHeader,
  Card,
  Link,
  Container,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { province } from "../../../../Data/provinceData.json";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: max,
    marginLeft: 50,
    marginRight: 50,
  },
  detail: {
    width: 150,
  },
  media: {
    height: 0,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const DetailEmployee = () => {
  const classes = useStyles();
  const [setEmp, SetEmp] = useState({});
  const navigate = useNavigate();
  const param = useLocation();
  const [state, setstate] = useState();

  async function loadEmps(param) {
    const resultLeave = await GetDetailEmployeeService(param);
    SetEmp(resultLeave.data);
  }
  useEffect(() => {
    if (param.state !== null) {
      loadEmps(param.state.id);
    }
  }, []);

  /////
  // const result = province.find((val) => {
  //   return val.provinceCode == setEmp.addressCode;
  // });
  // if (result) {
  //   alert(`${result.provinceName}`);
  // }

  ////
  return (
    <Page className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/employee">
              หน้าแรก
            </Link>

            <p color="inherit">รายละเอียดงาน</p>
          </Breadcrumbs>
        </Box>

        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <CardContent>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h2" component="h2">
                        {"แผนก :   " + " " + setEmp.departmentName}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} p={3}>
                      <Typography variant="h4" component="h2">
                        {"เริ่มวันที่ : " + setEmp.startDate}
                      </Typography>
                    </Grid>
                    <CardHeader />
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.detail}
                                  variant="h5"
                                  component="h2"
                                >
                                  หัวข้อหลัก
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={classes.detail}>
                                  รายละเอียดงาน
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  ชื่อพนักงาน :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {setEmp.empCode +
                                    " " +
                                    setEmp.titleName +
                                    " " +
                                    setEmp.firstName +
                                    " " +
                                    setEmp.lastName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  เลขบัตรประชน :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.idcardnumber}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  วัน/เดือน/ปีเกิด :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.birthday}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  ที่อยู่ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                              <Typography>
                                {setEmp.address + " "}
                                {province
                                  .filter(
                                    (x) => x.provinceCode == setEmp.addressCode
                                  )
                                  .map(
                                    (z) =>
                                      "จังหวัด" +
                                      " " +
                                      z.provinceName +
                                      " " +
                                      "อำเภอ" +
                                      " " +
                                      z.districtName +
                                      " " +
                                      "ตำบล" +
                                      " " +
                                      z.subdistrictName
                                      + " " + "รหัสไปรษณีย์" + " " + 
                                      z.postCode 
                                  )}
                              </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  แผนก :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {setEmp.departmentCode == "1"
                                    ? "Manager"
                                    : setEmp.departmentCode == "2"
                                    ? "Service"
                                    : "Analysis"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  ตำแหน่ง :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.positionName}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  อีเมลล์ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.email}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  เบอร์โทรศัพท์ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.phoneNumber}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  เลขบัญชี :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.numAccount}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  ชื่อธนาคาร :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.bankName}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  เงินเดือน :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setEmp.salary}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </CardContent>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate("/app/employee", {
                          replace: true,
                        });
                      }}
                      size="large"
                    >
                      ย้อนกลับ
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};
export default DetailEmployee;
