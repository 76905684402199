import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

//แสดงข้อมูลการลา
export async function GetLeavesService(search, pageSize, leaveStatus, leavetypeCode, currentPage, date, since, to, empCode) {
  try {
    const response = await Instance.get(
      URL +
        "Leaves?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&leavetypeCode=" +
        leavetypeCode +
        "&currentPage=" +
        currentPage +
        "&leaveStatus=" +
        leaveStatus +
        "&date=" +
        date +
        "&since=" +
        since +
        "&to=" +
        to +
        "&empCode=" +
        empCode +
        "&role=" +
        "1"
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//แสดงข้อมูลการลา Admin
export async function GetLeavesServiceAdmin(search, pageSize, leaveStatus, leavetypeCode, currentPage, date, since, to, empCode, role) {
  try {
    const response = await Instance.get(
      URL +
        "Leaves?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&leavetypeCode=" +
        leavetypeCode +
        "&currentPage=" +
        currentPage +
        "&leaveStatus=" +
        leaveStatus +
        "&date=" +
        date +
        "&since=" +
        since +
        "&to=" +
        to +
        "&empCode=" +
        empCode +
        "&role=" +
        role
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงรายละเอียดการลางาน
export async function GetDetailLeavesService(id) {
  try {
    const response = await Instance.get(URL + "Leaves/GetDetail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//โหลดวันที่ภาษาไทย
export async function LoadDataLeavesService(id) {
  try {
    const response = await Instance.get(URL + "Leaves/LoadData?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงข้อมูลค่าใช้จ่าย dropdown
export async function GetDataLeavesService() {
  try {
    const response = await Instance.get(URL + "Leaves/GetData");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//บันทึกข้อมูล การลา
export async function SaveLeavesService(values) {
  try {
    let formdata = new FormData();
    formdata.append("EmpCode", values.empCode);
    formdata.append("LeavetypeCode", values.leavetypeCode);
    formdata.append("StartDate", values.startDate);
    formdata.append("EndDate", values.endDate);
    formdata.append("Peirod", values.peirod);
    formdata.append("LeaveReason", values.leaveReason);
    //
    formdata.append("LeaveStatus", values.leaveStatus);
    formdata.append("Reason", values.reason);
    formdata.append("ApprovalDate ", values.approvalDate);
    formdata.append("CreatedDate ", values.createdDate);
    const response = await Instance.post(URL + "Leaves/Save", formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//อัพเดพข้อมูล
export async function UpdateLeavesService(values, data) {
  try {
    let formdata = new FormData();
    formdata.append("EmpCode", data.empCode);
    formdata.append("LeavetypeCode", data.leavetypeCode);
    formdata.append("StartDate", data.startDate);
    formdata.append("EndDate", data.endDate);
    formdata.append("Peirod", data.peirod);
    formdata.append("LeaveReason", data.leaveReason);
    //
    formdata.append("LeaveStatus", data.leaveStatus);
    formdata.append("Reason", data.reason);
    formdata.append("ApprovalDate", data.approvalDate);
    formdata.append("CreatedDate", data.createdDate);

    const response = await Instance.put(URL + "Leaves/Update/" + values, formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//ลบข้อมูลการลา
export async function DeleteLeavesService(id) {
  try {
    const response = await Instance.delete(URL + "Leaves/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
