import jwtDecode from "jwt-decode";

var initialState = {
  empCode: localStorage.getItem("empCode") === undefined ? "0" : localStorage.getItem("empCode"),
  email: localStorage.getItem("email") === undefined ? "0" : localStorage.getItem("email"),
  tasks: localStorage.getItem("tasks") === undefined ? "0" : localStorage.getItem("tasks"),
  level: localStorage.getItem("level") === undefined ? "0" : localStorage.getItem("level"),
  finance: localStorage.getItem("finance") === undefined ? "0" : localStorage.getItem("finance"),
  setting: localStorage.getItem("setting") === undefined ? "0" : localStorage.getItem("setting"),
  exp: localStorage.getItem("exp") === undefined ? "0" : localStorage.getItem("exp"),
  img: localStorage.getItem("img") === undefined ? "0" : localStorage.getItem("img"),
  depart: localStorage.getItem("depart") === undefined ? "" : localStorage.getItem("depart"),
  position: localStorage.getItem("position") === undefined ? "" : localStorage.getItem("position"),
  name: localStorage.getItem("name") === undefined ? "" : localStorage.getItem("name"),
  token: localStorage.getItem("token"),
};

export default async function Authentication(state = initialState, action) {
  switch (action.type) {
    case "AUTHEN":
      localStorage.setItem("token", action.token);
      let user = jwtDecode(action.token);
      localStorage.setItem("empCode", user.sub);
      localStorage.setItem("email", user.email);
      localStorage.setItem("tasks", user.tasks);
      localStorage.setItem("level", user.level);
      localStorage.setItem("finance", user.finance);
      localStorage.setItem("setting", user.setting);
      localStorage.setItem("exp", user.exp);
      localStorage.setItem("img", user.img);
      localStorage.setItem("depart", user.depart);
      localStorage.setItem("position", user.position);
      localStorage.setItem("name", user.name);

      return {
        ...state,
        empCode: localStorage.getItem("empCode"),
        email: localStorage.getItem("email"),
        tasks: localStorage.getItem("tasks"),
        level: localStorage.getItem("level"),
        finance: localStorage.getItem("finance"),
        setting: localStorage.getItem("setting"),
        exp: localStorage.getItem("exp"),
        img: localStorage.getItem("img"),
        depart: localStorage.getItem("depart"),
        position: localStorage.getItem("position"),
        token: localStorage.getItem("token"),
        name: localStorage.getItem("name"),
      };
    case "UAUTHEN":
      localStorage.removeItem("token");
      localStorage.removeItem("empCode");
      localStorage.removeItem("email");
      localStorage.removeItem("tasks");
      localStorage.removeItem("level");
      localStorage.removeItem("finance");
      localStorage.removeItem("setting");
      localStorage.removeItem("exp");
      localStorage.removeItem("img");
      localStorage.removeItem("depart");
      localStorage.removeItem("position");
      localStorage.removeItem("name");

      return {
        ...state,
        empCode: localStorage.getItem("empCode"),
        email: localStorage.getItem("email"),
        tasks: localStorage.getItem("tasks"),
        level: localStorage.getItem("level"),
        finance: localStorage.getItem("finance"),
        setting: localStorage.getItem("setting"),
        exp: localStorage.getItem("exp"),
        img: localStorage.getItem("img"),
        depart: localStorage.getItem("depart"),
        position: localStorage.getItem("position"),
        token: localStorage.getItem("token"),
        name: localStorage.getItem("name"),
      };
    case "USERINFO":
      return {
        ...state,
        empCode: localStorage.getItem("empCode"),
        email: localStorage.getItem("email"),
        tasks: localStorage.getItem("tasks"),
        level: localStorage.getItem("level"),
        finance: localStorage.getItem("finance"),
        setting: localStorage.getItem("setting"),
        exp: localStorage.getItem("exp"),
        img: localStorage.getItem("img"),
        depart: localStorage.getItem("depart"),
        position: localStorage.getItem("position"),
        token: localStorage.getItem("token"),
        name: localStorage.getItem("name"),
      };
    default:
      return state;
  }
}
