import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

export async function GetQuotationService(
  search,
  pageSize,
  currentPage,
  date,
  since,
  to
) {
  try {
    const response = await Instance.get(
      URL +
        "Quotation?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&since=" +
        since +
        "&to=" +
        to
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetDataQuoitemService() {
  try {
    const response = await Instance.get(URL + "Quotation/GetDataQuoitem");
    console.log(response);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetDataCusService() {
  try {
    const response = await Instance.get(URL + "Quotation/GetData");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function LoadDataQuotationService(id) {
  try {
    const response = await Instance.get(URL + "Quotation/LoadData?id=" + id);

    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function GetDetailQuatationService(id) {
  try {
    const response = await Instance.get(URL + "Quotation/GetDetail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function SaveQuotationService(values) {
  try {
    const response = await Instance.post(URL + "Quotation/Save", values);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateQuotationService(id, values, data) {
  try {
    const response = await Instance.put(
      URL + "Quotation/Update/" + id,
      values,
      data
    );

    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function DeleteQuotationService(id) {
  try {
    const response = await Instance.put(URL + "Quotation/Delete/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function DeleteQuoitemService(id) {
  try {
    const response = await Instance.put(
      URL + "Quotation/Delete/Quoitem/" + id + "/"
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
