import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableContainer from "@material-ui/core/TableContainer";
import {
  Box,
  TextField,
  CardHeader,
  Typography,
  Grid,
  Card,
  MenuItem,
  Button,
  Table,
  TableBody,
  Avatar,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import IconDel from "../../icons/Del";
import Detail from "../../icons/Detail";
import IconEdit from "../../icons/Edit";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { pageSize } from "../../Data/pageSize.json";
import { DeleteSalaryService } from "../../services/salary.services";


const useStyles = makeStyles(() => ({
  buttonwiring: {
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
  income: {
    color: "#228B22",
   },
   cost: {
     color: "#B22222",
    },
  buttoninfo: {
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
}));

const ShowdataSalary = ({ className, fetchData, salary, setpagin, changPageSize, changCurrentPage, currentPage, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const Del = async (value) => {

    let result = await DeleteSalaryService(value);
    if (result.statusCode === 1) {
      fetchData();
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  }

  const handleChangePage = (event, newPage) => {
    changCurrentPage(newPage);
};

  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TableContainer>
            <Table>
              <TableHead className="thead-font">
                <TableRow>
                  <TableCell>ลำดับ</TableCell>
                  <TableCell>ประจำเดือนที่</TableCell>
                  <TableCell>วันที่จ่ายเงินเดือน</TableCell>
                  <TableCell>ชื่อพนักงาน</TableCell>
                  <TableCell>เงินเดือน(บาท)</TableCell>
                  <TableCell>รายได้อื่นๆ(บาท)</TableCell>
                  <TableCell>รายจ่ายอื่นๆ(บาท)</TableCell>
                  <TableCell>ยอดรวมสุทธิ(บาท)</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salary.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{((setpagin.currentPage - 1) * setpagin.pageSize + (index + 1))}</TableCell>
                    <TableCell>{item.salaryIn}</TableCell>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.empCode}</TableCell>
                    <TableCell>{item.salary}</TableCell>
                    <TableCell className={classes.income}>{item.totalIncome}</TableCell>
                    <TableCell className={classes.cost}>{item.totalCost}</TableCell>
                    <TableCell>{item.netSalary}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="center">
                        <ButtonGroup variant="contained">
                          <Button
                            className={classes.buttonwiring}
                            size="medium"
                            onClick={(e) => {
                              navigate(
                                "/app/formSalary",
                                { state: { id: item.salaryCode } },
                                { replace: true }
                              );
                            }}
                          >
                            <IconEdit />
                          </Button>
                          <Button
                            className={classes.buttoninfo}
                            size="medium"
                            onClick={(e) => {
                              navigate("/app/detailSalary", { state: { id: item.salaryCode } }, { replace: true });
                            }}
                          >
                            <Detail />
                          </Button>
                          <Button
                            color="secondary"
                            size="medium"
                            onClick={(e) => {
                              swal({
                                className: "bg-modal-red",
                                icon: "warning",
                                dangerMode: true,
                                text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                buttons: {
                                  cancel: "ยกเลิก",
                                  confirm: {
                                    text: "ใช่",
                                    value: item.salaryCode,
                                  },
                                },
                              }).then((value) => {
                                if (value !== null) {
                                    Del(value);
                                }
                              });
                            }}
                          >
                            <IconDel />
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <CardHeader />

      <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Box>
          {setpagin.rowCount == 0 ? (
            <Box display="flex" justifyContent="center">
              <Typography color="Error"> "ไม่พบข้อมูล&nbsp;!" </Typography>
            </Box>) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>&nbsp; ข้อมูล : &nbsp;{setpagin.rowCount}&nbsp; รายการ</Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                        //   changPageSize(e.target.value);

                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}> ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination
                      count={setpagin.pageCount}
                      page={currentPage}
                      onChange={handleChangePage}
                      color="primary"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
        </Box>
      </Grid>
    </Grid>
      <CardHeader />
    </Card>
  );
};

export default ShowdataSalary;
