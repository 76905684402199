import React from "react";
import IconDel from "../../icons/Del";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import ED from "../../icons/Edite";
import DT from "../../icons/Detail";
import swal from "sweetalert";
import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate } from "react-router-dom";
import { DeleteQuotationService } from "../../services/services.Qoutation";
import { pageSize } from "../../Data/pageSize.json";
import {
  CardHeader,
  Box,
  Card,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableRow,
  Typography,
  Button,
  MenuItem,
  ButtonGroup,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

const CardQuotation = ({
  className,
  quotation,
  setpagin,
  fetchData,
  changPageSize,
  changCurrentPage,
  currentPage,
  ...rest
}) => {
  const handleChangePage = (event, newPage) => {
    changCurrentPage(newPage);
  };
  const navigate = useNavigate();

  const Del = async (value) => {
    let result = await DeleteQuotationService(value);
    if (result.statusCode === 1) {
      fetchData();
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  const useStyles = makeStyles(() => ({
    buttonsuccess: {
      backgroundColor: "#4caf50",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    buttonwiring: {
      backgroundColor: "#ff9800",
      "&:hover": {
        backgroundColor: "#f57c00",
      },
    },
    buttoninfo: {
      backgroundColor: "#2196f3",
      "&:hover": {
        backgroundColor: "#1976d2",
      },
    },
  }));

  const classes = useStyles();
  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead className="thead-font">
              <TableRow>
                <TableCell>ลำดับ</TableCell>
                <TableCell>เลขที่ </TableCell>
                <TableCell>วันที่ทำรายการ </TableCell>
                <TableCell>ชื่อบริษัท</TableCell>
                <TableCell>จำนวนเงิน(บาท)</TableCell>
                <TableCell>ยอดรวมสุทธิ(บาท)</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotation.map((item, index) => (
                <TableRow hover key={item.quotationCode}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.quotationCode}</TableCell>
                  <TableCell>{item.quotationDate}</TableCell>
                  <TableCell>{item.companyName} </TableCell>

                  <TableCell>{item.totalPrice}</TableCell>
                  <TableCell>{item.netPrice}</TableCell>
                  <TableCell>
                    <ButtonGroup
                      variant="contained"
                      aria-label="contained primary button group"
                      justify="flex-end"
                    >
                      <Tooltip title="แก้ไข" arrow>
                        <Button
                          className={classes.buttonwiring}
                          size="medium"
                          onClick={(e) => {
                            navigate(
                              "/app/insertQuotation",
                              { state: { id: item.quotationCode } },
                              { replace: true }
                            );
                          }}
                        >
                          <ED />
                        </Button>
                      </Tooltip>
                      <Tooltip title="รายละเอียด" arrow>
                        <Button
                          className={classes.buttoninfo}
                          size="medium"
                          onClick={(e) => {
                            navigate(
                              "/app/printQuotation",
                              { state: { id: item.quotationCode } },
                              { replace: true }
                            );
                          }}
                        >
                          <DT />
                        </Button>
                      </Tooltip>
                      <Tooltip title="ลบ" arrow>
                        <Button
                          color="secondary"
                          size="medium"
                          onClick={(e) => {
                            swal({
                              className: "bg-modal-red",
                              icon: "warning",
                              dangerMode: true,
                              text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                              buttons: {
                                cancel: "ยกเลิก",
                                confirm: {
                                  text: "ใช่",
                                  value: item.quotationCode,
                                },
                              },
                            }).then((value) => {
                              if (value !== null) {
                                Del(value);
                              }
                            });
                          }}
                        >
                          <IconDel />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <CardHeader />

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box>
            {setpagin.rowCount === 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography color="Error"> "ไม่พบข้อมูล&nbsp;!" </Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography>
                      &nbsp; ข้อมูล : &nbsp;{setpagin.rowCount}&nbsp; รายการ
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Typography>แถวต่อหน้า : &nbsp;</Typography>
                      <TextField
                        defaultValue={10}
                        select
                        size="small"
                        onChange={(e) => {
                          changPageSize(e.target.value);
                        }}
                      >
                        {pageSize.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1}> ทั้งหมด</MenuItem>
                      </TextField>
                    </Box>

                    <Pagination
                      count={setpagin.pageCount}
                      page={currentPage}
                      onChange={handleChangePage}
                      color="primary"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <CardHeader />
    </Card>
  );
};

CardQuotation.propTypes = {
  className: PropTypes.string,
  quotation: PropTypes.array.isRequired,
};

export default CardQuotation;
