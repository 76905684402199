import React, { useState, useEffect } from "react";
import IconSave from "../../../icons/Save";
import IconCancel from "../../../icons/Cancel";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  makeStyles,
  Box,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Page from "../../../components/Page";
import { Formik, Form, ErrorMessage } from "formik";
import IconButton from "@material-ui/core/IconButton";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { GetDataLeavesService } from "../../../services/leaves.serervice";
import { SaveLeavesService } from "../../../services/leaves.serervice";
import { UpdateLeavesService } from "../../../services/leaves.serervice";
import swal from "sweetalert";
import CKEditor from "ckeditor4-react";
import dateFormat from "dateformat";
import { tyleleave, peirod } from "../../../Data/leaveData.json";
import { LoadDataLeavesService } from "../../../services/leaves.serervice";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  input: {
    display: "none",
  },
}));
const LAD = Yup.object().shape({
  leavetypeCode: Yup.string().required("กรุณาเลือก ประเภทงาน"),
  startDate: Yup.date().required("กรุณาเลือก เริ่มวันที่การลา"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "กรุณาเลือก วันที่สิ้นสุดการลาให้มากกว่า")
    .required("กรุณาเลือก วันที่สิ้นสุดการลา"),
  peirodCode: Yup.string().required("กรุณาเลือก ช่วงเวลา"),
  empCode: Yup.string().required("กรุณาเลือก รหัส,ชือ-นามสกุล "),
  leaveReason: Yup.string().required("กรุณากรอก เหตุผลการลา"),
});

const FormLeave = ({ className, fetchDatas, ...rest }) => {
  const classes = useStyles();
  const [setEmpCode, SetEmpCode] = useState([]);
  const [setLe, SetLe] = useState({});
  const navigate = useNavigate();
  const param = useLocation();
  const [loading, SetLoading] = useState(true);

  useEffect(() => {
    if (param.state !== null) {
      loadLeaves(param.state.id);
    }
  }, []);

  async function loadLeaves(param) {
    const resultLeaves = await LoadDataLeavesService(param);
    SetLe(resultLeaves.data);
  }

  useEffect(() => {
    fetchLeaves();
  }, []);
  async function fetchLeaves() {
    const result = await GetDataLeavesService();
    SetEmpCode(result.emp);
    SetLoading(false);
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateLeavesService(param.state.id, values);
      console.log("update", resultUpdate);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/Leave", { replace: true });
        } else {
          alert(resultUpdate.mgs);
          console.log(resultUpdate);
        }
      }
    } else {
      let result = await SaveLeavesService(values);
      console.log(result);
      if (result !== null) {
        if (result.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/Leave", { replace: true });
        } else {
          alert(result.mgs);
          console.log(result);
        }
      }
    }
  };

  return (
    <Page className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/leave">
              ประวัติการลา
            </Link>
            <Link color="textPrimary">เพิ่มรายละเอียดการลางาน</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardHeader />
            <Box display="flex" justifyContent="center">
              <CardHeader />
              <Typography color="textPrimary" variant="h2">
                ข้อมูลการลางาน
              </Typography>
            </Box>
            <CardContent>
              <Formik
                onSubmit={(values) => {
                  console.log("Savevalue", values);
                  SaveValue({
                    empCode: values.empCode,
                    leavetypeCode: values.leavetypeCode,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    peirod: values.peirodCode != null ? values.peirodCode : "-",
                    leaveReason:
                      values.leaveReason != null ? values.leaveReason : "",
                    //
                    leaveStatus:
                      values.leaveStatus != null ? values.leaveStatus : "",
                    reason: values.reason != null ? values.reason : "",
                    createdDate:
                      values.createdDate != null ? values.createdDate : "-",
                    approvalDate:
                      values.approvalDate != null ? values.approvalDate : "-",
                  });
                }}
                enableReinitialize
                initialValues={{
                  empCode: setLe.empCode != null ? setLe.empCode : "",
                  leavetypeCode:
                    setLe.leavetypeCode != null
                      ? parseInt(setLe.leavetypeCode)
                      : "",
                  startDate:
                    setLe.startDate != null
                      ? dateFormat(setLe.startDate, "yyyy-mm-dd")
                      : "",
                  endDate:
                    setLe.endDate != null
                      ? dateFormat(setLe.endDate, "yyyy-mm-dd")
                      : "",
                  peirodCode: setLe.peirod != null ? setLe.peirod : "",
                  leaveReason:
                    setLe.leaveReason != null ? setLe.leaveReason : "",
                  //

                  leaveStatus:
                    setLe.leaveStatus != null ? setLe.leaveStatus : "",
                  reason: setLe.reason != null ? setLe.reason : "-",
                  approvalDate:
                    setLe.approvalDate != null
                      ? dateFormat(setLe.approvalDate, "yyyy-mm-dd")
                      : "",
                  createdDate:
                    setLe.createdDate != null
                      ? dateFormat(setLe.createdDate, "yyyy-mm-dd")
                      : "",
                }}
                validationSchema={LAD}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  values,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form>
                    <Grid container spacing={5}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          defaultValue={values.empCode}
                          value={values.empCode}
                          size="small"
                          fullWidth
                          label="รหัสพนักงาน,ชื่อ-นามสกุล"
                          name="empCode"
                          variant="outlined"
                          select
                          error={Boolean(touched.empCode && errors.empCode)}
                          helperText={touched.empCode && errors.empCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {setEmpCode.map((item) => (
                            <MenuItem key={item.empCode} value={item.empCode}>
                              {item.empCode}&nbsp;
                              {item.titleName +
                                " " +
                                item.firstName +
                                " " +
                                item.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          defaultValue={values.leavetypeCode}
                          value={values.leavetypeCode}
                          size="small"
                          label=" ประเภทการลา"
                          name="leavetypeCode"
                          onChange={(e) =>
                            setFieldValue("leavetypeCode", e.target.value)
                          }
                          variant="outlined"
                          fullWidth
                          select
                          error={Boolean(
                            touched.leavetypeCode && errors.leavetypeCode
                          )}
                          helperText={
                            touched.leavetypeCode && errors.leavetypeCode
                          }
                          onBlur={handleBlur}
                        >
                          {tyleleave.map((item, pass) => (
                            <MenuItem
                              key={item.leavetypeCode}
                              value={item.leavetypeCode}
                            >
                              {item.leavetypeName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={1} xs={12}>
                        <Box mt={-2}>
                          <IconButton
                            aria-label="livehelp"
                            className={classes.margin}
                            onClick={handleClickOpen}
                          >
                            <LiveHelpIcon />
                          </IconButton>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <TextField
                              defaultValue={values.leavetypeCode}
                              select
                              disabled
                            >
                              {tyleleave.map((item) => (
                                <div
                                  key={item.leavetypeCode}
                                  value={item.leavetypeCode}
                                >
                                  <DialogTitle style={{ color: "red" }}>
                                    {" " + item.leavetypeName}
                                  </DialogTitle>
                                  <DialogContent style={{ color: "black" }}>
                                    {item.leavetypeDetail}
                                  </DialogContent>
                                </div>
                              ))}
                            </TextField>

                            <DialogActions>
                              <Button
                                onClick={handleClose}
                                color="primary"
                                autoFocus
                              >
                                เข้าใจ
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          defaultValue={values.startDate}
                          value={values.startDate}
                          id="date"
                          label="วันที่เริ่มลางาน "
                          type="date"
                          fullWidth
                          variant="outlined"
                          name="startDate"
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            setFieldValue("startDate", e.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <TextField
                          defaultValue={values.endDate}
                          value={values.endDate}
                          id="date"
                          label="วันที่สิ้นสุดลางาน "
                          type="date"
                          fullWidth
                          variant="outlined"
                          name="endDate"
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            setFieldValue("endDate", e.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          defaultValue={values.peirodCode}
                          value={values.peirodCode}
                          size="small"
                          label=" ช่วงเวลา"
                          name="peirodCode"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          select
                          error={Boolean(
                            touched.peirodCode && errors.peirodCode
                          )}
                          helperText={touched.peirodCode && errors.peirodCode}
                          onBlur={handleBlur}
                        >
                          {peirod.map((values) => (
                            <MenuItem
                              key={values.peirodCode}
                              value={values.peirodCode}
                            >
                              {values.peirodName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography
                          className={
                            errors.leaveReason && touched.leaveReason
                              ? "input-feedback"
                              : ""
                          }
                        >
                          เหตุผลการลา
                        </Typography>
                        {loading ? (
                          <></>
                        ) : (
                          <div
                            className={
                              errors.leaveReason && touched.leaveReason
                                ? "border"
                                : ""
                            }
                          >
                            <CKEditor
                              className={
                                errors.leaveReason && touched.leaveReason
                                  ? "text-input error"
                                  : ""
                              }
                              helperText={
                                touched.leaveReason && errors.leaveReason
                              }
                              name="leaveReason"
                              data={values.leaveReason}
                              type="text"
                              onChange={(e) => {
                                setFieldValue(
                                  "leaveReason",
                                  e.editor.getData()
                                );
                              }}
                              config={{}}
                              onBeforeLoad={(CKEDITOR) => {
                                CKEDITOR.disableAutoInline = true;
                                CKEDITOR.config.autoParagraph = false;
                              }}
                            />
                          </div>
                        )}
                        <ErrorMessage
                          component="div"
                          name="leaveReason"
                          className="input-feedback "
                        />
                      </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" p={5}>
                      <Box mr={3}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          startIcon={<IconSave />}
                          size="large"
                        >
                          บันทึก
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<IconCancel />}
                          onClick={() => {
                            navigate("/app/Leave", { replace: true });
                          }}
                          size="large"
                        >
                          ยกเลิก
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default FormLeave;
