import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import { max } from "moment";
import { GetDetailCustomerService } from "../../../services/customer.services";
import Page from "../../../components/Page";
import {
  Box,
  CardHeader,
  Card,
  Link,
  Container,
  CardContent,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PerfectScrollbar from "react-perfect-scrollbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { province } from "../../../Data/provinceData.json";
import { LoadDataCustomerService } from "../../../services/customer.services";
import { func } from "prop-types";
import { LineWeightOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  media: {
    height: 0,
  },
  detail: {
    width: 150,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const DetailCustomer = () => {
  const classes = useStyles();
  const [setCus, SetCus] = useState({});
  const [setCon, SetCon] = useState([{}]);

  const navigate = useNavigate();
  const param = useLocation();

  async function loadCus(param) {
    const resultC = await GetDetailCustomerService(param);
    SetCus(resultC.cus);
    SetCon(resultC.cusContacts);
  }
  useEffect(() => {
    if (param.state !== null) {
      loadCus(param.state.id);
    }
  }, []);

  return (
    <Page className={classes.root} noValidate>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/customers">
              หน้าแรก
            </Link>
            <p color="inherit">รายละเอียดลูกค้า</p>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <CardContent>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h2" component="h2">
                        {"บริษัท :   " + " " + setCus.companyName}
                      </Typography>
                    </Grid>
                    <CardHeader />
                    <Card>
                      <PerfectScrollbar>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  className={classes.detail}
                                >
                                  หัวข้อหลัก
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography style={{ width: "500px" }}>
                                  รายละเอียดงาน
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  เลขที่ผู้เสียภาษี :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{setCus.taxnum}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  ที่อยู่ :
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {setCus.address + " "}
                                  {province
                                    .filter(
                                      (x) =>
                                        x.provinceCode == setCus.provinceCode
                                    )
                                    .map(
                                      (z) =>
                                        "จังหวัด" +
                                        " " +
                                        z.provinceName +
                                        " " +
                                        "อำเภอ" +
                                        " " +
                                        z.districtName +
                                        " " +
                                        "ตำบล" +
                                        " " +
                                        z.subdistrictName
                                    )}
                                </Typography>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography variant="h5" component="h2">
                                  ผู้ประสานงาน*
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Grid md={6} item container spacing={1}>
                                  {setCon.map((item) => (
                                    <>
                                      <Grid item md={4} xs={4}>
                                        <Typography variant="h5" component="h2">
                                          ชื่อ-นามสกุล :
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} xs={8}>
                                        <Typography>
                                          {" "}
                                          {item.firstName + " " + item.lastName}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} xs={4}>
                                        <Typography variant="h5" component="h2">
                                          หมายเลขโทรศัพท์ :
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} xs={8}>
                                        <Typography>{item.mobile}</Typography>
                                      </Grid>

                                      <Grid item md={4} xs={4}>
                                        <Typography variant="h5" component="h2">
                                          ชื่อโปรเจค :
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} xs={8}>
                                        <Typography>
                                          {" "}
                                          {item.projectsName}
                                        </Typography>
                                      </Grid>
                                    </>
                                  ))}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </PerfectScrollbar>
                    </Card>
                    <CardHeader />
                  </CardContent>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate("/app/customers ", {
                          replace: true,
                        });
                      }}
                      size="large"
                    >
                      ย้อนกลับ
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default DetailCustomer;
