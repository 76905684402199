import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import NewLogo from "../../components/Newlogo";
import NewLogotwo from "../../assets/images/BLUE_LOGO-removebg-preview.png";

import {
  Box,
  Button,
  Card,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  CardContent,
  Grid,
} from "@material-ui/core";
import Page from "../../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Register">
      <Grid md={12}>
        <Box md={12} position=" absolute" right="20px" width="585px">
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                email: "",
                firstName: "",
                lastName: "",
                password: "",
                confirm: "",
                policy: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("กรุณากรอก อีเมล หรือ เบอร์โทรศัพท์"),
                firstName: Yup.string()
                  .max(255)
                  .required("กรุณากรอก ชื่อ"),
                lastName: Yup.string()
                  .max(255)
                  .required("กรุณากรอก นามสกุล"),
                password: Yup.string()
                  .max(255)
                  .required("กรุณากรอก รหัสผ่านใหม่"),

                confirm: Yup.string()
                  .max(255)
                  .required("กรุณากรอก ยืนยันรหัสผ่าน")
                  .oneOf([Yup.ref("password")], "กรุณาตรวจสอบรหัสผ่าน"),
              })}
              onSubmit={() => {
                navigate("/login", { replace: true });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <body>
                    <Box>
                      <Box display="flex" justifyContent="center" p={2}>
                        <NewLogo />
                      </Box>

                      {/* <Box mb={3}> */}
                      {/* <Typography color="textPrimary" variant="h2">
                      เปลี่ยนรหัสผ่านของคุณ
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Change your password
                    </Typography> */}
                      {/* </Box> */}

                      <Card>
                        <CardContent>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="อีเมล"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={values.email}
                            variant="outlined"
                          />

                          <Box alignItems="center" display="flex" ml={-1}>
                            {/* <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  /> */}
                            {/* <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography> */}
                          </Box>
                          {Boolean(touched.policy && errors.policy) && (
                            <FormHelperText error>
                              {errors.policy}
                            </FormHelperText>
                          )}
                          <Box my={2}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              ตกลง
                            </Button>
                          </Box>
                          <Typography color="textSecondary" variant="body1">
                            คุณมีบัญชีแล้ว{" "}
                            <Link
                              component={RouterLink}
                              to="/login"
                              variant="h6"
                            >
                              Login
                            </Link>
                          </Typography>
                        </CardContent>
                      </Card>

                      <section class="front-page layer">
                        <img src={NewLogotwo} className="App-logo" alt="logo" />
                      </section>
                    </Box>
                  </body>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Grid>
    </Page>
  );
};

export default RegisterView;
