import React, { useState, useEffect } from "react";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Formik, Form } from "formik";
import swal from "sweetalert";
import { UpdateTaskService, LoadNewTaskService, GetDetailTaskService } from "../../services/serviceTask";
import {
  Box,
  CardHeader,
  Button,
  Card,
  Link,
  Container,
  CardContent,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Page from "../../components/Page";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  buttonsuccess: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
}));

const Detaildev = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const param = useLocation();
  const [setTask, SetTask] = useState({});
  const [setDetail, Setdetail] = useState({});
  console.log("setDetail", setDetail);
  console.log("setTask", setTask);
  async function loadTask(param) {
    const resultTask = await LoadNewTaskService(param);
    SetTask(resultTask.data);
  }

  async function loadDetail(param) {
    const resultTask = await GetDetailTaskService(param);
    Setdetail(resultTask.data);
  }

  useEffect(() => {
    if (param.state !== null) {
      loadTask(param.state.id);
      loadDetail(param.state.id);
    }
  }, []);

  const SaveValue = async (values) => {
    if (param.state !== null) {
      let resultUpdate = await UpdateTaskService(param.state.id, values);
      if (resultUpdate !== null) {
        if (resultUpdate.statusCode === 1) {
          swal({
            title: "สำเร็จ!",
            text: "",
            icon: "success",
            button: "ปิด",
          });
          navigate("/app/Developer", { replace: true });
        } else {
          alert(resultUpdate.mgs);
        }
      }
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/app/Developer">
              รายการงานที่ได้รับหมอบหมาย
            </Link>
            <Link color="inherit">รายละเอียดงานที่ได้รับหมอบหมาย</Link>
          </Breadcrumbs>
        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Formik
                onSubmit={(values) => {
                  SaveValue({
                    taskprojectsCode: parseInt(values.taskprojectsCode),
                    taskcustomerCode: values.taskcustomerCode,
                    tasktypeCode: parseInt(values.tasktypeCode),
                    taskstatusCode: parseInt(values.taskstatusCode),
                    taskImportanceCode: parseInt(values.taskImportanceCode),
                    taskempCode: values.taskempCode,
                    taskcreatedBy: parseInt(values.taskcreatedBy) != null ? parseInt(values.taskcreatedBy) : "",
                    notes: values.notes,
                    tasklist: values.tasklist,
                    taskIsuse: values.taskIsuse,
                    taskRefers: values.taskRefers,
                    taskDetail: values.taskDetail,
                    taskSolution: values.taskSolution,
                    taskstartDate: values.taskstartDate,
                    taskgetjobDate: values.taskgetjobDate,
                    taskresolveDate: values.taskresolveDate,
                  });
                }}
                enableReinitialize
                initialValues={{
                  taskstatusCode: setDetail.statusCode == 3 ? 2 : parseInt(setDetail.statusCode),
                  taskprojectsCode: parseInt(setDetail.projectsCode),
                  taskcustomerCode: setTask.customerCode != null ? setTask.customerCode : "",
                  tasktypeCode: parseInt(setDetail.tasktypeCode),
                  taskImportanceCode: parseInt(setDetail.importanceCode),
                  taskempCode: setDetail.empCode,
                  taskstartDate: setDetail.taskstartDate,
                  taskresolveDate: setDetail.taskresolveDate,
                  taskDetail: setDetail.taskDetail,
                  tasklist: setDetail.tasklist,
                  taskgetjobDate: setDetail.taskgetjobDate,
                  taskcreatedBy: setDetail.taskcreatedBy != null ? parseInt(setDetail.taskcreatedBy) : "",
                }}
              >
                {() => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12} mt={3}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12}>
                            <CardContent>
                              <Grid item md={12} xs={12}>
                                <Typography variant="h2" component="h2">
                                  {"เรื่องแจ้ง :" + setTask.tasklist}
                                </Typography>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Typography variant="h4" component="h2">
                                  {"วันที่สั่งงาน : " + setTask.taskcreatedDate}
                                </Typography>
                              </Grid>
                              <CardHeader />
                              <Card>
                                <PerfectScrollbar>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <Typography className="detail" variant="h5" component="h2">
                                            รายการ
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>รายละเอียดงาน</Typography>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <Typography variant="h5" component="h2">
                                          วันที่แจ้งบัญหา :
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography>{setTask.taskgetjobDate + " " + "น."}</Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography variant="h5" component="h2">
                                          วันที่เริ่มงาน :
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography>{setTask.taskstartDate + " " + "น."}</Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            วันที่สิ้นสุด :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.taskresolveDate + " " + "น."}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            ผู้มอบหมายงาน :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.taskcreatedByName}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            ผู้รับงาน :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.taskempNeme}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            โครงกาาร :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.projectsName}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            ลูกค้า :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.companyName}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            ระดับความสำคัญ :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.importanceName}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            ประเภทงาน :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.tasktypeName}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            เรื่องแจ้ง :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.tasklist}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            รายละเอียดงาน :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            dangerouslySetInnerHTML={{ __html: setTask.taskDetail }}
                                            style={{ width: "1000px", wordWrap: "break-word" }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            สถานะงาน :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography>{setTask.statusName}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            วิธีแก้ไขปัญหา :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            dangerouslySetInnerHTML={{ __html: setTask.taskSolution }}
                                            style={{ width: "1000px", wordWrap: "break-word" }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="h5" component="h2">
                                            แนะนำ :
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            dangerouslySetInnerHTML={{ __html: setTask.taskRefers }}
                                            style={{ width: "1000px", wordWrap: "break-word" }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography> หมายเหตุ :</Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            dangerouslySetInnerHTML={{ __html: setTask.notes }}
                                            style={{ width: "1000px", wordWrap: "break-word" }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </PerfectScrollbar>
                              </Card>
                              <CardHeader />
                            </CardContent>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {setTask.statusName === "รอดำเนินการ" ? (
                        <Box display="flex" justifyContent="flex-end">
                          <Box mr={3}>
                            <Button
                              color="primary"
                              type="submit"
                              className={classes.buttonsuccess}
                              variant="contained"
                              startIcon={<PlaylistAddIcon />}
                              size="large"
                            >
                              รับงาน
                            </Button>
                          </Box>
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => {
                              navigate("/app/Developer", {
                                replace: true,
                              });
                            }}
                            size="large"
                          >
                            ย้อนกลับ
                          </Button>
                        </Box>
                      ) : setTask.statusName !== "รอดำเนินการ" ? (
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => {
                              navigate("/app/Developer", {
                                replace: true,
                              });
                            }}
                            size="large"
                          >
                            ย้อนกลับ
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default Detaildev;
