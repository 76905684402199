import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createMuiTheme({
  color: {
    backgroundbutton: "#FF0000",
  },
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: colors.indigo[500],
    },
    secondary: {
      main: "#FF0000",
    },

    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["Kanit"],
      },
    },
  },
});
export default theme;
