import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Search as SearchIcon } from "react-feather";
import IconAdd from "../../icons/Iconadd";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import { pricetype } from "../../Data/tax.json";
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, clear, searchData, changSelects, selects, pricetypeCoode, Selectpricetype, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textPrimary">หนังสือรับรองการหักภาษี ณ ที่จ่าย</Link>
        </Breadcrumbs>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextField
                    id="search"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      searchData(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={"ค้นหา..."}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormGroup
                    row
                    onChange={(e) => {
                      changSelects(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      checked={selects == "-1"}
                      control={<Checkbox value="-1" color="primary" />}
                      label="ทั้งหมด"
                    />
                    <FormControlLabel
                      checked={selects == "0"}
                      control={<Checkbox value="0" color="primary" />}
                      label="นิติบุคคล"
                    />
                    <FormControlLabel
                      checked={selects == "1"}
                      control={<Checkbox value="1" color="primary" />}
                      label="บุคคลธรรมดา"
                    />
                  </FormGroup>
                </Grid>

                <Grid item md={2} xs={6}>
                  <TextField
                    value={pricetypeCoode}
                    size="small"
                    label="ประเภทเงินที่จ่าย"
                    id="taskType"
                    name="taskType"
                    variant="outlined"
                    fullWidth
                    inputProps={{ defaultValue: -1 }}
                    select
                    onChange={(e) => {
                      Selectpricetype(e.target.value);
                    }}
                  >
                    <MenuItem value={-1}>ทั้งหมด</MenuItem>
                    {pricetype.map((values) => (
                      <MenuItem key={values.pricetypeCode} value={values.pricetypeCode}>
                        {values.pricetypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={1} xs={6}>
                  <Box display="flex" justifyContent="flex-start">
                    <Tooltip title="ล้างข้อมูลการค้นหา">
                      <IconButton
                        values={0}
                        size="small"
                        onClick={(e) => {
                          document.getElementById("search").value = "";
                          clear();
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<IconAdd />}
                      onClick={() => {
                        navigate("/app/InsertTax", { replace: true });
                      }}
                      size="large"
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
