import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import { Box, MenuItem, Card, CardContent, TextField, InputAdornment, SvgIcon, makeStyles } from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Page from "../../components/Page";
import { status, taskType, importance } from "../../Data/taskData.json";

const Toolbar = ({
  clear,
  statusCode,
  tasktypeCode,
  importanceCode,
  searchData,
  changstatusCode,
  changimportanceCode,
  changtasktypeCode,
  since,
  to,
  flutterSince,
  flutterTo,
}) => {
  return (
    <Page>
      <Box display="flex" justifyContent="flex-start">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="h3">
            รายการงานที่ได้รับหมอบหมาย
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box mt={1}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="search"
                  onKeyUp={(e) => {
                    searchData(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="ค้นหา...เรื่องแจ้ง"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  value={tasktypeCode}
                  size="small"
                  label="ประเภทงาน"
                  id="taskType"
                  name="taskType"
                  variant="outlined"
                  fullWidth
                  inputProps={{ defaultValue: 0 }}
                  select
                  onChange={(e) => {
                    changtasktypeCode(e.target.value);
                  }}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  {taskType.map((values) => (
                    <MenuItem key={values.tasktypeCode} value={values.tasktypeCode}>
                      {values.tasktypeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  value={statusCode}
                  size="small"
                  id="status"
                  name="status"
                  label="สถานะงาน"
                  variant="outlined"
                  fullWidth
                  inputProps={{ defaultValue: 0 }}
                  select
                  onChange={(e) => {
                    changstatusCode(e.target.value);
                  }}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  {status.map((values) => (
                    <MenuItem key={values.statusCode} value={values.statusCode}>
                      {values.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  value={importanceCode}
                  size="small"
                  id="importance"
                  name="importance"
                  label="ความสำคัญ"
                  variant="outlined"
                  fullWidth
                  inputProps={{ defaultValue: 0 }}
                  select
                  onChange={(e) => {
                    changimportanceCode(e.target.value);
                  }}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  {importance.map((values) => (
                    <MenuItem key={values.importanceCode} value={values.importanceCode}>
                      {values.importanceName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2}></Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  value={since}
                  id="date"
                  label="ตั้งแต่วันที่ "
                  type="date"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    flutterSince(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  value={to}
                  id="date"
                  label="ถึงวันที่"
                  type="date"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    flutterTo(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item md={1} xs={6}>
                <Box display="flex" justifyContent="flex-start">
                  <Tooltip title="ล้างข้อมูลการค้นหา">
                    <IconButton
                      values={0}
                      size="small"
                      onClick={(e) => {
                        document.getElementById("search").value = "";
                        clear();
                      }}
                    >
                      <AutorenewIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
