import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import { GetCustomerService } from "../../services/customer.services";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(233, 247, 255, 0.678)",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [setCustomer, SetCustomer] = useState([]);
  const [setpagin, SetPagin] = useState({});
  const [search, Setsearch] = useState("");
  const [currentPage, SetcurrentPage] = useState(1);
  const [pageSize, SetpageSize] = useState(10);

  useEffect(() => {
    fetchData(search, pageSize, currentPage);
  }, []);
  async function fetchData(search, pageSize, currentPage) {
    const result = await GetCustomerService(search, pageSize, currentPage);
    SetCustomer(result.data);
    SetPagin(result.paging);
  }

  function searchData(value) {
    Setsearch(value);
    SetcurrentPage(1);
    fetchData(value, pageSize, 1);
  }

  function changeCurrentPage(value) {
    SetcurrentPage(value);
    fetchData(search, pageSize, value);
  }

  function changPageSize(value) {
    SetpageSize(value);
    SetcurrentPage(1);
    fetchData(search, value, 1);
  }

  function fetchDelete() {
    fetchData(search, pageSize, 1);
  }

  function clear() {
    Setsearch("");

    SetcurrentPage(1);

    fetchData("", pageSize, 1);
  }

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar searchData={searchData}  clear={clear} />
        <Box mt={3}>
          <Results
            setCustomers={setCustomer}
            fetchDatas={fetchData}
            changPageSize={changPageSize}
            changeCurrentPage={changeCurrentPage}
            currentPage={currentPage}
            fetchDelete={fetchDelete}
            setpagin={setpagin}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
