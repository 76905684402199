import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import Pages from "../../../components/Page";
import { Box, Link, Container, makeStyles, Grid } from "@material-ui/core";
import { GetDetailExpensesService } from "../../../services/services.Expenses";
import Pagination from '@material-ui/lab/Pagination'
import PDFViewer from 'pdf-viewer-reactjs'
import { Document, Page } from 'react-pdf'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { URLPDF } from "../../../Helper/baseURL";



const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        backgroundColor: "rgba(233, 247, 255, 0.678)",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(5),
    },

}));

const PDFView = ({ className, fetchDatas, ...rest }) => {
    const classes = useStyles();
    const [setEx, SetEx] = useState({});
    const navigate = useNavigate();
    const param = useLocation();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    async function loadExpenses(param) {
        const resultExpenses = await GetDetailExpensesService(param);
        SetEx(resultExpenses.data)
    }
    useEffect(() => {
        if (param.state !== null) {
            loadExpenses(param.state.id)
        }
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

   

    
    return (

        <Pages className={classes.root} noValidate>
            <Container maxWidth={false}>
                <Box display="flex" justifyContent="flex-start">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#" onClick={(e) => {
                            e.preventDefault();
                            navigate("/app/DetailRED", { state: { id: setEx.expesesCode } }, { replace: true });
                        }}
                        >
                            รายละเอียดค่าใช้จ่าย
                         </Link>
                        <Link color="textPrimary">เอกสารประกอบการเบิกเงิน</Link>
                    </Breadcrumbs>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Pagination count={numPages} page={pageNumber} onChange={handleChangePage} />

                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Document
                                file={URLPDF + setEx.exDocument}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />

                            </Document>

                        </Box>
                    </Grid>
                </Grid>
            </Container>                   
        </Pages>

    );
}


export default PDFView;