import Instance from "../Helper/axios";
import {URL} from '../Helper/baseURL';

//แสดงข้อมูลค่าใช้จ่าย
export async function GetExpensesService(search,expensesType,categoryCode,pageSize,currentPage,since,to) {
  try {
    const response = await Instance.get(URL+"Expenses?search=" + search + '&expensesType=' + expensesType + '&categoryCode=' + categoryCode + '&pageSize=' + pageSize + '&currentPage=' + currentPage  + '&since=' + since + '&to=' + to);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงข้อมูลค่าใช้จ่าย dropdown
export async function GetDataExpensesService() {
  try {
    const response = await Instance.get(URL+"Expenses/GetData");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function GetDataCatExpensesService(expensesTypeCode) {
  try {
    const response = await Instance.get(URL+"Expenses/GetDataCat?expensesTypeCode=" + expensesTypeCode);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function GetDataToolExpensesService() {
  try {
    const response = await Instance.get(URL+"Expenses/GetDataTool");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงรายละเอียดข้อมูลค่าใช้จ่าย
export async function GetDetailExpensesService(id) {
  try {
    const response = await Instance.get(URL+"Expenses/GetDetail?id="+ id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function LoadDataExpensesService(id) {
  try {
    const response = await Instance.get(URL+"Expenses/LoadData?id="+ id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}


export async function SaveExpensesService(values){
  try {
    let formdata = new FormData();

    formdata.append('ExList',values.exList);
    formdata.append('ExDetail',values.exDetail);
    formdata.append('ExValue',values.exValue);
    formdata.append('ExVat',values.exVat);
    formdata.append('ExVatTotal',values.exVatTotal);
    formdata.append('ExTotal',values.exTotal);
    formdata.append('ExNote',values.exNote);
    formdata.append('ExDocument',values.exDocument);
    formdata.append('ExCategoryCode',values.exCategoryCode);
    formdata.append('ExDepartmentCode',values.exDepartmentCode);
    formdata.append('ExProjectsCode',values.exProjectsCode);
    formdata.append('ExpensesType',values.expensesType);
    formdata.append('ExTransactionDate',values.exTransactionDate);
    formdata.append('ExRecordUser',"HC0045");


    const response = await Instance.post(URL+"Expenses/Save",formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateExpensesService(values,data){
  try {
    let formdata = new FormData();
    formdata.append('ExList',data.exList);
    formdata.append('ExDetail',data.exDetail);
    formdata.append('ExValue',data.exValue);
    formdata.append('ExVat',data.exVat);
    formdata.append('ExVatTotal',data.exVatTotal);
    formdata.append('ExTotal',data.exTotal);
    formdata.append('ExNote',data.exNote);
    formdata.append('ExDocument',data.exDocument);
    formdata.append('ExCategoryCode',data.exCategoryCode);
    formdata.append('ExDepartmentCode',data.exDepartmentCode);
    formdata.append('ExProjectsCode',data.exProjectsCode);
    formdata.append('ExpensesType',data.expensesType);
    formdata.append('ExTransactionDate',data.exTransactionDate);
    formdata.append('ExRecordUser',"HC0045");


    const response = await Instance.put(URL + "Expenses/Update/" + values,formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//ลบข้อมูลค่าใช้จ่าย
export async function DeleteExpensesService(id) {
  try {
    const response = await Instance.delete(URL + "Expenses/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
