export function AUTHEN(token) {
  return { type: "AUTHEN", token: token };
}

export function UAUTHEN() {
  return { type: "UAUTHEN" };
}

export function USERINFO() {
  return { type: "USERINFO" };
}
