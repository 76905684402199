import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

export async function GetTaskService(search, pageSize, currentPage, statusCode, importanceCode, tasktypeCode, since, to) {
  try {
    const response = await Instance.get(
      URL +
        "Task?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&statusCode=" +
        statusCode +
        "&importanceCode=" +
        importanceCode +
        "&tasktypeCode=" +
        tasktypeCode +
        "&since=" +
        since +
        "&to=" +
        to
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
////////////////////////////////////////////////////////
export async function GetDveTaskService(search, pageSize, currentPage, statusCode, importanceCode, tasktypeCode, since, to, empCode) {
  try {
    const response = await Instance.get(
      URL +
        "Task/Dve?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&statusCode=" +
        statusCode +
        "&importanceCode=" +
        importanceCode +
        "&tasktypeCode=" +
        tasktypeCode +
        "&since=" +
        since +
        "&to=" +
        to +
        "&empCode=" +
        empCode
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
////////////////////////////////////////////////////////
export async function GetDetailTaskService(id) {
  try {
    const response = await Instance.get(URL + "Task/GetDetail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function LoadDataTaskService(id) {
  try {
    const response = await Instance.get(URL + "Task/LoadData?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function LoadNewTaskService(id) {
  try {
    const response = await Instance.get(URL + "Task/LoadNew?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
//////////////////////////////////////////////////////////
export async function GetdataTaskService() {
  try {
    const response = await Instance.get(URL + "Task/GetData");
    console.log(response);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteTaskService(id) {
  try {
    const response = await Instance.delete(URL + "Task/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function SaveTaskService(values) {
  try {
    let formdata = new FormData();
    formdata.append("TaskprojectsCode", values.taskprojectsCode);
    formdata.append("TaskcustomerCode", values.taskcustomerCode);
    formdata.append("TasktypeCode", values.tasktypeCode);
    formdata.append("TaskstatusCode", 3);
    formdata.append("TaskempCode", values.taskempCode);
    formdata.append("TaskImportanceCode", values.taskImportanceCode);
    formdata.append("TaskresolveDate", values.taskresolveDate);
    formdata.append("TaskDetail", values.taskDetail);
    formdata.append("Tasklist", values.tasklist);
    formdata.append("TaskIsuse", values.taskIsuse);
    formdata.append("TaskgetjobDate", values.taskgetjobDate);
    formdata.append("TaskstartDate", values.taskstartDate);
    formdata.append("TaskcreatedBy", values.taskcreatedBy);
    formdata.append("TaskRefers", values.taskRefers);
    formdata.append("TaskSolution", values.taskSolution);
    formdata.append("Notes", values.notes);

    const response = await Instance.post(URL + "Task/Save", formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateTaskService(values, data) {
  try {
    let formdata = new FormData();
    formdata.append("TaskprojectsCode", data.taskprojectsCode);
    formdata.append("TaskcustomerCode", data.taskcustomerCode);
    formdata.append("TasktypeCode", data.tasktypeCode);
    formdata.append("TaskstatusCode", data.taskstatusCode);
    formdata.append("TaskempCode", data.taskempCode);
    formdata.append("TaskImportanceCode", data.taskImportanceCode);
    formdata.append("TaskresolveDate", data.taskresolveDate);
    formdata.append("TaskDetail", data.taskDetail);
    formdata.append("Tasklist", data.tasklist);
    formdata.append("TaskIsuse", data.taskIsuse);
    formdata.append("TaskgetjobDate", data.taskgetjobDate);
    formdata.append("TaskstartDate", data.taskstartDate);
    formdata.append("TaskcreatedBy", data.taskcreatedBy);
    formdata.append("TaskRefers", data.taskRefers);
    formdata.append("TaskSolution", data.taskSolution);
    formdata.append("Notes", data.notes);

    const response = await Instance.put(URL + "Task/Update/" + values, formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateDevTaskService(values, data) {
  try {
    let formdata = new FormData();
    formdata.append("TaskprojectsCode", data.taskprojectsCode);
    formdata.append("TaskcustomerCode", data.taskcustomerCode);
    formdata.append("TasktypeCode", data.tasktypeCode);
    formdata.append("TaskstatusCode", data.taskstatusCode);
    formdata.append("TaskempCode", data.taskempCode);
    formdata.append("TaskImportanceCode", data.taskImportanceCode);
    formdata.append("TaskresolveDate", data.taskresolveDate);
    formdata.append("TaskDetail", data.taskDetail);
    formdata.append("Tasklist", data.tasklist);
    formdata.append("TaskIsuse", data.taskIsuse);
    formdata.append("TaskgetjobDate", data.taskgetjobDate);
    formdata.append("TaskstartDate", data.taskstartDate);
    formdata.append("TaskcreatedBy", data.taskcreatedBy);
    formdata.append("TaskRefers", data.taskRefers);
    formdata.append("TaskSolution", data.taskSolution);
    formdata.append("Notes", data.notes);
    const response = await Instance.put(URL + "Task/UpdateDev/" + values, formdata);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
