import Instance from "../Helper/axios";
import { URL } from "../Helper/baseURL";

//แสดงข้อมูลการจ่ายเงินเดือน
export async function GetSalareService(search, pageSize, currentPage, month, year, empCode, role) {
  try {
    const response = await Instance.get(
      URL +
        "Salary?search=" +
        search +
        "&pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&month=" +
        month +
        "&year=" +
        year +
        "&empCode=" +
        empCode +
        "&role=" +
        role
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงข้อมูลพนักงาน dropdown
export async function GetDataSalaryService() {
  try {
    const response = await Instance.get(URL + "Salary/GetData");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function LoadDataSalaryService(id) {
  try {
    const response = await Instance.get(URL + "Salary/LoadData?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetDetailSalaryService(id) {
  try {
    const response = await Instance.get(URL + "Salary/GetDetail?id=" + id);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function SaveSalaryService(values) {
  try {
    console.log("values", values);

    const response = await Instance.post(URL + "Salary/Save", values);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateSalaryService(values, data) {
  try {
    const response = await Instance.put(URL + "Salary/Update/" + values, data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteSalaryService(id) {
  try {
    const response = await Instance.put(URL + "Salary/Delete/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteIncomeService(id) {
  try {
    const response = await Instance.put(URL + "Salary/Delete/income/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function DeleteCostService(id) {
  try {
    const response = await Instance.put(URL + "Salary/Delete/cost/" + id + "/");
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
